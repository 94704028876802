import { SCALE_AMOUNT } from "./variables";
import { initialTheme } from "../../state";
import { useWindowSize } from "react-use";
import { Channel } from "../../interfaces";
import { findIndex, inRange, first, last, isEqual, includes } from "lodash";
import { FeedTypeEnum } from "../../util";

/* GLOBAL METHODS */

/**
 * @description Calculates the amount required to translate the x axis of an item.
 */
export function calculateItemTranslateX(
  transformDirection: number,
  isActive: boolean,
  isFirst: boolean,
  isLast: boolean,
  _scaleAmount: number
) {
  if ((isActive && isFirst) || (isActive && isLast)) {
    return "0";
  }
  let transform = (transformDirection * (_scaleAmount - 1) * 100) / 2;
  return transform === 0 ? 0 : transform + "%";
}

// small: 700,
// medium: 1024,
// large: 1440,
// xlarge: 1920,

/**
 * @description Calculates the number of items to display
 * at the current container width, based on the
 * `theme > mediaSizes`
 * @returns `itemsToDisplay`
 */
export const useItemsToDisplay = () => {
  const { small, medium, large, xlarge } = initialTheme.mediaSizes;
  const { width: containerWidth } = useWindowSize();
  let itemsToDisplay = 1;
  switch (true) {
    case inRange(containerWidth, 0, small):
      itemsToDisplay = 1;
      break;
    case inRange(containerWidth, small, medium):
      itemsToDisplay = 2;
      break;
    case inRange(containerWidth, medium, large):
      itemsToDisplay = 3;
      break;
    case inRange(containerWidth, large, xlarge):
      itemsToDisplay = 4;
      break;
    case inRange(containerWidth, xlarge, Infinity /* :D */):
      itemsToDisplay = 5;
      break;
  }
  return { itemsToDisplay, containerWidth };
};

/** 
  /** 
/** 
 * @description Generates the directional value for any given item, 
 * tell the requesting item how much it should move, and to which direction,
 * it is based on the position of the activeItem AND/OR if the active item is 
 * bordered item (First or Last).
 * 
 * @returns
 * `0` : No active item, no transform is needed, 
 * `0` : No active item, no transform is needed, 
 * `0` : No active item, no transform is needed, 
 * `1` : Move 1 unit to the right (activeItem is first)
 * `-1` : Move 1 unit to the left (activeItem is last)
 * `2` : Move 2 units to the right
 * `-2` : Move 2 units to the left
 */
// @TODO Optimization needed on the first run, use idle time to run batch calculation, maybe use React.memo?!
export const getTransformDirection = (
  channel: Channel,
  activeItem: Channel | null,
  channelsInDisplay: Channel[],
  originalChannels: Channel[]
) => {
  if (!activeItem || !includes(channelsInDisplay, activeItem)) return 0;

  const activeItemIsFirst = isEqual(first(channelsInDisplay), activeItem);
  const activeItemIsLast = isEqual(last(channelsInDisplay), activeItem);
  // The activeItem is first or last (bordered)
  if (
    (isEqual(activeItem, channel) && activeItemIsFirst) ||
    (isEqual(activeItem, channel) && activeItemIsLast)
  ) {
    return activeItemIsFirst ? 1 : -1;
  }

  if (isEqual(activeItem, channel)) return 0;

  const activeItemIsFirstOrLast = activeItemIsFirst || activeItemIsLast;

  const activeItemIndex = findIndex(originalChannels, activeItem);
  const channelIndex = channel.placeholder
    ? findIndex(channelsInDisplay, channel)
    : findIndex(originalChannels, channel);

  // Don't move items outside channelsInDisplay when activeItemIsFirstOrLast
  if (activeItemIsFirstOrLast && !includes(channelsInDisplay, channel)) {
    // don't move the item at the end
    if (activeItemIsFirst && channelIndex < activeItemIndex) return 0;

    // don't move the item at the start
    if (activeItemIsLast && channelIndex > activeItemIndex) return 0;
  }

  // This value determents on which direction
  // This value determents on which direction
  // This value determents on which direction
  // the transform of the item will scale.
  let direction = 0;

  if (activeItemIndex < channelIndex) {
    direction = activeItemIsFirstOrLast ? 2 : 1;
  } else if (activeItemIndex >= channelIndex) {
    direction = activeItemIsFirstOrLast ? -2 : -1;
  }
  return direction;
};

export const generateMyChannel = (d: Channel) => ({
  ...d,
  media: d.media || { formats: { medium: "", fullHd: "" } },
}); //https://source.unsplash.com/random/160x${sample(range(50, 100))}

export const FeaturesConfig: any = {
  [FeedTypeEnum.INFOTAINMENT]: {
    slug: FeedTypeEnum.INFOTAINMENT,
    title: "Infotainment",
  },
  [FeedTypeEnum.TEAMS]: {
    slug: FeedTypeEnum.TEAMS,
    title: "Teams",
  },
  [FeedTypeEnum.SOCIAL]: {
    slug: FeedTypeEnum.SOCIAL,
    title: "Social",
  },
};
