
// Define arrays of image and video file extensions
export const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp'];
export const videoExtensions = ['mp4', 'mov', 'avi', 'mkv', 'wmv', 'ogg', 'webm'];

export const getMediaType = (fileName?: string) => {
  if (!fileName) return null;
  const extension = fileName.split('.').pop()?.toLowerCase();
  if (!extension) return null;

  // Check if the file's extension matches an image extension
  if (imageExtensions.includes(extension)) {
    return 'image';
  }
  
  // Check if the file's extension matches a video extension
  if (videoExtensions.includes(extension)) {
    return 'video';
  }

  // If the extension does not match any image or video extension, return null or handle it accordingly
  return null;
};
