import {
  styled,
  Block,
  Button,
  buttonType,
  ModalCtaContainer,
  Icon,
} from "../../../seenspire-library";
import React, { FunctionComponent } from "react";

export interface IModalButtons {
  onSubmit: any;
  onCancel: any;
  cancel: any;
  submit: any;
  ctaType?: buttonType;
  disableSubmit?: boolean;
}

export const StyledButton = styled(Button)`
  min-height: 50px;
  gap: 5px;
`;

const ModalButtons: FunctionComponent<IModalButtons> = ({
  onSubmit,
  onCancel,
  cancel,
  submit,
  ctaType,
  disableSubmit,
}) => {
  return (
    <ModalCtaContainer>
      {onCancel && (
        <Button elementWidth="block" onClick={onCancel} buttonType="outline">
          {" "}
          {cancel}
        </Button>
      )}
      <Button
        disablePointer={disableSubmit}
        disabled={disableSubmit}
        elementWidth="block"
        onClick={onSubmit}
        buttonType={ctaType || "cta"}
      >
        {" "}
        {submit}
      </Button>
    </ModalCtaContainer>
  );
};

export interface IFormModalButtons {
  form: any;
  onCancel?: any;
  cancel?: any;
  submit: any;
  submitting?: any;
  outsideForm?: boolean;
  enableOnValidity?: boolean;
  ctaNativeColor?: string;
}
const FormModalButtons: FunctionComponent<IFormModalButtons> = ({
  form,
  onCancel,
  cancel,
  submit,
  submitting,
  outsideForm,
  enableOnValidity,
  ctaNativeColor,
}) => {
  return (
    <ModalCtaContainer>
      {onCancel && (
        <Button
          elementWidth="block"
          onClick={() => {
            form.resetForm();
            onCancel();
          }}
          buttonType="outline"
        >
          {" "}
          {cancel}
        </Button>
      )}
      {outsideForm ? (
        <StyledButton
          disablePointer={form.isSubmitting}
          disabled={form.isSubmitting}
          nativeColor={ctaNativeColor}
          elementWidth="block"
          onClick={() => form.submitForm()}
          buttonType="cta"
        >
          {form.isSubmitting ? (
            <Icon name="loading" strokeColor="white" />
          ) : (
            submit
          )}
        </StyledButton>
      ) : (
        <StyledButton
          disablePointer={form.isSubmitting}
          disabled={form.isSubmitting}
          nativeColor={ctaNativeColor}
          elementWidth="block"
          type="submit"
          buttonType="cta"
        >
          {form.isSubmitting ? (
            <Icon name="loading" strokeColor="white" />
          ) : (
            submit
          )}
        </StyledButton>
      )}
    </ModalCtaContainer>
  );
};

export { ModalButtons, FormModalButtons };
