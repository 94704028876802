import { getParams, deleteQueryParam } from "../routes";

export const clearSession = () => {
  localStorage.setItem("token", "null");
};

export const storeToken = (token: any, persistSession?: boolean) => {
  localStorage.setItem(
    "token",
    JSON.stringify({
      ...token.data,
      refresh_token: persistSession === false ? "" : token?.data?.refresh_token ?? "",
      expires_at: new Date(new Date().getTime() + token?.data?.expires_in * 1000) ?? null,
    })
  );
  return token;
};

export const getToken = () => {
  const queryParams = getParams();
  const tokenBase64: any = queryParams.token;
  let token;
  try {
    token = tokenBase64
      ? JSON.parse(atob(tokenBase64))
      : JSON.parse(localStorage.getItem("token") || "null");
  } catch (error) {
    token = null;
  }
  return token || null;
};

export const clearTokenParams = () => {
  return deleteQueryParam("token");
};

export const generateToken = (token: any) => {
  const tokenBase64 = Buffer.from(JSON.stringify(token)).toString("base64");
  return tokenBase64;
};
