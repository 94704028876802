import {
  css,
  unit,
  renderColor,
  theme,
  hsla,
  withMedia as m,
  mapValuesToMediaSizes,
} from "../../styled-components";
import {
  IShadow,
  IAlignment,
  ITransition,
  IFlex,
  ITextTransform,
  IFontSize,
  IFontWeight,
  ISpacing,
  ITextColor,
  IBackgroundColor,
  IBorderRadius,
  IResponsiveAt,
  getElementSize,
  IElementWidth,
  IElementHeightBase,
  ColorType,
  ITextAlign,
  ICursor,
  IVisibility,
  IOpacity,
  IPointer,
  ITextDecoration,
  IZIndex,
  ThemedColorConfig,
} from "../../styled-components/interface";

const BorderRadiusBase = css`
  border-radius: ${({ theme }) => unit(theme.borderRadius)};
`;

const Padding = css`
  padding: ${({ theme }) => unit(theme.spacing.base)};
`;

const PaddingMedium = css`
  padding: ${({ theme }) => unit(theme.spacing.base * 2)};
`;

const PaddingLarge = css`
  padding: ${({ theme }) => unit(theme.spacing.base * 4)};
`;

const Relative = css`
  position: relative;
`;

const CenterItems = css`
  justify-items: center;
  align-items: center;
`;

const CenterContent = css`
  justify-content: center;
  align-content: center;
`;

const DisplayFlex = css`
  display: ${(props: any) => props.display || "flex"};
`;

const FlexColumn = css`
  flex-direction: column;
`;

const Grid = css`
  display: grid;
`;

const Shadow = css<IShadow>`
  box-shadow: ${({ theme, shadow }) =>
    !shadow ? "none" : theme.shadows[shadow]};
`;

const TransitionBase = css`
  transition: all 350ms ease;
`;

const Transition = css<ITransition>`
  ${({ transition }) => transition && TransitionBase};
`;

const TextTransform = css<ITextTransform>`
  text-transform: ${({ textTransform }) => textTransform};
`;

const TextAlign = css<ITextAlign>`
  text-align: ${({ textAlign }) => textAlign};
`;

const Cursor = css<ICursor>`
  cursor: ${({ cursor }) => cursor};
`;

const Alignment = css<IAlignment>`
  ${({ position }) => m("position", position)};
  ${({ display }) => m("display", display)};
  ${({ display, direction }) =>
    m("flexDirection", direction, undefined, [display, "flex"])};
  ${({ display, direction }) =>
    m("gridAutoFlow", direction, undefined, [display, "grid"])};
  ${({ display, gridGap }) => m("gridGap", gridGap, unit, [display, "grid"])};

  ${({ display, gridTemplate }) =>
    gridTemplate &&
    m("gridTemplateColumns", gridTemplate.columns, undefined, [
      display,
      "grid",
    ])};
  ${({ display, gridTemplate }) =>
    gridTemplate &&
    m("gridTemplateRows", gridTemplate.rows, undefined, [display, "grid"])};
  ${({ gridColumn }) => m("gridColumn", gridColumn)};
  ${({ gridRow }) => m("gridRow", gridRow)};
  ${({ alignItems }) => m("alignItems", alignItems)};
  ${({ alignContent }) => m("alignContent", alignContent)};
  ${({ justifyItems }) => m("justifyItems", justifyItems)};
  ${({ justifyContent }) => m("justifyContent", justifyContent)};
  ${({ justifySelf }) => m("justifySelf", justifySelf)};
  ${({ alignSelf }) => m("alignSelf", alignSelf)};
`;

const Spacing = css<ISpacing>`
  ${({ padding }) => padding && m("padding", padding.all, unit)};
  ${({ margin }) => margin && m("margin", margin.all, unit)};
  ${({ padding }) => padding && m("paddingTop", padding.top, unit)};
  ${({ margin }) => margin && m("marginTop", margin.top, unit)};
  ${({ padding }) => padding && m("paddingBottom", padding.bottom, unit)};
  ${({ margin }) => margin && m("marginBottom", margin.bottom, unit)};
  ${({ padding }) => padding && m("paddingLeft", padding.left, unit)};
  ${({ margin }) => margin && m("marginLeft", margin.left, unit)};
  ${({ padding }) => padding && m("paddingRight", padding.right, unit)};
  ${({ margin }) => margin && m("marginRight", margin.right, unit)};
`;

const Flex = css<IFlex>`
  ${({ flex }) => m("flex", flex)};
`;

const FontWeight = css<IFontWeight>`
  font-weight: ${({ theme, fontWeight }) =>
    fontWeight && theme.fontWeight[fontWeight]};
`;

const FontSize = css<IFontSize>`
  font-size: ${({ theme, fontSize }) => fontSize && theme.fontSize[fontSize]};
`;

const FontSizeBase = css<IFontSize>`
  font-size: ${({ theme, fontSize }) => theme.fontSize[fontSize || "base"]};
`;

const FontWeightBold = css<IFontWeight>`
  font-weight: ${({ theme, fontWeight }) =>
    theme.fontWeight[fontWeight || "bold"]};
`;

const TextTransformCapitalize = css<ITextTransform>`
  text-transform: ${({ textTransform }) => textTransform || "capitalize"};
`;

/** fontSize > elementSize > padding */
const ElementSizePadding = css<IFontSize>`
  padding: ${({ fontSize, theme }) =>
    unit(theme.element.padding[getElementSize(fontSize)])};
`;

const ElementWidth = css<IElementWidth>`
  ${({ theme, elementWidth }: any) =>
    mapValuesToMediaSizes("minWidth", elementWidth, {
      block: "100%",
      free: null,
      default: unit(theme.element.width),
    })};
  ${({ theme, elementWidth }: any) =>
    mapValuesToMediaSizes("width", elementWidth, {
      block: "100%",
      free: null,
      default: unit(theme.element.width),
    })};
`;

const ElementHeightBase = css<IElementHeightBase>`
  min-height: ${({ theme, elementHeightBase = true }) =>
    elementHeightBase && unit(theme.element.height)};
`;
const BorderRadius = css<IBorderRadius>`
  ${({ borderRadius }) => borderRadius && BorderRadiusBase};
`;

const ResponsiveAt = css<IResponsiveAt>`
  min-width: ${({ theme, responsiveAt }) =>
    responsiveAt && unit(theme.mediaSizes[responsiveAt])};
`;

const Color = css<ITextColor>`
  color: ${({ theme, color, shade }) => renderColor(theme, color, shade)};
  svg {
    fill: ${({ theme, color, shade }) => renderColor(theme, color, shade)};
  }
`;

const BackgroundColor = css<IBackgroundColor>`
  background-color: ${({ theme, bg, bgShade }) =>
    renderColor(theme, bg, bgShade)};
`;

const Visibility = css<IVisibility>`
  ${({ visibility }) => m("visibility", visibility)};
`;

const Opacity = css<IOpacity>`
  opacity: ${({ opacity }) => opacity};
`;

const Pointer = css<IPointer>`
  pointer-events: ${({ disablePointer }) => disablePointer && "none"};
`;

const TextDecoration = css<ITextDecoration>`
  text-decoration: ${({ textDecoration }) => textDecoration};
`;

const ZIndex = css<IZIndex>`
  z-index: ${({ zIndex }) => zIndex};
`;

const ColorsReducer = (
  cb: (acc: ThemedColorConfig, label: ColorType) => ThemedColorConfig
) =>
  (Object.keys(theme.colors) as ColorType[]).reduce(
    cb,
    {} as ThemedColorConfig
  );

const ColorState = (CSS_PROPERTY_NAME: string, opacity: number = 1) =>
  ColorsReducer((acc, label) => {
    acc[label] = css`
      ${CSS_PROPERTY_NAME} : ${({ theme }) =>
        hsla(theme.colors[label].base, opacity)};
      transition: all 160ms ease-in-out;
      &:hover {
        ${CSS_PROPERTY_NAME}: ${({ theme }) =>
          hsla(theme.colors[label].base, opacity + 0.1)};
      }
      &:active,
      &:focus {
        ${CSS_PROPERTY_NAME}: ${({ theme }) =>
          hsla(theme.colors[label].base, opacity + 0.2)};
      }
    `;
    return acc;
  });

export {
  BorderRadiusBase,
  BorderRadius,
  Padding,
  PaddingMedium,
  PaddingLarge,
  Relative,
  CenterItems,
  CenterContent,
  DisplayFlex,
  FlexColumn,
  Grid,
  Shadow,
  Alignment,
  TextTransform,
  Transition,
  TransitionBase,
  Flex,
  Spacing,
  FontWeight,
  FontSize,
  BackgroundColor,
  ResponsiveAt,
  ElementSizePadding,
  ElementWidth,
  FontSizeBase,
  FontWeightBold,
  TextTransformCapitalize,
  ElementHeightBase,
  ColorState,
  ColorsReducer,
  TextAlign,
  Cursor,
  Visibility,
  Opacity,
  Pointer,
  TextDecoration,
  ZIndex,
  Color,
};
