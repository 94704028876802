import React from "react";
import { RouteConfig } from ".";

const Login = React.lazy(() => import("../containers/Auth/Login"));
const RequestResetPassword = React.lazy(
  () => import("../containers/Auth/RequestResetPassword")
);
const Register = React.lazy(() => import("../containers/Auth/Register"));
const ChangePassword = React.lazy(
  () => import("../containers/Auth/ChangePassword")
);
const AuthConfirmation = React.lazy(
  () => import("../containers/Auth/AuthConfirmation")
);

export type AuthRoutesNames =
  | "auth_login"
  | "auth_register"
  | "auth_register_invite"
  | "auth_change_password"
  | "auth_confirmation"
  | "auth_request_reset_password";

const AuthRoutes: RouteConfig[] = [
  {
    name: "auth_login",
    label: "Login",
    path: "/:slug/login",
    component: Login,
    open: true,
  },
  {
    name: "auth_register",
    label: "Register invite",
    path: "/:slug/signup/:inviteId/:answer",
    component: Register,
    open: true,
  },
  {
    name: "auth_register",
    label: "Signup",
    path: "/:slug/signup",
    component: Register,
    open: true,
  },
  {
    name: "auth_change_password",
    label: "Change Password",
    path: "/:slug/change-password/:token",
    component: ChangePassword,
    open: true,
  },
  {
    name: "auth_confirmation",
    label: "Confirmation",
    path: "/:slug/confirmation/:token",
    component: AuthConfirmation,
    open: true,
  },
  {
    name: "auth_request_reset_password",
    label: "Request reset password",
    path: "/:slug/reset-password",
    component: RequestResetPassword,
    open: true,
  },
];

export const AuthRoutNames: AuthRoutesNames[] = [
  "auth_login",
  "auth_change_password",
  "auth_register",
  "auth_register_invite",
  "auth_confirmation",
  "auth_request_reset_password",
];

export default AuthRoutes;
