import * as React from "react";
import { useContext, FunctionComponent } from "react";
import { SliderItemProps, SliderItemStatusType } from "./interfaces";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../state";
import {
  AvailableSource,
  AvailableSource2,
  AvailableRegion2,
  Region,
} from "seenspire-api-client";
import { chain, find, reduce, get, head, debounce, orderBy } from "lodash";
import flags from "./flags";
import {
  unit,
  styled,
  Block,
  hsla,
  Icon,
  TransitionBase,
  Select,
  Spacer,
  buttonType,
  ColorType,
  IAsyncSelectProps,
  AsyncSelect,
  SelectMenuComponent,
  SelectCommonProps,
  SelectOptionComponent,
  SelectControlComponent,
  INPUT_BORDER_COLOR,
  theme,
  SelectOptionStyle,
} from "../../../seenspire-library";
import { Channel, IChannelData } from "../../interfaces";
import belgaColor from "../../../assets/belgaColor.svg";
import sttColor from "../../../assets/sttColor.png";
import sttWhite from "../../../assets/sttWhite.png";
import afpColor from "../../../assets/afpColor.svg";
import reutersColor from "../../../assets/reutersColor.svg";
import skynewsarabiaColorImg from "../../../assets/sna.png";
import belgaWhite from "../../../assets/belgaWhite.svg";
import afpWhite from "../../../assets/afpWhite.svg";
import pamediaLight from "../../../assets/pamediaLight.svg";
import pamediaDark from "../../../assets/pamediaDark.svg";
import reutersWhite from "../../../assets/reutersWhite.svg";
import skynewsarabiaWhite from "../../../assets/skynewsarabiaWhite.svg";
import { AvailableRegion, AvailableLanguage2 } from "seenspire-api-client";
import { MenuButton } from "../../components/Menu/Menu";
import { StylesConfig, styleFn } from "react-select/lib/styles";
import { SelectComponents } from "react-select/lib/components";
import { AspectRatioBox, SliderItemButtonIcon } from "./Styles";
import { generateMyChannel } from "./MethodsAndLogic";
import AsyncImage from "./AsyncImage";
import SliderActiveItem from "./SliderActiveItem";
import backend from "../../backend/backend";
import Menu from "react-select/lib/components/Menu";
import { OptionsType } from "react-select/lib/types";
import { SelectComponentsProps } from "react-select/lib/Select";
import { SEARCH_DEBOUNCE_DURATION } from "../../backend/consts";
import { generateToken } from "../../backend/session";
import { TransportModes } from "./types";

export const SaveToChannelIcon = styled(SliderItemButtonIcon)`
  background-color: ${({ theme, status }) =>
    status === "DONE" ? "transparent" : hsla(theme.colors.black.base, 0.2)};
  border-radius: ${({ theme }) =>
    `0 ${unit(theme.borderRadius)} ${unit(theme.borderRadius)} 0`};
  ${TransitionBase};
`;

const SelectSingleValueLabel = styled(Block)`
  font-size: 9.5px;
`;
export const SelectItemImgContainer = styled(Block).attrs({})<{
  src: any;
  containerHeight?: string;
  imagePosition?: "right" | "left";
}>`
  height: ${({ containerHeight }) => containerHeight || "14px"};
  width: 100%;
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center ${({ imagePosition }) => imagePosition || "right"};
`;

export const CustomSelectControlStyle = {
  border: "none",
  borderColor: "transparent",
  boxShadow: "none",
  minHeight: "24px",
  maxHeight: "24px",
  zIndex: 150,
  background: "transparent",
};

export const CustomFieldsSelectStyles: StylesConfig = {
  control: (base: any) => ({
    ...base,
    ...CustomSelectControlStyle,
  }),
  menu: (base: any) => ({
    ...base,
    marginTop: "-10",
  }),
  // valueContainer: (base: any) => ({ ...base, padding: 0, }),
  // input: (base: any) => ({ ...base, margin: 0, opacity: 0 }),
};

export const CustomSelectStyles: StylesConfig = {
  control: (base: any) => ({
    ...base,
    ...CustomSelectControlStyle,
  }),
  valueContainer: (base: any) => ({
    ...base,
    display: "flex",
    justifyContent: "flex-end",
    padding: "0 0 0 10px",
  }),
  clearIndicator: () => ({ display: "none" }),
  indicatorSeparator: () => ({ display: "none" }),
  input: (base: any) => ({ ...base, margin: 0, opacity: 0 }),
  menu: (base: any) => ({
    ...base,
    marginTop: "-25px",
    border: "solid 1px #D5DBE2",
    boxShadow: "none",
    borderRadius: "5px",
  }),
  menuList: (base: any) => ({
    ...base,
    paddingTop: "0",
    paddingBottom: "0",
    padding: "10px",
    maxHeight: "150px !important",
  }),
};

export const SourcesSelectSingleValue: FunctionComponent<{
  Img: string | React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  labelColor: ColorType;
}> = (props) => {
  return (
    <Block
      flex="1"
      display="grid"
      alignItems="center"
      gridTemplate={{ columns: "1fr 3fr 20px" }}
    >
      <SelectSingleValueLabel
        fontWeight="bold"
        color={props.labelColor}
        textTransform="uppercase"
      >
        Source
      </SelectSingleValueLabel>
      {props.Img ? (
        <SelectItemImgContainer justifySelf="end" src={props.Img} />
      ) : (
        <span></span>
      )}
      <Icon justifySelf="end" color={props.labelColor} name="dropdownIcon" />
    </Block>
  );
};

export const SourcesSelectComponents: Partial<
  SelectComponents<{ label: string; value: string }>
> = {
  DropdownIndicator: null,
  SingleValue: (props) => {
    const Img = getSourceImage(props.data.value);
    return <SourcesSelectSingleValue Img={Img} labelColor="gray" />;
  },
  Menu: SelectMenuComponent({ title: "Sources" }) as any,
  Option: ({ innerProps, data, ...props }) => {
    let selectedValue: any = props.getValue();
    const _height = data.value === SOURCES_CONFIG.STT.id ? "12px" : "15px";
    if (selectedValue) {
      selectedValue = selectedValue[0];
    }
    const isSelected = selectedValue && selectedValue.value == data.value;
    const Img = getSourceImage(data.value);
    const { onClick, ...restInnerProps } = innerProps;
    return (
      <MenuButton
        selected={isSelected}
        disabled={data.isInMyChannels}
        opacity={data.isInMyChannels ? 0.4 : 1}
        onClick={onClick as any}
        {...restInnerProps}
      >
        <Block
          flex="1"
          display="grid"
          alignItems="center"
          gridTemplate={{ columns: "1fr" }}
        >
          {Img ? (
            <SelectItemImgContainer
              containerHeight={_height}
              justifySelf="end"
              imagePosition="left"
              src={Img}
            />
          ) : (
            <span></span>
          )}
        </Block>
      </MenuButton>
    );
  },
};

export const RegionsLanguageSelectSingleValue = (props: any) => {
  const Img = props.data?.language?.slug ? getRegionImage(props.data.language.slug) : null;
  const templateColumns = props.data?.language?.slug
    ? "1fr 3fr 30px 20px"
    : "1fr 3fr 20px";
  return (
    <Block
      flex="1"
      display="grid"
      alignItems="center"
      gridTemplate={{ columns: templateColumns }}
    >
      <SelectSingleValueLabel
        fontWeight="bold"
        color={props.labelColor}
        textTransform="uppercase"
      >
        Language
      </SelectSingleValueLabel>
      <SelectSingleValueLabel
        fontWeight="bold"
        color={props.labelColor}
        shade="darker"
        justifySelf="end"
      >
        {props.data?.language?.name ?? props.data?.label ?? "Unknown"}
      </SelectSingleValueLabel>
      {props.data.slug && (
        <SelectItemImgContainer justifySelf="end" src={Img} />
      )}
      <Icon justifySelf="end" color={props.labelColor} name="dropdownIcon" />
    </Block>
  );
};

export const RegionsSelectSingleValue = (props: any) => {
  const Img = props.data.slug ? getRegionImage(props.data.slug) : null;
  const templateColumns = props.data.slug
    ? "1fr 3fr 30px 20px"
    : "1fr 3fr 20px";
  return (
    <Block
      flex="1"
      display="grid"
      alignItems="center"
      gridTemplate={{ columns: templateColumns }}
    >
      <SelectSingleValueLabel
        fontWeight="bold"
        color={props.labelColor}
        textTransform="uppercase"
      >
        Region
      </SelectSingleValueLabel>
      <SelectSingleValueLabel
        fontWeight="bold"
        color={props.labelColor}
        shade="darker"
        justifySelf="end"
      >
        {props.data.label}
      </SelectSingleValueLabel>
      {props.data.slug && (
        <SelectItemImgContainer justifySelf="end" src={Img} />
      )}
      <Icon justifySelf="end" color={props.labelColor} name="dropdownIcon" />
    </Block>
  );
};

export const RegionSelectComponents: Partial<
  SelectComponents<{ label: string; value: string }>
> = {
  DropdownIndicator: null,
  SingleValue: (props: any) => (
    <RegionsSelectSingleValue labelColor="gray" {...props} />
  ),
  Menu: SelectMenuComponent({ title: "Regions" }) as any,
  Option: ({ innerProps, data, ...props }) => {
    let selectedValue: any = props.getValue();

    if (selectedValue) {
      selectedValue = selectedValue[0];
    }
    const isSelected = selectedValue && selectedValue.value == data.value;
    const Img = getRegionImage(data.slug);
    const { onClick, ...restInnerProps } = innerProps;

    return (
      <MenuButton
        selected={isSelected}
        disabled={data.isInMyChannels}
        opacity={data.isInMyChannels ? 0.4 : 1}
        onClick={onClick as any}
        {...restInnerProps}
      >
        <Block
          flex="1"
          display="grid"
          alignItems="center"
          gridTemplate={{ columns: "30px 1fr" }}
        >
          {/* flag */}
          <SelectItemImgContainer imagePosition="left" src={Img} />
          {/* name */}
          <Block
            textAlign="left"
            fontSize="small"
            fontWeight={isSelected ? "bold" : "thin"}
            color="gray"
            shade="darker"
          >
            {data.label}
          </Block>
        </Block>
      </MenuButton>
    );
  },
};

export const RegionLanguageSelectComponents: Partial<
  SelectComponents<{ label: string; value: string }>
> = {
  DropdownIndicator: null,
  SingleValue: (props: any) => (
    <RegionsLanguageSelectSingleValue labelColor="gray" {...props} />
  ),
  Menu: SelectMenuComponent({ title: "Languages" }) as any,
  Option: ({ innerProps, data, ...props }) => {
    let selectedValue: any = props.getValue();

    if (selectedValue) {
      selectedValue = selectedValue[0];
    }
    const isSelected = selectedValue && selectedValue.value == data.value;
    const Img = data?.language?.slug ? getRegionImage(data.language.slug) : null;
    const { onClick, ...restInnerProps } = innerProps;

    return (
      <MenuButton
        selected={isSelected}
        disabled={data.isInMyChannels}
        opacity={data.isInMyChannels ? 0.4 : 1}
        onClick={onClick as any}
        {...restInnerProps}
      >
        <Block
          flex="1"
          display="grid"
          alignItems="center"
          gridTemplate={{ columns: "30px 1fr" }}
        >
          {/* flag */}
          <SelectItemImgContainer imagePosition="left" src={Img} />
          {/* name */}
          <Block
            textAlign="left"
            fontSize="small"
            fontWeight={isSelected ? "bold" : "thin"}
            color="gray"
            shade="darker"
          >
            {data.language?.name ?? data.label ?? "Unknown"}
          </Block>
        </Block>
      </MenuButton>
    );
  },
};

export const TimeZoneSelectSingleValue = (props: any) => {
  return (
    <Block
      flex="1"
      display="grid"
      alignItems="center"
      gridTemplate={{ columns: "1fr 3fr 20px" }}
    >
      <SelectSingleValueLabel
        fontWeight="bold"
        color={props.labelColor}
        textTransform="uppercase"
      >
        Timezone
      </SelectSingleValueLabel>
      <SelectSingleValueLabel
        fontWeight="bold"
        color={props.labelColor}
        shade="darker"
        justifySelf="end"
      >
        {props.data.label}
      </SelectSingleValueLabel>
      <Icon justifySelf="end" color={props.labelColor} name="dropdownIcon" />
    </Block>
  );
};

export const TimeZoneSelectComponents: Partial<
  SelectComponents<{ label: string; value: string }>
> = {
  DropdownIndicator: null,
  SingleValue: (props: any) => (
    <TimeZoneSelectSingleValue labelColor="gray" {...props} />
  ),
  Menu: SelectMenuComponent({ title: "Timezone" }) as any,
  Option: ({ innerProps, data, ...props }) => {
    let selectedValue: any = props.getValue();
    if (selectedValue) {
      selectedValue = selectedValue[0];
    }
    const { onClick, ...restInnerProps } = innerProps;
    const isSelected = selectedValue && selectedValue.value == data.value;
    return (
      <MenuButton
        selected={isSelected}
        disabled={data.isInMyChannels}
        opacity={data.isInMyChannels ? 0.4 : 1}
        onClick={onClick as any}
        {...restInnerProps}
      >
        <Block
          flex="1"
          display="grid"
          alignItems="center"
          gridTemplate={{ columns: "200px 1fr" }}
        >
          {/* name */}
          <Block
            textAlign="left"
            fontSize="small"
            fontWeight={isSelected ? "bold" : "thin"}
            color="gray"
            shade="darker"
          >
            {data.label}
          </Block>
        </Block>
      </MenuButton>
    );
  },
};

export const TransportSelectSingleValue = (props: any) => {
  return (
    <Block
      flex="1"
      display="grid"
      alignItems="center"
      gridTemplate={{ columns: "1fr 3fr 20px" }}
    >
      <SelectSingleValueLabel
        fontWeight="bold"
        color={props.labelColor}
        textTransform="uppercase"
        style={{ whiteSpace: "nowrap" }}
      >
        Select a station
      </SelectSingleValueLabel>
      <SelectSingleValueLabel
        fontWeight="bold"
        color={props.labelColor}
        shade="darker"
        justifySelf="end"
      >
        {props.data.label?.split("///")[0]}
      </SelectSingleValueLabel>
      <Icon justifySelf="end" color={props.labelColor} name="dropdownIcon" />
    </Block>
  );
};

export const getTransportModeString = (mode: TransportModes) => {
  switch (mode) {
    case "highSpeedTrain":
      return "High-speed trains";
    case "intercityTrain":
      return "Intercity/EuroCity trains";
    case "interRegionalTrain":
      return "Inter-regional and fast trains";
    case "regionalTrain":
      return "Regional and other trains";
    case "cityTrain":
      return "City trains";
    case "bus":
      return "Buses";
    case "ferry":
      return "Boats/Ferries";
    case "subway":
      return "Metros/Subways";
    case "lightRail":
      return "Trams";
    case "privateBus":
      return "Ordered services/Taxis";
    case "inclined":
      return "Inclined/Funiculars";
    case "aerial":
      return "Aerials/Cable cars";
    case "busRapid":
      return "Rapid buses";
    case "monorail":
      return "Monorails";
    case "flight":
      return "Airplanes";
    case "walk":
      return "Walk";
  }
};

export const TransportSelectComponents: Partial<
  SelectComponents<{
    label: string;
    mode: string;
    headsign: string;
    value: string;
  }>
> = {
  DropdownIndicator: null,
  SingleValue: (props: any) => (
    <TransportSelectSingleValue labelColor="gray" {...props} />
  ),
  Menu: SelectMenuComponent({ title: "Select a station" }) as any,
  Option: ({ innerProps, data, ...props }) => {
    let selectedValue: any = props.getValue();
    if (selectedValue) {
      selectedValue = selectedValue[0];
    }
    const { onClick, ...restInnerProps } = innerProps;
    const isSelected = selectedValue && selectedValue.value == data.value;
    return (
      <MenuButton
        selected={isSelected}
        disabled={data.isInMyChannels}
        opacity={data.isInMyChannels ? 0.4 : 1}
        onClick={onClick as any}
        {...restInnerProps}
      >
        <Block style={{ width: "100%" }}>
          <>
            {data.label ? (
              <Block
                textAlign="left"
                fontSize="small"
                fontWeight={"bold"}
                color="black"
                shade="darker"
              >
                {data.label}
                {data.mode ? ` - ${getTransportModeString(data.mode)}` : ""}
              </Block>
            ) : null}
            {data.headsigns ? (
              <Block
                textAlign="left"
                fontSize="small"
                fontWeight={"thin"}
                color="gray"
                shade="darker"
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {data.headsigns}
              </Block>
            ) : null}
          </>
        </Block>
      </MenuButton>
    );
  },
};

export const LanguageSelectSingleValue = (props: any) => {
  const Img = props.data.slug ? getRegionImage(props.data.slug) : null;
  const templateColumns = props.data.slug
    ? "1fr 3fr 30px 20px"
    : "1fr 3fr 20px";
  return (
    <Block
      flex="1"
      display="grid"
      alignItems="center"
      gridTemplate={{ columns: templateColumns }}
    >
      <SelectSingleValueLabel
        fontWeight="bold"
        color={props.labelColor}
        textTransform="uppercase"
      >
        Languages
      </SelectSingleValueLabel>
      <SelectSingleValueLabel
        fontWeight="bold"
        color={props.labelColor}
        shade="darker"
        justifySelf="end"
      >
        {props.data.label}
      </SelectSingleValueLabel>
      {props.data.slug && (
        <SelectItemImgContainer justifySelf="end" src={Img} />
      )}
      <Icon justifySelf="end" color={props.labelColor} name="dropdownIcon" />
    </Block>
  );
};

export const LanguageSelectComponents: Partial<
  SelectComponents<{ label: string; value: string }>
> = {
  DropdownIndicator: null,
  SingleValue: (props: any) => (
    <LanguageSelectSingleValue labelColor="gray" {...props} />
  ),
  Menu: SelectMenuComponent({ title: "Languages" }) as any,
  Option: ({ innerProps, data, ...props }) => {
    let selectedValue: any = props.getValue();
    if (selectedValue) {
      selectedValue = selectedValue[0];
    }
    const isSelected = selectedValue && selectedValue.value == data.value;
    const Img = getRegionImage(data.slug);
    const { onClick, ...restInnerProps } = innerProps;
    return (
      <MenuButton
        selected={isSelected}
        disabled={data.isInMyChannels}
        opacity={data.isInMyChannels ? 0.4 : 1}
        onClick={onClick as any}
        {...restInnerProps}
      >
        <Block
          flex="1"
          display="grid"
          alignItems="center"
          gridTemplate={{ columns: "30px 1fr" }}
        >
          {/* flag */}
          <SelectItemImgContainer imagePosition="left" src={Img} />
          {/* name */}
          <Block
            textAlign="left"
            fontSize="small"
            fontWeight={isSelected ? "bold" : "thin"}
            color="gray"
            shade="darker"
          >
            {data.label}
          </Block>
        </Block>
      </MenuButton>
    );
  },
};

export const LineSeparator = styled(Block)`
  box-shadow: ${({ theme }) => `0 0 0px 0.3px ${theme.colors.gray.lighter}`};
`;

export const EmptyImg = styled.span``;

type SourceDetails = {
  id: number;
  imgColor: string;
  imgWhite: string;
  order: number;
};

let SOURCES_CONFIG: Record<string, SourceDetails> = {};
if (process.env.REACT_APP_STAGE === "production") {
  SOURCES_CONFIG = {
    AFP: {
      id: 1,
      imgColor: afpColor,
      imgWhite: afpWhite,
      order: 1,
    },
    REUTERS: {
      id: 7,
      imgColor: reutersColor,
      imgWhite: reutersWhite,
      order: 2,
    },
    STT: {
      id: 14393,
      imgColor: sttColor,
      imgWhite: sttWhite,
      order: 3,
    },
    SKYNEWSARABIA: {
      id: 5,
      imgColor: skynewsarabiaColorImg,
      imgWhite: skynewsarabiaWhite,
      order: 4,
    },
    BELGA: {
      id: 13,
      imgColor: belgaColor,
      imgWhite: belgaWhite,
      order: 5,
    },
    APMEDIA: {
      id: 44570,
      imgColor: pamediaDark,
      imgWhite: pamediaLight,
      order: 6,
    },
  };
} else if (process.env.REACT_APP_STAGE !== "production") {
  SOURCES_CONFIG = {
    AFP: {
      id: 1,
      imgColor: afpColor,
      imgWhite: afpWhite,
      order: 1,
    },
    REUTERS: {
      id: 8,
      imgColor: reutersColor,
      imgWhite: reutersWhite,
      order: 2,
    },
    STT: {
      id: 1161,
      imgColor: sttColor,
      imgWhite: sttWhite,
      order: 3,
    },
    SKYNEWSARABIA: {
      id: 6,
      imgColor: skynewsarabiaColorImg,
      imgWhite: skynewsarabiaWhite,
      order: 4,
    },
    BELGA: {
      id: 14,
      imgColor: belgaColor,
      imgWhite: belgaWhite,
      order: 5,
    },
    APMEDIA: {
      id: 3906,
      imgColor: pamediaDark,
      imgWhite: pamediaLight,
      order: 6,
    },
  };
}

export const SOURCES_CONFIG_IDS = reduce(
  SOURCES_CONFIG,
  (results, current) => {
    results[current.id] = current;
    return results;
  },
  {} as any
);

export const DEFAULT_SOURCE_ID = SOURCES_CONFIG.REUTERS.id; // Reuters
export const DEFAULT_REGION_ID = 3; // Us
export const DEFAULT_LANGUAGE_ID = 1; // English

export const REGION_IMG_CONFIG = {
  BE: {
    flag: "BE",
  },
  EU: {
    flag: "EU",
  },
  US: {
    flag: "US",
  },
  EN: {
    flag: "GLOBAL",
  },
  GB: {
    flag: "GB",
  },
  RU: {
    flag: "RU",
  },
  "ar-ae": {
    flag: "AE",
  },
  AR: {
    flag: "AE",
  },
  SA: {
    flag: "SA",
  },
  IT: {
    flag: "IT",
  },
  JP: {
    flag: "JP",
  },
  ES: {
    flag: "ES",
  },
  FR: {
    flag: "FR",
  },
  CA: {
    flag: "CA",
  },
  GLOBAL: {
    flag: "GL",
  },
  BR: {
    flag: "BR",
  },
  CF: {
    flag: "GL", // @TODO replace flag
  },
  ch: {
    flag: "CH",
  },
  IN: {
    flag: "IN",
  },
  cl: {
    flag: "CL",
  },
  ar: {
    flag: "AR",
  },
  au: {
    flag: "AU",
  },
  DE: {
    flag: "DE",
  },
  PT: {
    flag: "PT",
  },
  SV: {
    flag: "SE",
  },
  SE: {
    flag: "SE",
  },
  NL: {
    flag: "NL",
  },
  FI: {
    flag: "FI",
  },
  IS: {
    flag: "IS",
  },
  NO: {
    flag: "NO",
  },
  CH: {
    flag: "CH",
  },
  "m-e": {
    flag: "AE",
  },
  DK: {
    flag: "DK",
  },
};
export const getDefaultSource = (sources: any[]) => {
  const result =
    find(
      sources,
      (source) => source.source.id === DEFAULT_SOURCE_ID && !source.hidden
    ) ||
    find(
      sources,
      (source) =>
        source.available_regions.find(
          (_region: any) => _region.region.slug === "GLOBAL"
        ) && !source.hidden
    ) ||
    sources.filter((source) => !source.hidden)?.[0];
  return result;
};
export const getDefaultRegion = (regions: any[]) => {
  const result =
    find(regions, (region) => {
      return region.region.slug === "GLOBAL" && !region.hidden;
    }) ||
    find(
      regions,
      (region) => region.region.id === DEFAULT_REGION_ID && !region.hidden
    ) ||
    regions[0];
  return result;
};
export const getDefaultLanguage = (languages: AvailableLanguage2[]) => {
  const result =
    find(languages, (_language) => {
      return _language.language.slug === "GLOBAL";
    }) ||
    languages.find((language) => {
      return language.language.id === DEFAULT_LANGUAGE_ID;
    }) ||
    languages[0];

  return result;
};

export const generateInitialChannelItemData = (
  channel: Channel,
  myChannels: Channel[]
): IChannelData | Record<string, never>=> {
  const { id, available_sources: sources } = channel;
  const defaultSource = getDefaultSource(sources);

  if (!defaultSource) {
    return {};
  }

  const defaultRegion = getDefaultRegion(defaultSource.available_regions);

  const filteredLanguages = defaultRegion.available_languages.filter(
    (language: any) => {
      return !isInMyChannels(
        channel,
        defaultSource,
        defaultRegion,
        language,
        myChannels
      );
    }
  );
  const defaultLanguage = getDefaultLanguage(filteredLanguages);
  return {
    channel: id,
    source: defaultSource.source.id,
    region: defaultRegion.region.id,
    language: defaultLanguage && defaultLanguage.language.id,
    custom_fields: {},
  };
};

export const getSourceImage = (sourceId: any, whiteColor?: boolean) => {
  switch (sourceId) {
    case SOURCES_CONFIG.AFP.id:
      return !whiteColor
        ? SOURCES_CONFIG.AFP.imgColor
        : SOURCES_CONFIG.AFP.imgWhite;
    case SOURCES_CONFIG.REUTERS.id:
      return !whiteColor
        ? SOURCES_CONFIG.REUTERS.imgColor
        : SOURCES_CONFIG.REUTERS.imgWhite;
    case SOURCES_CONFIG.SKYNEWSARABIA.id:
      return !whiteColor
        ? SOURCES_CONFIG.SKYNEWSARABIA.imgColor
        : SOURCES_CONFIG.SKYNEWSARABIA.imgWhite;
    case SOURCES_CONFIG.APMEDIA.id:
      return !whiteColor
        ? SOURCES_CONFIG.APMEDIA.imgColor
        : SOURCES_CONFIG.APMEDIA.imgWhite;
    case SOURCES_CONFIG.BELGA.id:
      return !whiteColor
        ? SOURCES_CONFIG.BELGA.imgColor
        : SOURCES_CONFIG.BELGA.imgWhite;
    case SOURCES_CONFIG.STT.id:
      return !whiteColor
        ? SOURCES_CONFIG.STT.imgColor
        : SOURCES_CONFIG.STT.imgWhite;
    default:
      return "";
  }
};

export const checkIsInMyChannelsEquality = (
  selectedKey: string,
  myChannels: Channel[]
) => {
  return find(myChannels, (c: Channel) => {
    if (!c.channel || !c.source || !c.region || !c.language) return;
    const myChannelKey = `${c.channel.id}_${c.source.id}_${c.region.id}_${c.language.id}`;
    return myChannelKey === selectedKey;
  });
};

export const isInMyChannels = (
  channel: Channel,
  selectedSource: AvailableSource2,
  region: AvailableRegion2,
  language: AvailableLanguage2,
  myChannels: Channel[]
) => {
  const selectedKey = `${channel.id}_${selectedSource.source.id}_${region.region.id}_${language.language.id}`;
  return checkIsInMyChannelsEquality(selectedKey, myChannels);
};

export const getRegionImage = (regionSlug: any) => {
  const flagKey =
    get(REGION_IMG_CONFIG, regionSlug) &&
    (get(REGION_IMG_CONFIG, regionSlug).flag as any);
  return (flags as any)[flagKey] || flags.GL;
};

export const generateSourcesOptions = (channel: Channel) => {
  return !channel.available_sources
    ? []
    : channel.available_sources
        .filter((availableSources: any) => !availableSources.hidden)
        .map((source) => ({
          label: `${source.source.title}:${source.source.id}`,
          value: source.source.id as any,
          order:
            SOURCES_CONFIG_IDS[source.source.id] &&
            SOURCES_CONFIG_IDS[source.source.id].order,
        }))
        .sort((a, b) => {
          return a.order < b.order ? -1 : 1;
        });
};
const generateNationalWeatherRegionOptions = () => {
  return [
    {
      label: "United States",
      value: "United States",
      regionId: 3,
      slug: "US",
    },
    {
      label: "Canada",
      value: "Canada",
      regionId: 12,
      slug: "CA",
    },
    {
      label: "Australia",
      value: "Australia",
      regionId: 20,
      slug: "au",
    },
    {
      label: "Great Britain",
      value: "Great Britain",
      regionId: 4,
      slug: "GB",
    },
    {
      label: "Germany",
      value: "Germany",
      regionId: 21,
      slug: "DE",
    },
    {
      label: "Belgium",
      value: "Belgium",
      regionId: 1,
      slug: "BE",
    },
    {
      label: "Netherlands",
      value: "Netherlands",
      regionId: 26,
      slug: "NL",
    },
    {
      label: "Switzerland",
      value: "Switzerland",
      regionId: 25,
      slug: "CH",
    },
    {
      label: "Norway",
      value: "Norway",
      regionId: 24,
      slug: "NO",
    },
    {
      label: "France",
      value: "France",
      regionId: 11,
      slug: "FR",
    },
    // {
    //     "label":"Middle-East",
    //     "value":"UAE",
    //       "regionId": 6,
    //     "slug":"m-e"
    // },
    {
      label: "UAE",
      value: "UAE",
      regionId: 6,
      slug: "ar-ae",
    },
    {
      label: "Saudi Arabia",
      value: "Saudi Arabia",
      regionId: 7,
      slug: "SA",
    },
    {
      label: "Italy",
      value: "Italy",
      regionId: 8,
      slug: "IT",
    },
    {
      label: "Spain",
      value: "Spain",
      regionId: 10,
      slug: "ES",
    },
    {
      label: "Portugal",
      value: "Portugal",
      regionId: 22,
      slug: "PT",
    },
  ];
};

export const generateRegionsOptions = (
  channel: Channel,
  selectedSource: AvailableSource,
  myChannels: Channel[]
) => {
  // Custom Region Options for National Weather
  if (channel.slug === "weather_cities") {
    return generateNationalWeatherRegionOptions();
  }

  const languagesRegions = reduce(
    selectedSource.available_regions,
    (acc, region: any) => {
      region.available_languages.map((language: any) => {
        acc.push({
          label: `${region.region.name}, ${language.language.name}`,
          value: `${region.region.id}_${language.language.id}`,
          slug: region.region.slug,
          language: language.language,
          isInMyChannels: isInMyChannels(
            channel,
            selectedSource,
            region,
            language,
            myChannels
          ),
          /** @ts-ignore */
          hidden: region.hidden,
        });
      });

      return acc;
    },
    [] as any
  );

  return languagesRegions;
};

export const loadAsyncCityOptions = debounce((query, cb) => {
  if (!query) return;
  backend
    .loadClient()
    .Open.getCities(undefined, undefined, undefined, undefined, query)
    .then(({ data }) => {
      cb(
        orderBy(data, ["name", "country"]).map((city: any) => ({
          icon: "locationPinDark",
          label: `${city.name}, ${city.country}`,
          value: city.id,
        }))
      );
    });
}, SEARCH_DEBOUNCE_DURATION);

export const loadAsyncAirQualityCityOptions = debounce((query, cb) => {
  if (!query) return;
  backend
    .loadClient()
    .Open.getAirQualityLocations(
      undefined,
      undefined,
      undefined,
      undefined,
      query
    )
    .then(({ data }) => {
      cb(
        orderBy(data, ["name", "country"]).map((city: any) => ({
          icon: "locationPinDark",
          label: `${city.name || city.location}, ${city.country}`,
          value: city.id,
          country: city.country,
        }))
      );
    });
}, SEARCH_DEBOUNCE_DURATION);

export const loadAsyncStockOptions = debounce((query, cb) => {
  if (!query) return;
  backend
    .loadClient()
    .Open.getStocks(undefined, undefined, undefined, undefined, query)
    .then(({ data }) => {
      cb(
        data.map((stock: any) => ({
          ...stock,
          label: `${stock.symbol}`,
          description: `${stock.name}`,
          value: stock.id,
        }))
      );
    });
}, SEARCH_DEBOUNCE_DURATION);

export const getPlaceCity = (addressComponents: any[]) => {
  const address =
    addressComponents.find(
      (item) =>
        item.types.includes("administrative_area_level_1") ||
        item.types.includes("administrative_area_level_3") ||
        item.types.includes("locality")
    ) || {};
  return address.long_name;
};

export const getPlaceCountry = (addressComponents: any[]) => {
  const address =
    addressComponents.find((item) => item.types.includes("country")) || {};
  return address.long_name;
};

export const replaceCountry = (country: string | undefined) => {
  switch (country?.toLocaleLowerCase()) {
    case "south korea":
      return "Korea";
    case "united kingdom":
      return "Great Britian";
    default:
      return country;
  }
};

export const loadAsyncPlacesOptions = (options: { citiesOnly?: boolean }) =>
  debounce((query, cb) => {
    if (!query) return;
    const google = (window as any).google;
    // @TODO Clean up unnecessary use of map variable
    var autocompleteService = new google.maps.places.AutocompleteService();
    var map = new google.maps.Map(document.getElementById("map"));
    var service = new google.maps.places.PlacesService(map);
    const exclusions = ["Singapore", "Luxembourg"];
    autocompleteService.getQueryPredictions(
      { input: query },
      function (results: any, status: any) {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          const placesPromises = results
            .filter((place: any) => {
              if (!place || !place.types) {
                return false;
              }

              // Exclude counties and regions
              let condition =
                !place.types.includes("administrative_area_level_2") &&
                !place.types.includes("country");
              
              if (options.citiesOnly) {
                condition =
                  exclusions.includes(place.description) ||
                  place.types.includes("locality") ||
                  place.types.includes("neighborhood") ||
                  place.types.includes("administrative_area_level_1") ||
                  place.types.includes("administrative_area_level_3");
              }

              return condition;
            })
            .map((place: any) => {
              return new Promise((resolve) => {
                var request = {
                  placeId: place.place_id,
                  fields: ["address_component", "geometry", "name", "types"],
                };

                service = new google.maps.places.PlacesService(map);

                service.getDetails(
                  request,
                  (placeDetails: any, placeStatus: any) => {
                    if (
                      placeStatus == google.maps.places.PlacesServiceStatus.OK
                    ) {
                      place.details = placeDetails;
                      place.city = getPlaceCity(
                        placeDetails.address_components
                      );
                      place.country = getPlaceCountry(
                        placeDetails.address_components
                      );
                      place.lat = place.details.geometry.location.lat();
                      place.long = place.details.geometry.location.lng();
                      place.main_text = place.structured_formatting.main_text;
                      place.secondary_text =
                        place.structured_formatting.secondary_text;
                      // options.client.Open.getCountries(undefined, undefined, undefined, undefined, place.country)
                      // @TODO Replace with internal endpoint

                      fetch(
                        `https://api.countrylayer.com/v2/name/${replaceCountry(
                          place.country
                        )}?access_key=8f035ba86fc2a94b4c169a650d4150bf&FullText=`
                      )
                        .then((results) => results.json())
                        .then((data) => {
                          if (data && data[0] && data[0].alpha2Code) {
                            place.countryAlpha2Code = data[0].alpha2Code;
                          }
                          if (data && data[0] && data[0].languages) {
                            place.languages = data[0].languages;
                          }

                          resolve(place);
                        });
                    }
                  }
                );
              });
            });

          Promise.all(placesPromises).then((places: any[]) => {
            cb(
              places.map((item: any) => ({
                ...item,
                icon: "locationPinDark",
                label: `${item.main_text}`,
                value: item.place_id,
                description: item.secondary_text,
              }))
            );
          });
        }
      }
    );
  }, SEARCH_DEBOUNCE_DURATION);

export const LocationSelect: FunctionComponent<
  Partial<IAsyncSelectProps> & { citiesOnly?: boolean }
> = (props) => {
  const { client } = useContext(AppContext);
  return (
    <AsyncSelect
      title="Enter location"
      noOptionsMessage={() => null}
      loadOptions={loadAsyncPlacesOptions({ citiesOnly: props.citiesOnly })}
      withDescription
      components={{
        DropdownIndicator: null,
        IndicatorSeparator: null,
      }}
      withIcon
      icon={<Icon name="locationPin" />}
      styles={CustomFieldsSelectStyles as any}
      {...props}
    />
  );
};

export const CustomSelect: FunctionComponent<IAsyncSelectProps> = (props) => (
  <AsyncSelect
    {...(props as any)}
    {...SelectCommonProps}
    noOptionsMessage={() => null}
    loadingMessage={() => null}
    components={{
      DropdownIndicator: null,
      IndicatorSeparator: null,
      Option: SelectOptionComponent() as any,
      Menu: SelectMenuComponent({ title: props.menuTitle }) as any,
      Control: SelectControlComponent as any,
      ...props.components,
    }}
    styles={{
      input: (base: any) => ({ ...base, fontSize: 9 }),
      placeholder: (base: any) => ({
        ...base,
        fontSize: 9,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: "90%",
        lineHeight: "20px",
        paddingRight: "25px",
      }),
      singleValue: (base: any) => ({
        ...base,
        fontSize: 9,
        overflow: "visible",
      }),
      control: (base: any, state) => ({
        ...base,
        borderColor: state.isFocused ? INPUT_BORDER_COLOR : INPUT_BORDER_COLOR,
        boxShadow: "none !important",
        minHeight: "27.7px",
      }),
      menuPortal: (base: any) => ({
        ...base,
        zIndex: theme.header.zIndex + 20000000,
      }),
      option: SelectOptionStyle as any,
      ...props.styles,
    }}
  />
);

export const requiresCustomFieldsOnly = (channel: Channel) => {
  return [
    "traffic",
    "datacall_stocks",
    "airquality",
    "local_weather",
    "weather_cities",
  ].includes(channel.slug);
};
