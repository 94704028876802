import React, {
  useState,
  FunctionComponent,
  useContext,
  useEffect,
} from "react";
import {
  Block,
  Modal,
  IModalDefaultProps,
  Icon,
  IconName,
  buttonType,
  ModalIconContainer,
  ModalTitleContainer,
  ModalSubTitle,
  ModalTitle,
  ModalDescription,
  ModalCtaContainer,
} from "../../../seenspire-library";
import { AppContext } from "../../state";
import { useTranslation, Trans } from "react-i18next";
import { ModalButtons } from "../../components/ModalButtons/ModalButtons";
import styled from "styled-components";
import { HELP_URL } from "../../backend/consts";

enum ConfirmationStatusEnum {
  "INIT" = "INIT",
  "IN_PROGRESS" = "IN_PROGRESS",
  "DONE" = "DONE",
  "ERROR" = "ERROR",
}

interface IConfirmationModalProps extends IModalDefaultProps {
  isOpen: boolean;
  onConfirm?: any;
  confirmLabel?: any;
  confirmingLabel?: any;
  cancelLabel?: any;
  icon?: IconName;
  title?: any;
  subTitle?: any;
  description?: any;
  ctaType?: buttonType;
  notificationMode?: boolean;
  disableSubmit?: boolean;
}

const StyledLink = styled.a`
  font-weight: 600;
`;

const TransErrorDescription: FunctionComponent = () => {
  return (
    <Trans i18nKey="generals.error_description">
      <span></span>
      <StyledLink href={HELP_URL} target="_blank"></StyledLink>
    </Trans>
  );
};

const ConfirmationModal: FunctionComponent<IConfirmationModalProps> = ({
  isOpen,
  confirmLabel,
  confirmingLabel,
  cancelLabel,
  onCancel = () => {},
  onConfirm,
  icon,
  title,
  subTitle,
  description,
  ctaType,
  notificationMode,
  disableSubmit,
}) => {
  const { t } = useTranslation();
  const DEFAULT_VALUES = {
    IN_PROGRESS: {
      confirmLabel: t("generals.confirmation_button"),
      confirmingLabel: "Confirming ...",
      icon: "notice" as IconName,
      title: "Confirmation",
      subTitle: t("generals.confirmation_title"),
      description: t("generals.confirmation_description"),
      cancelLabel: "CANCEL",
    },
    ERROR: {
      confirmLabel: "Error, try again",
      confirmingLabel: "Confirming ...",
      icon: "error" as IconName,
      title: " ",
      subTitle: t("generals.error_title"),
      description: <TransErrorDescription />,
      cancelLabel: "CANCEL",
    },
  };

  const { theme } = useContext(AppContext);
  const [status, setStatus] = useState<ConfirmationStatusEnum>(
    ConfirmationStatusEnum.INIT
  );
  const [submitLabel, setSubmitLabel] = useState(getSubmitLabel());
  useEffect(() => {
    isOpen && setStatus(ConfirmationStatusEnum.INIT);
  }, [isOpen]);
  useEffect(() => {
    setSubmitLabel(getSubmitLabel());
  }, [status]);

  function getSubmitLabel() {
    switch (status) {
      case ConfirmationStatusEnum.IN_PROGRESS:
        return confirmingLabel || <Icon name="loading" strokeColor="white" />;
      case ConfirmationStatusEnum.ERROR:
        return confirmingLabel || t(DEFAULT_VALUES.ERROR.confirmingLabel);
      default:
        return confirmLabel || t(DEFAULT_VALUES.IN_PROGRESS.confirmLabel);
    }
  }

  const confirm = () => {
    setStatus(ConfirmationStatusEnum.IN_PROGRESS);
    const _p = onConfirm();

    if (_p) {
      _p.then(() => {
        setStatus(ConfirmationStatusEnum.DONE);
      }).catch((error: any) => {
        console.log(error);
        setStatus(ConfirmationStatusEnum.ERROR);
        return error;
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={() => onCancel(false)} theme={theme}>
      <Block>
        {status !== ConfirmationStatusEnum.ERROR && (
          <>
            <ModalIconContainer>
              <Icon
                iconSize="larger"
                name={icon || DEFAULT_VALUES.IN_PROGRESS.icon}
              />
            </ModalIconContainer>

            <ModalTitleContainer>
              <ModalSubTitle>
                {title || t(DEFAULT_VALUES.IN_PROGRESS.title)}
              </ModalSubTitle>
              <ModalTitle>
                {subTitle || t(DEFAULT_VALUES.IN_PROGRESS.subTitle)}
              </ModalTitle>
            </ModalTitleContainer>

            <ModalDescription>
              {description || t(DEFAULT_VALUES.IN_PROGRESS.description)}
            </ModalDescription>
          </>
        )}

        {status === ConfirmationStatusEnum.ERROR && (
          <>
            <ModalIconContainer>
              <Icon iconSize="larger" name={DEFAULT_VALUES.ERROR.icon} />
            </ModalIconContainer>

            <ModalTitleContainer>
              <ModalSubTitle>
                {title || t(DEFAULT_VALUES.ERROR.title)}
              </ModalSubTitle>
              <ModalTitle>
                {subTitle || t(DEFAULT_VALUES.ERROR.subTitle)}
              </ModalTitle>
            </ModalTitleContainer>

            <ModalDescription>
              {description || t(DEFAULT_VALUES.ERROR.description)}
            </ModalDescription>
          </>
        )}
      </Block>
      <ModalButtons
        ctaType={status === ConfirmationStatusEnum.ERROR ? "alert" : ctaType}
        onSubmit={() => confirm()}
        onCancel={!notificationMode && onCancel}
        submit={submitLabel}
        cancel={cancelLabel || t("generals.cancel_button")}
        disableSubmit={disableSubmit}
      />
    </Modal>
  );
};

export { ConfirmationModal, TransErrorDescription };
