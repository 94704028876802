import * as React from "react";
import { Block, IBlockProps } from "../Block/Block";
import {
  styled,
  unit,
  renderColor,
  withMedia,
  IResponsive,
} from "../../styled-components";
import { FunctionComponent } from "react";
import { Paper } from "../Paper/Paper";

const TABLE_VARIABLES = {
  sectionHeight: 40,
  spacing: 4,
};

interface ITableCellProps {
  width?: IResponsive<string>;
  colSpan?: number;
  rowSpan?: number;
}

interface ITableProps extends IBlockProps {
  cellBorders?: boolean;
  hoverable?: boolean;
  skipTableHead?: boolean;
}

// @TODO
// const TableSortLabel = styled(Block)``;
// const TablePagination = styled(Block)``;
// const TableFooter = styled(Block).attrs({as: 'tbody'})``;

const TableCell = styled(Block).attrs({ as: "td" })<ITableCellProps>`
  position: relative;
  ${({ width }) => withMedia("width", width)};
  ${({ padding }) =>
    padding
      ? withMedia("paddingTop", padding.top || TABLE_VARIABLES.spacing, unit)
      : withMedia("paddingTop", TABLE_VARIABLES.spacing, unit)};
  ${({ padding }) =>
    padding
      ? withMedia(
          "paddingRight",
          padding.right || TABLE_VARIABLES.sectionHeight / 2,
          unit
        )
      : withMedia("paddingRight", TABLE_VARIABLES.sectionHeight / 2, unit)};
  ${({ padding }) =>
    padding
      ? withMedia(
          "paddingBottom",
          padding.bottom || TABLE_VARIABLES.spacing,
          unit
        )
      : withMedia("paddingBottom", TABLE_VARIABLES.spacing, unit)};
  ${({ padding }) =>
    padding
      ? withMedia(
          "paddingLeft",
          padding.left || TABLE_VARIABLES.sectionHeight / 2,
          unit
        )
      : withMedia("paddingLeft", TABLE_VARIABLES.sectionHeight / 2, unit)};
`;

interface ITableRowProps {
  condenseSideCells?: boolean;
}
const TableRow = styled(Block).attrs({ as: "tr" })<ITableRowProps>`
  display: table-row;
  height: ${unit(TABLE_VARIABLES.sectionHeight)};
  ${TableCell} {
    &:not(:first-child):not(:last-child) {
      padding-right: 5px;
      padding-left: 5px;
    }
    &:first-child {
      padding-right: 5px;
    }
    &:last-child {
      padding-left: 5px;
    }
  }
`;

const TableBody = styled(Block).attrs({ as: "tbody" })``;

const TableHead = styled(Block).attrs({ as: "thead" })`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  ${TableRow} {
    height: ${unit(TABLE_VARIABLES.sectionHeight * 1.2)};
  }
  /* background-color: ${({ theme, bg, bgShade }) =>
    bg ? renderColor(theme, bg, bgShade) : theme.colors.gray.dark};
  color: ${({ theme, color, shade }) =>
    color ? renderColor(theme, color, shade) : theme.colors.white.base};
  fill: ${({ theme, color, shade }) =>
    color ? renderColor(theme, color, shade) : theme.colors.white.base}; */
`;

const Table = styled(Block).attrs({ as: "table" })<ITableProps>`
  table-layout: fixed;
  width: 100%;
  border-spacing: 0;
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: ${({ theme }) => theme.fontWeight.thin};
  ${TableBody} {
    ${TableRow} {
      ${TableCell} {
        border-bottom: ${({ theme, cellBorders }) =>
          cellBorders && `1px solid ${theme.colors.gray.lighter}`};
      }
      :last-child {
        ${TableCell} {
          border-bottom: ${({ theme, cellBorders }) =>
            cellBorders && `1px solid ${theme.colors.transparent.base}`};
        }
      }
      :hover {
        ${TableCell} {
          background-color: ${({ theme, hoverable }) =>
            hoverable && theme.colors.gray.lighter};
        }
      }
    }
  }
`;

const TableBase: FunctionComponent<ITableProps> = ({
  skipTableHead,
  children,
  ...props
}) => {
  const content = React.Children.toArray(children);
  const [head, ...body] = content;
  return (
    <Paper scrollX bg="white" borderRadius display="flex" direction="column">
      {!skipTableHead && (
        <Table responsiveAt="small" shadow="dark">
          <TableHead color="white" bg="gray" bgShade="dark">
            {head}
          </TableHead>
        </Table>
      )}

      <Paper scrollY responsiveAt="small">
        <Table {...props}>
          <TableBody>
            {skipTableHead && head}
            {body}
          </TableBody>
        </Table>
      </Paper>
    </Paper>
  );
};

export {
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Table,
  ITableCellProps,
  ITableProps,
  TableBase,
  ITableRowProps,
  // TableSortLabel
  // TablePagination
  // TableFooter
};
