import ReactModal from "react-modal";
import * as React from "react";
import { FunctionComponent } from "react";
import {
  theme as baseTheme,
  ITheme,
  createGlobalStyle,
  styled,
} from "../../styled-components";
import { Block, IBlockProps } from "../Block/Block";

const ModalTransitionClasses = createGlobalStyle<{ enableScroll?: boolean }>`
  
  body.ReactModal__Body--open {
    overflow: ${({ enableScroll }) => (enableScroll ? "auto" : "hidden")};
  }

  .ReactModal__Overlay {
      opacity: 0;
      transition: all 250ms ease-in-out;
  }

  .ReactModal__Overlay--after-open{
      opacity: 1;
  }

  .ReactModal__Overlay--before-close{
      opacity: 0;
  }
  `;
const ModalIconContainer = styled(Block)`
  display: grid;
  padding: ${({ padding }) => !padding && "20px"};
  justify-content: center;
`;

const ModalTitleContainer = styled(Block)`
  display: grid;
  padding: 10px 24px 0 24px;
  justify-content: center;
  justify-items: center;
  grid-gap: 4px;
`;

const ModalTitle = styled(Block)`
  color: #41505f;
  font-size: 20px;
  text-align: center;
  line-height: 24px;
  font-weight: 400;
`;

const ModalSubTitle = styled(Block)`
  color: ${({ theme }) => theme.colors.gray.dark};
  font-size: 14px;
  text-align: center;
  line-height: 16px;
  font-weight: 600;
  text-transform: uppercase;
`;

const ModalDescription = styled(Block)`
  color: ${({ theme }) => theme.colors.gray.dark};
  font-size: 15px;
  text-align: center;
  line-height: 22px;
  font-weight: 400;
  padding-top: 24px;
  padding-left: 44px;
  padding-right: 44px;
`;

const ModalCtaContainer: FunctionComponent<IBlockProps> = (props) => (
  <Block
    display="grid"
    gridGap={12}
    gridTemplate={{
      columns: `repeat(${React.Children.toArray(props.children).length}, 1fr)`,
    }}
    direction={{ small: "row", medium: "column" }}
    {...props}
  />
);

interface IModalDefaultProps {
  theme?: ITheme;
  onSubmit?: any;
  onCancel?: any;
  data?: any;
  skipStyling?: boolean;
  skipSizing?: boolean;
  disableScroll?: boolean;
  skipContentPadding?: boolean;
  customStyle?: ReactModal.Styles;
  skipDefaultHeight?: boolean;
  overflow?: string;
}
interface IModalProps extends ReactModal.Props, IModalDefaultProps {}

const Modal: FunctionComponent<IModalProps> = ({
  theme = baseTheme,
  skipStyling,
  skipSizing,
  customStyle,
  disableScroll,
  skipContentPadding,
  skipDefaultHeight,
  overflow = "hidden",
  ...props
}) => {
  const customOverlay = (customStyle && customStyle.overlay) || {};
  const customContent = (customStyle && customStyle.content) || {};
  const ModalStyles: ReactModal.Styles = {
    overlay: {
      zIndex: theme.header.zIndex * 2,
      position: "fixed",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: "#000000d1",
      ...customOverlay,
    },
    content: {
      border: "none",
      boxShadow: skipStyling
        ? "none"
        : "0 10px 20px 0 rgba(0, 0, 0, 0.15), 0 3px 4px 0 rgba(0, 0, 0, 0.05)",
      top: "50%",
      left: "50%",
      right: "unset",
      bottom: "unset",
      transform: "translateY(-50%) translateX(-50%)",
      background: skipStyling ? "none" : theme.colors.white.base,
      width: !skipSizing ? "550px" : undefined,
      minWidth: !skipSizing ? "550px" : undefined,
      padding: !skipContentPadding ? "24px" : undefined,
      display: "grid",
      overflow,
      gridGap: 24,
      alignContent: "space-between",
      minHeight: !skipDefaultHeight ? 434 : undefined,
      ...customContent,
    },
  };
  return (
    <>
      <ModalTransitionClasses enableScroll={disableScroll} />
      <ReactModal
        closeTimeoutMS={250}
        style={ModalStyles}
        ariaHideApp={false}
        {...props}
      >
        {props.children}
      </ReactModal>
    </>
  );
};

export {
  Modal,
  IModalProps,
  IModalDefaultProps,
  ModalDescription,
  ModalTitle,
  ModalSubTitle,
  ModalIconContainer,
  ModalTitleContainer,
  ModalCtaContainer,
};
