import ConfirmationModal from "../ConfirmationModal";
import React, { FunctionComponent } from "react";
import { get } from "lodash";
import { IError } from "../../state";
import { useTranslation } from "react-i18next";
import { TransErrorDescription } from "../ConfirmationModal/ConfirmationModal";

const ErrorNotificationModal: FunctionComponent<{
  error: IError;
  setError: any;
}> = ({ error, setError }) => {
  const { t } = useTranslation();
  const defaultErrorInfo = {
    subTitle: t("generals.error_title"),
    message: <TransErrorDescription />,
  };
  return (
    <ConfirmationModal
      isOpen={!!(error && error.error)}
      onCancel={() =>
        error.onCancel
          ? error
              .onCancel()
              .then(() => setError({ error: false, title: "", message: "" }))
          : Promise.resolve(setError({ error: false, title: "", message: "" }))
      }
      onConfirm={() =>
        error.onConfirm
          ? error
              .onConfirm()
              .then(() => setError({ error: false, title: "", message: "" }))
          : Promise.resolve(setError({ error: false, title: "", message: "" }))
      }
      icon="error"
      confirmLabel="Close"
      title={
        error.defaults
          ? defaultErrorInfo.subTitle
          : (error && error.subTitle) || " "
      }
      subTitle={(error && error.title) || " "}
      description={
        error.defaults
          ? defaultErrorInfo.message
          : get(error, "errorResponse.response.data.error.message") ||
            get(error, "errorResponse.response.data.message") ||
            (error && error.message)
      }
      notificationMode={true}
      ctaType="accent"
    />
  );
};

export { ErrorNotificationModal };
