import { css, unit, styled } from "../../styled-components";
import { INPUT_ICON_DEFAULTS } from "./variables";
import { Block } from "../Block/Block";
import { TransitionBase } from "../styles";

export const InputIconPaddingLeft = css<any>`
  padding-left: ${({ theme, withIcon, iconPosition }) =>
    withIcon && iconPosition === "left"
      ? unit(theme.element.padding.large + INPUT_ICON_DEFAULTS.size)
      : unit(theme.element.padding.large)};
  padding-right: ${({ theme, withIcon, iconPosition }) =>
    withIcon && iconPosition === "right"
      ? unit(theme.element.padding.large + INPUT_ICON_DEFAULTS.size)
      : unit(theme.element.padding.large)};
`;

export const InputLabelLeft = css<any>`
  left: ${({ theme, withIcon, iconPosition }) =>
    withIcon && iconPosition === "left"
      ? unit(theme.element.padding.large + INPUT_ICON_DEFAULTS.size)
      : unit(theme.element.padding.large)};
`;

export const FieldError = styled(Block).attrs({})<{
  errorMessage: any;
  withoutField?: boolean;
}>`
  width: 100%;
  position: absolute;
  bottom: ${({ withoutField }) => (withoutField ? "-10px" : "5px")};
  transform: ${({ errorMessage }) =>
    errorMessage ? "translateY(100%)" : "translateY(0)"};
  padding: 5px;
  padding-left: ${({ theme }) => unit(theme.element.padding.large)};
  border-radius: ${({ theme, withoutField }) =>
    withoutField
      ? unit(theme.borderRadius)
      : `0px 0px ${unit(theme.borderRadius)} ${unit(theme.borderRadius)}`};
  opacity: ${({ errorMessage }) => (errorMessage ? 1 : 0)};
  ${TransitionBase};
`;

export type IconPositionType = "right" | "left";
