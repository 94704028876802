import * as React from "react";
import { FunctionComponent } from "react";
import { styled, renderColor } from "../../styled-components";
import { BorderRadiusBase } from "../styles";
import { Block, IBlockProps } from "../Block/Block";
import { Spacer } from "../Spacer/Spacer";

type ContainerHeaderSize = "small" | "base" | "large";

interface IPanelProps {
  borderLess?: boolean;
}
const Panel = styled(Block).attrs({})<IPanelProps>`
  padding: ${({ padding }) =>
    padding && (padding.all || padding.all === 0) ? padding.all : "24px"};
  border: solid 1px
    ${({ theme, borderLess }) =>
      borderLess ? "transparent" : theme.colors.gray.lightest};
  background-color: ${({ theme, bg, bgShade }) =>
    renderColor(theme, bg, bgShade) || theme.colors.background.dark};
  ${({ borderRadius }) => (borderRadius === false ? "" : BorderRadiusBase)};
`;

interface IPanelHeaderProps extends IBlockProps {
  title?: string;
  description?: any;
  headerSize?: ContainerHeaderSize;
  onClick?: () => void;
}
const PanelHeaderStyled = styled(Block)`
  width: 100%;
`;
const PanelHeaderTitle = styled(Block)`
  font-size: ${({ theme }) => theme.fontSize.base};
  color: ${({ theme }) => theme.colors.gray.darkest};
  font-weight: 700;
`;

const PanelHeaderDescription = styled(Block)`
  color: ${({ theme }) => theme.colors.gray.dark};
  font-weight: 600;
  font-size: 12px;
  padding-top: 2px;
`;

const PanelHeader: FunctionComponent<IPanelHeaderProps> = ({
  children,
  title,
  description,
  headerSize,
  ...props
}) => {
  return (
    <PanelHeaderStyled display="flex" alignItems="center" {...props}>
      <Block display="grid" alignItems="center" gridGap={4}>
        <PanelHeaderTitle
          textTransform="uppercase"
          fontSize={headerSize || "large"}
          fontWeight="bold"
        >
          {title}
        </PanelHeaderTitle>
        {description && (
          <PanelHeaderDescription>{description}</PanelHeaderDescription>
        )}
      </Block>
      <Spacer />
      {children}
    </PanelHeaderStyled>
  );
};

export { Panel, PanelHeader, IPanelProps, IPanelHeaderProps };
