import axios, { AxiosRequestConfig } from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";

import { getToken, storeToken, clearSession } from "./session";
import { getSlug } from "../routes";
const dotenv = require("dotenv");
dotenv.config();

const basePath = {
  baseURL: process.env.REACT_APP_API,
};

const instance = axios.create();
const interceptedInstance = createAuthRefreshInterceptor(
  axios.create(),
  refreshTokenInterceptor
);

interceptedInstance.interceptors.request.use(requestInterceptor);

function requestInterceptor(request: AxiosRequestConfig) {
  // console.log(`[requestInterceptor]`)
  // get token
  const token = getToken();

  // if no token, stop, redirect to login
  if (!token) return goToLogin();

  request.headers = {
    ...request.headers,
    Authorization: `Bearer ${token.access_token}`,
  };
  // continue with the request
  return request;
}

async function requestAccessToken(refresh_token: string) {
  const url = `${basePath.baseURL}/oauth/v2/token`;
  const params = {
    client_id: "1_qwgh3gijy6oc44kw04wco0swookgwgws0csww4cgw4oss80s4",
    client_secret: "44eugnuavykgosskw00c84wwoosc4oosk80000w88o48wkssso",
    grant_type: "refresh_token",
    refresh_token,
  };

  try {
    const token = await instance.post(url, params);
    return storeToken(token);
  } catch (error) {
    throw error;
  }
}

async function refreshTokenInterceptor(request: AxiosRequestConfig) {
  // get token
  const currentToken = getToken();
  try {
    const token = await requestAccessToken(currentToken.refresh_token);
    request.headers = {
      ...request.headers,
      Authorization: `Bearer ${token.access_token}`,
    };
    storeToken(token);
    return request;
  } catch (error) {
    console.error(`[requestInterceptor]`, error);
    clearSession();

    goToLogin();
    return error;
  }
}

function goToLogin() {
  const slug = getSlug();

  window.location.href = `${window.location.href}/${slug}/login?redirect=${window.location.pathname}`;
  return Promise.reject({ error: "No token!" });
}

export { interceptedInstance as api, instance as openApi, basePath };
