import { FormikHelpers } from "formik";
import {
  formatServerValidationErrors,
  formatServerGeneralError,
} from "../../util";

export enum formStatus {
  success = "success",
  postSuccess = "postSuccess",
  failed = "failed",
}

export const onSuccess = (
  actions: FormikHelpers<any>,
  cb: any,
  callBackTimeout: number = 2000
) => {
  actions.setStatus({ status: formStatus.success });
  setTimeout(
    () => actions.setStatus({ status: formStatus.postSuccess }),
    callBackTimeout / 2
  );
  setTimeout(() => cb(), callBackTimeout);
};

export const onFail = (
  actions: FormikHelpers<any>,
  error: any,
  cb: any = () => {},
  customErrorMessage?: string
) => {
  actions.setStatus({
    status: formStatus.failed,
    error:
      formatServerGeneralError(error) ||
      customErrorMessage ||
      "Sorry, issue occured!",
  });
  actions.setErrors(formatServerValidationErrors(error));
  actions.setSubmitting(false);
  cb();
};

// export interface IFormCtaButtonProps extends Partial<ICtaButtonProps> {
//   label?: string;
//   form: FormikProps<any>
//   disabledSubmit?: boolean
// }

// const FormCtaButton: FunctionComponent<IFormCtaButtonProps> = ({ form, disabledSubmit, ...props }) => {
//   const status = form.status && form.status.status;
//   const { t } = useTranslation();
//   return (
//     <CtaButton disabled={!form.isValid} type={disabledSubmit ? 'button' : 'submit'}
//       keepOpen={props.keepClose ? false : (form.isSubmitting || status === formStatus.success)}

//       opacity={
//         (form.dirty && form.isValid) ? 1 : 0
//       }

//       icon={
//         form.isSubmitting ?
//           (status === formStatus.success || status === formStatus.postSuccess ? 'check' : 'loading') :
//           'save'
//       }

//       buttonType={
//         form.isSubmitting ?
//           (status === formStatus.success || status === formStatus.postSuccess ? 'success' : 'accent') :
//           (status === formStatus.failed ? 'alert' : 'cta')
//       }

//       label={
//         form.isSubmitting ?
//           (status === formStatus.success || status === formStatus.postSuccess ? t('Saved') : t('Saving...')) :
//           t('generals.save_label')
//       }

//       {...props} />
//   )
// };
// export default FormCtaButton;
