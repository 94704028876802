import { IBlockProps, Block } from "../Block/Block";
import { styled } from "../../styled-components";

interface IPaperProps extends IBlockProps {
  overflow?: string;
  scrollY?: boolean;
  scrollX?: boolean;
}

const Paper = styled(Block).attrs({})<IPaperProps>`
  overflow: ${({ overflow }) => overflow};
  overflow-x: ${({ scrollX }) => scrollX && "auto"};
  overflow-y: ${({ scrollY }) => scrollY && "auto"};
`;
export { Paper, IPaperProps };
