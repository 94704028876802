import React, { useEffect, useState, FunctionComponent } from "react";
import {
  TransitionBase,
  styled,
  Block,
  hsla,
  IBlockProps,
  IIconProps,
  Icon,
} from "../../../seenspire-library";

const LoadingIndicatorStyled = styled(Block)<{ isLoading: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: ${({ theme, bg }) => !bg && hsla(theme.colors.primary.base, 0.4)};
  opacity: ${({ theme, isLoading }) => (isLoading ? 1 : 0)};
  visibility: ${({ theme, isLoading }) => !isLoading && "hidden"};
  ${TransitionBase}
`;

const LoadingIndicator: FunctionComponent<
  IBlockProps & { loading: boolean; iconProps?: IIconProps }
> = ({ loading, iconProps, ...props }) => {
  const _props: IBlockProps = {
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
    justifyItems: "center",
    gridGap: 20,
    ...props,
  };
  const _iconProps: IIconProps = {
    name: "loading",
    iconSize: "larger",
    strokeColor: "accent",
    strokeShade: "light",
    ...iconProps,
  };
  return (
    <LoadingIndicatorStyled isLoading={loading} {..._props}>
      <Icon {..._iconProps} />
    </LoadingIndicatorStyled>
  );
};

export { LoadingIndicator };
