import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

// Load the presenation as JSON
// https://us-central1-rl-seenspire-v2-production.cloudfunctions.net/api/translations/350fa77b438cc68c62893c1ded9ad749/MkLbv6L9IL/ec7bee77f11a2887c4d6548d1d323c42e5eeb9e5bc998faa54c0535cc878986e

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(
    {
      lng: "en",
      debug: false,
      backend: {
        loadPath:
          process.env.NODE_ENV === "production"
            ? "/locales/{{lng}}/{{ns}}.json"
            : "/public/locales/{{lng}}/{{ns}}.json",
      },
    },
    undefined
  );

export default i18n;
