import AR from "../../../assets/flags/AR.svg";
import BE from "../../../assets/flags/BE.svg";
import PK from "../../../assets/flags/PK.svg";
import US from "../../../assets/flags/US.svg";
import GB from "../../../assets/flags/GB.svg";
import RU from "../../../assets/flags/RU.svg";
import AE from "../../../assets/flags/AE.svg";
import SA from "../../../assets/flags/SA.svg";
import IT from "../../../assets/flags/IT.svg";
import JP from "../../../assets/flags/JP.svg";
import ES from "../../../assets/flags/ES.svg";
import FR from "../../../assets/flags/FR.svg";
import CA from "../../../assets/flags/CA.svg";
import TR from "../../../assets/flags/TR.svg";
import BR from "../../../assets/flags/BR.svg";
import RW from "../../../assets/flags/RW.svg";
import CH from "../../../assets/flags/CH.svg";
import IN from "../../../assets/flags/IN.svg";
import CL from "../../../assets/flags/CL.svg";
import AU from "../../../assets/flags/AU.svg";
import DE from "../../../assets/flags/DE.svg";
import PT from "../../../assets/flags/PT.svg";
import GL from "../../../assets/flags/GL.svg";
import IS from "../../../assets/flags/IS.svg";
import NL from "../../../assets/flags/NL.svg";
import SE from "../../../assets/flags/SE.svg";
import NO from "../../../assets/flags/NO.svg";
import FI from "../../../assets/flags/FI.svg";
import DK from "../../../assets/flags/DK.svg";
import EU from "../../../assets/flags/EU.svg";

export default {
  AR,
  BE,
  PK,
  US,
  GB,
  RU,
  AE,
  SA,
  IT,
  JP,
  ES,
  FR,
  CA,
  TR,
  BR,
  RW,
  CH,
  IN,
  CL,
  AU,
  DE,
  PT,
  GL,
  IS,
  NL,
  SE,
  NO,
  FI,
  DK,
  EU,
};
