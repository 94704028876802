import React, { FunctionComponent } from "react";
import {
  styled,
  css,
  Block,
  IBlockProps,
  hsla,
  TransitionBase,
} from "../../../seenspire-library";

const CircleSmall = css`
  width: 28px;
  height: 28px;
  font-size: 11px;
`;

const CircleMedium = css`
  width: 34px;
  height: 34px;
  font-size: 11px;
`;

const CircleLarge = css`
  width: 48px;
  height: 48px;
  font-size: 18px;
`;

const CircleLarger = css`
  width: 64px;
  height: 64px;
  font-size: 22px;
`;

const CircleLargest = css`
  width: 80px;
  height: 80px;
  font-size: 24px;
`;

const CircleSuperLarge = css`
  width: 96px;
  height: 96px;
  font-size: 28px;
`;

const getCircleSize = (circleSize: string) => {
  switch (circleSize) {
    case "superlarge":
      return CircleSuperLarge;
    case "largest":
      return CircleLargest;
    case "larger":
      return CircleLarger;
    case "large":
      return CircleLarge;
    case "medium":
      return CircleMedium;
    default:
      return CircleSmall;
  }
};

const Circle = styled(Block).attrs({})<{
  circleSize:
    | "superlarge"
    | "largest"
    | "larger"
    | "large"
    | "medium"
    | "small";
  withHover?: boolean;
  hovered?: boolean;
}>`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.gray.base};
  ${({ circleSize }) => getCircleSize(circleSize)}
  border: solid 2px ${({ hovered, theme }) =>
    hovered ? hsla(theme.colors.white.base, 0.5) : "transparent"};
  :hover {
    border-color: ${({ withHover, theme }) =>
      withHover && hsla(theme.colors.white.base, 0.5)};
    ${TransitionBase};
  }
  ${TransitionBase};
`;

const ImageBlock = styled(Block).attrs({})<{ mediaUrl: string }>`
  right: 0;
  top: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  background-image: ${({ mediaUrl }) => `url(${mediaUrl})`};
  background-size: cover;

  background-repeat: no-repeat;
`;

type FontSize =
  | "base"
  | "small"
  | "smallest"
  | "smaller"
  | "large"
  | "larger"
  | "largest"
  | undefined;
type AvatarSize =
  | "superlarge"
  | "largest"
  | "larger"
  | "large"
  | "medium"
  | "small";

const Avatar: FunctionComponent<
  IBlockProps & {
    user?: any;
    avatarSize: AvatarSize;
    fontSize?: FontSize;
    withHover?: boolean;
    hovered?: boolean;
    mediaUrl?: string;
  }
> = ({ avatarSize, user, mediaUrl, fontSize, ...props }) => {
  const { first_name, last_name, avatar } = user;
  const label = `${(first_name && first_name[0].toUpperCase()) || "-"}${
    (last_name && last_name[0].toUpperCase()) || "-"
  }`;
  const media = mediaUrl || avatar;
  return (
    <Circle circleSize={avatarSize} {...props}>
      {media ? (
        <ImageBlock mediaUrl={media} />
      ) : (
        <Block
          cursor="default"
          fontSize={fontSize || "small"}
          color="white"
          fontWeight="bold"
        >
          {label}
        </Block>
      )}
    </Circle>
  );
};

export { Avatar };
