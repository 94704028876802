import React, {
  FunctionComponent,
  useState,
  useEffect,
  useContext,
} from "react";
import {
  IInputFieldWithIconProps,
  FullInput,
  Icon,
  styled,
  IIconProps,
  Block,
} from "../../../seenspire-library";
import { useToggle } from "react-use";
import { Menu } from "../Menu/Menu";
import { BlockPicker } from "react-color";
import { AppContext } from "../../state";
import { chain } from "lodash";

const ColorPickerIcon = styled(Icon)<{ value: any }>`
  svg {
    stroke: ${({ value }) => !value && "#f0f8ff40"};
    stroke-dasharray: ${({ value }) => !value && "5px"};
  }
`;

const ColorPickerIconSquare = styled(ColorPickerIcon)`
  width: 36px;
  height: 36px;
  path {
    fill: ${({ value }) => value ?? "#f0f8ff40"};
  }
`;

const ColorPickerIconRectangular = styled(ColorPickerIcon)`
  width: 48px;
  height: 32px;
  path {
    fill: ${({ value }) => value ?? "#f0f8ff40"};
  }
`;

const ColorInput: FunctionComponent<
  IInputFieldWithIconProps & {
    onColorSelected: (color: string) => void;
    pickerPosition?: "right" | "left";
    openDirection?: "down" | "up";
  }
> = ({
  value,
  onColorSelected,
  pickerPosition = "left",
  openDirection,
  onChange,
  ...props
}) => {
  const { theme } = useContext(AppContext);
  const [isOpen, toggleModal] = useToggle(false);
  const [color, setColor] = useState(String(value || ""));

  const onColorPick = (e: any) => {
    setColor(e.hex);
    onColorSelected(e.hex);
  };

  const iconProps: Partial<IIconProps> = {
    iconFill: color,
  };

  const icon = (
    <Block position="relative" zIndex={1000}>
      <Menu
        menuButton={
          props.iconPosition === "right" ? (
            <ColorPickerIconRectangular
              value={value}
              name="changeColorRectangular"
              {...iconProps}
            />
          ) : (
            <ColorPickerIconSquare
              value={value}
              name="changeColor"
              {...iconProps}
            />
          )
        }
        withPortal={(props as any).withPortal}
        manualWidth={true}
        menuWidth="300px"
        adjustments={{ top: 13, right: pickerPosition === "left" ? -15 : -249 }}
        skipMenuBackgroundColor={true}
        openDirection={openDirection || "down"}
        onMenuOpen={(status) => toggleModal(status)}
      >
        <BlockPicker
          width="100%"
          triangle="hide"
          styles={{
            default: {
              input: { display: "none" },
              card: {
                borderRadius: theme.borderRadius,
                padding: 10,
                paddingBottom: 0,
              },
              head: { borderRadius: theme.borderRadius },
              body: { padding: "unset", paddingTop: 10, paddingLeft: 0 },
            },
          }}
          color={color}
          onChangeComplete={onColorPick}
        />
      </Menu>
    </Block>
  );

  useEffect(() => {
    setColor(String(value || ""));
  }, [value]);

  const inputOnChange:
    | ((event: React.ChangeEvent<HTMLInputElement>) => void)
    | undefined = (e) => {
    const _value = e.target.value || "";
    const cleanValue = chain(_value)
      .filter((i) => i !== "#")
      .join("")
      .value();
    onColorSelected(`#${cleanValue}`);
    e.target.value = `#${cleanValue}`;
    onChange && onChange(e);
  };

  return (
    <FullInput
      value={value}
      icon={icon}
      onChange={inputOnChange}
      iconPosition={props.iconPosition}
      wrapperProps={{ zIndex: isOpen ? 1000 : undefined }}
      {...props}
    />
  );
};

export { ColorInput };
