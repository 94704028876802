import {
  css,
  styled,
  unit,
  theme,
  scaleFontSize,
} from "../../styled-components";
import { LABEL_DEFAULTS } from "../Input/variables";
import { TransitionBase } from "../styles";

const LabelActive = css<{ scaleAmount?: number }>`
  transform: scale(${LABEL_DEFAULTS.scale});
  top: ${({ scaleAmount }) =>
    scaleAmount
      ? unit(LABEL_DEFAULTS.top / scaleAmount)
      : unit(LABEL_DEFAULTS.top)};
  /* @TODO Use dynamic theme */
  font-size: ${({ scaleAmount }) =>
    scaleAmount
      ? scaleFontSize(theme.fontSize.small, scaleAmount)
      : theme.fontSize.small};
`;

const Label = styled.label<{ scaleAmount?: number }>`
  color: ${({ theme }) => theme.colors.gray.darker};
  font-size: ${({ theme, scaleAmount }) =>
    scaleAmount
      ? scaleFontSize(theme.fontSize.large, scaleAmount)
      : theme.fontSize.large};
  position: absolute;
  transform-origin: left top;
  top: 50%;
  transform: translateY(-50%) scale(1);
  opacity: 0.6;
  ${TransitionBase};
  &.active {
    ${LabelActive}
  }
`;

const DarkModeLabelColor = css`
  color: ${({ theme }) => theme.colors.white.base};
`;

export { Label, LabelActive, DarkModeLabelColor };
