import { initialTheme } from "../../state";

/* Global constant controls the size of the active item, and sliding siblings*/
export const SCALE_AMOUNT = 1.35;
export const SCALE_AMOUNT_MIN = 1.1;
export const SET_ACTIVE_DEBOUNCE = 60;
export const SLIDER_ITEMS_SPACING = 4;
export const OPEN_ITEM_HEIGHT = 60;
export const SIDE_PADDING = {
  small: 10,
  default: 4,
};

// @TODO fix magic number, this issue occurred when working with SliderPreview
export const LEVELS = {
  ACTIVE_LEVEL: initialTheme ? initialTheme.header.zIndex - 1 : 9999 - 1,
  ACTIVE_ITEM: initialTheme ? initialTheme.header.zIndex - 2 : 9999 - 2,
};
/* Transition Settings */
export const TRANSITION_DURATION = 120;

export const LAYOUT_MAX_WIDTH = 1200;

export const CUSTOM_BREAKPOINTS = {
  BETWEEN_LARGE_AND_XLARGE: 1540,
};
