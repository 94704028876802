import React from "react";
import { RouteConfig } from ".";
import { cloneDeep } from "lodash";
const MyStream = React.lazy(() => import("../containers/MyStreams/MyStreams"));
const StreamUrl = React.lazy(() => import("../containers/MyStreams/StreamUrl"));

export const generateStreamRoutes = (
  streamRoutes: RouteConfig[] = StreamRoutes,
  streamId: string
) => {
  const streamRoutesClone = cloneDeep(streamRoutes).map((route) => {
    route.path = route.path.replace(":id", streamId);
    return route;
  });
  return [...streamRoutesClone];
};

export type StreamRoutesNames = "stream_feeds" | "stream_url";

const StreamRoutes: RouteConfig[] = [
  {
    name: "stream_feeds",
    label: "Edit stream",
    path: "/:slug/stream/:id/edit-stream",
    requiredUserRoles: ["ROLE_MANAGER", "ROLE_OWNER"],
    component: MyStream,
  },
  {
    name: "stream_url",
    label: "Copy stream url",
    path: "/:slug/stream/:id/stream-url",
    requiredUserRoles: ["ROLE_MANAGER", "ROLE_OWNER", "ROLE_CURATOR"],
    component: StreamUrl,
  },
];

export default StreamRoutes;
