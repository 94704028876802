import React, {
  useState,
  FunctionComponent,
  useContext,
  useEffect,
} from "react";
import {
  Block,
  Modal,
  Select,
  PanelHeader,
  IModalProps,
  Icon,
  Button,
  ModalTitle,
  ModalSubTitle,
  ModalDescription,
  ModalIconContainer,
  ModalTitleContainer,
  ModalCtaContainer,
  IconName,
} from "../../../seenspire-library";
import PanelForm from "../../components/PanelForm/PanelForm";
import * as Yup from "yup";
import {
  Invite,
  ActiveChannelType,
  Subscription,
  Plan,
  UpgradeRequest,
  UpgradeRequest5,
  Plan5,
} from "seenspire-api-client";
import {
  formatServerValidationErrors,
  FeedTypeEnum,
  FeedType,
  isFeedType,
} from "../../util";
import InputField from "../../components/InputField/InputField";
import { AppContext } from "../../state";
import { useTranslation } from "react-i18next";
import {
  ModalButtons,
  FormModalButtons,
} from "../../components/ModalButtons/ModalButtons";
import { ISocialNetworksTypesConfig } from "../../containers/AccountSocialNetworks/AccountSocialNetworks";
import { chain, get, every } from "lodash";
import { AuthRoutNames } from "../../routes/AuthRoutes";
import { FeaturesConfig } from "../../containers/Dashboard/MethodsAndLogic";
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator";
import { Link } from "../../components/LinkButton/LinkButton";
import { HELP_URL } from "../../backend/consts";

const CONTEXT_DEFAULT_DATA: any = {
  INFOTAINMENT: {
    icon: "feature",
    title: " ",
    subTitle: "feeds.locked_unlock_infotainment_title",
    description: "feeds.locked_unlock_infotainment_description",
  },
  SOCIAL: {
    icon: "feature",
    title: " ",
    subTitle: "feeds.locked_unlock_social_title",
    description: "feeds.locked_unlock_social_description",
  },
  TEAMS: {
    icon: "feature",
    title: " ",
    subTitle: "feeds.locked_unlock_teams_title",
    description: "feeds.locked_unlock_teams_description",
  },
};

const RequestTrialEndedModal: FunctionComponent<IModalProps> = ({
  isOpen: ignored,
  ...props
}) => {
  const {
    client,
    authenticated,
    activated,
    theme,
    currentRoute,
    logout,
    requestTrialModalIsOpen,
    setRequestTrialModalIsOpen,
    goTo,
    account,
  } = useContext(AppContext);
  const { t } = useTranslation();
  const [error, setError] = useState();
  const [loading, setLoading] = useState<boolean>(false);
  const [requestedFeature, setRequestedFeature] = useState<ActiveChannelType>({
    id: 0,
    title: "",
    slug: "",
  });
  const [plans, setPlans] = useState<Plan[]>();
  const [currentSubscription, setCurrentSubscription] =
    useState<Subscription>();
  const [parentsSubscription, setParentSubscriptions] = useState();
  const [parentHasFeature, setParentHasFeature] = useState<boolean>(false);
  const [hasCurrentPendingRequest, setHasCurrentPendingRequest] =
    useState<UpgradeRequest5>();
  const [context, setContext] = useState({
    icon: "feature" as IconName,
    title: "",
    subTitle: "",
    description: "",
  });
  useEffect(() => {
    if (!requestTrialModalIsOpen) return;
    setError(undefined);
    setLoading(true);
    loadData().then(() => setLoading(false));
  }, [requestTrialModalIsOpen]);

  const loadData = async () => {};

  const oke = () => {
    localStorage.setItem("trial_ended_seen", "1");
    window.location.reload();
  };

  return (
    <Modal
      isOpen={
        account.account.is_extra_trial_finished &&
        !localStorage.getItem("trial_ended_seen")
      }
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => setRequestTrialModalIsOpen(undefined)}
      theme={theme}
      {...props}
    >
      {!loading ? (
        <>
          <Block>
            <ModalIconContainer>
              <Icon iconSize="larger" name={context.icon} />
            </ModalIconContainer>

            <ModalTitleContainer>
              <ModalSubTitle>TRIAL HAS EXPIRED</ModalSubTitle>
              <ModalTitle>Your trial has expired</ModalTitle>
            </ModalTitleContainer>

            <ModalDescription>
              We hope you have enjoyed Seenspire's unlimited and diverse
              content. Would you like to upgrade your subscription to continue
              having access to all Seenspire feeds?
            </ModalDescription>
          </Block>

          <ModalCtaContainer>
            <Button
              elementWidth="block"
              buttonType="outline"
              onClick={() => {
                oke();
              }}
            >
              Keep current subscription
            </Button>

            <Button
              elementWidth="block"
              onClick={() => {
                oke();
              }}
              buttonType="cta"
            >
              Upgrade subscription
            </Button>
          </ModalCtaContainer>
        </>
      ) : (
        <Block>
          <LoadingIndicator bg="transparent" loading={true} />
        </Block>
      )}
    </Modal>
  );
};

export { RequestTrialEndedModal };
