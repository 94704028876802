export const LABEL_DEFAULTS = {
  scale: 1,
  top: 12,
};

export const INPUT_ICON_DEFAULTS = {
  size: 45,
};

export const SELECT_DEFAULTS = {
  // https://react-select.com/styles#using-classnames
  classNamePrefix: "app-select",
  controlClassName: "app-select__control",
  singleValueClassName: "app-select__single-value",
  inputClassName: "app-select__input",
  selectContainerClassName: "app-select__container",
  dropdownNameAttribute: 'name="dropdownIcon"',
};

export const INPUT_BORDER_COLOR = "#D5DBE2";
export const INPUT_BORDER_COLOR_DARK_MODE = "rgba(255, 255, 255, 0.1)";
