import * as React from "react";
import { FunctionComponent } from "react";
import {
  BorderRadiusBase,
  ElementWidth,
  FontSizeBase,
  ElementHeightBase,
  ColorState,
} from "../styles";
import { IBlockProps, BlockStyles, Block } from "../Block/Block";
import {
  styled,
  IElementHeightBase,
  unit,
  getElementSize,
  css,
  IElementWidth,
  hsla,
} from "../../styled-components";
import { TextStyles } from "../Text/Text";
import { StyledCheckbox, CheckboxLabel } from "../Checkbox/Checkbox";
import {
  SELECT_DEFAULTS,
  INPUT_BORDER_COLOR_DARK_MODE,
  INPUT_BORDER_COLOR,
} from "./variables";
import { Icon } from "../Icon/Icon";
import { Label, LabelActive, DarkModeLabelColor } from "../Label/Label";
import {
  InputIconPaddingLeft,
  InputLabelLeft,
  FieldError,
  IconPositionType,
} from "./shared";

type inputStyleType = "primary" | "cta" | "alert" | "success";
interface IInputProps extends IBlockProps, IElementWidth, IElementHeightBase {
  inputStyleType?: inputStyleType;
}

const DarkModeInputBackgroundColor = css`
  background-color: ${({ theme }) => hsla(theme.colors.white.base, 0.05)};
`;
const DarkModeInputColor = css`
  color: ${({ theme }) => theme.colors.white.base};
`;
const DarkModeInputBorderColor = css`
  border-color: ${INPUT_BORDER_COLOR_DARK_MODE};
`;

const InputIcon = styled(Block)<{
  iconPosition?: IconPositionType;
  scaleAmount?: number;
}>`
  position: absolute;
  top: 50%;
  left: ${({ iconPosition, scaleAmount }) =>
    iconPosition === "left" &&
    (scaleAmount ? unit(15 / scaleAmount) : unit(15))};
  right: ${({ iconPosition, scaleAmount }) =>
    iconPosition === "right" &&
    (scaleAmount ? unit(15 / scaleAmount) : unit(15))};
  transform: translateY(-50%);
  z-index: 100;
`;

const Input = styled.input.attrs({})<IInputProps>`
  border: solid 1px transparent;
  color: ${({ theme }) => theme.colors.gray.darker};
  background-color: ${({ theme }) => theme.colors.white.base};
  ${({ inputStyleType }) =>
    inputStyleType && ColorState("border-color")[inputStyleType]};
  border-color: ${({ inputStyleType }) =>
    !inputStyleType && INPUT_BORDER_COLOR};
  /* ${({ inputStyleType }) =>
    inputStyleType
      ? ColorState("background-color", 0)[inputStyleType]
      : ColorState("background-color", 0).gray}; */
  /* background-color: white; */
  padding: ${({ fontSize, theme }) =>
    unit(theme.element.padding[getElementSize(fontSize)])};
  /* changes should reflect on FieldWrapper > textarea*/
  padding-top: ${({ fontSize, theme }) =>
    unit(theme.element.padding[getElementSize(fontSize)] * 1.5)};
  padding-bottom: ${({ fontSize, theme }) =>
    unit(theme.element.padding[getElementSize(fontSize)] * 0.5)};
  ${ElementHeightBase};
  ${BorderRadiusBase};
  ${ElementWidth};
  ${BlockStyles};
  ${TextStyles};
  ${FontSizeBase};

  &:disabled {
    background-color: ${({ theme }) => theme.colors.background.darkest};
    :hover,
    :active,
    :focus {
      background-color: ${({ theme }) => theme.colors.background.darkest};
    }
  }
`;

interface IDarkModeBlockProps extends IBlockProps {
  darkMode?: boolean;
}

const DarkModeWrapperFunction: FunctionComponent<IDarkModeBlockProps> = ({
  children,
  ...props
}) => (
  <Block bg="primary" bgShade="dark" color="gray" shade="lightest" {...props}>
    {children}
  </Block>
);

const DarkModeWrapper = styled(DarkModeWrapperFunction)`
  ${Input} {
    ${DarkModeInputBackgroundColor};
    ${DarkModeInputBorderColor};
    ${DarkModeInputColor};
  }
  ${Label} {
    ${DarkModeLabelColor};
  }
  ${CheckboxLabel}:not(.ignore-in-dark-mode) {
    color: white;
  }
  ${StyledCheckbox}:not(.ignore-in-dark-mode) {
    border-color: ${({ theme }) => theme.colors.white.base};
    background-color: ${({ theme }) => theme.colors.transparent.base};
  }
  ${StyledCheckbox}.checkbox-checked:not(.ignore-in-dark-mode) {
    color: white;
    border-color: ${({ theme }) => theme.colors.transparent.base};
    background-color: ${({ theme }) => theme.colors.white.base};
    svg {
      stroke: ${({ theme }) => theme.colors.primary.dark};
    }
  }

  .override.ignore-in-dark-mode {
    color: ${({ theme }) => theme.colors.gray.darkest};
  }

  ${StyledCheckbox}.checkbox-hidden {
    display: none;
  }

  .${SELECT_DEFAULTS.selectContainerClassName} {
    background-color: transparent;
  }
  .${SELECT_DEFAULTS.controlClassName} {
    ${DarkModeInputBackgroundColor};
    ${DarkModeInputBorderColor};
  }
  .${SELECT_DEFAULTS.singleValueClassName} {
    color: inherit;
  }
  .${SELECT_DEFAULTS.inputClassName} {
    color: ${({ theme }) => theme.colors.white.base};
  }
  div[${SELECT_DEFAULTS.dropdownNameAttribute}] {
    svg {
      fill: ${({ theme }) => theme.colors.white.base};
    }
  }
`;

interface IFullInputCustomStyles {
  input?: { [key: string]: any };
  label?: { [key: string]: any };
}
interface IFieldWrapperProps extends IDarkModeBlockProps {
  withIcon?: boolean;
  iconPosition?: IconPositionType;
  customStyles?: IFullInputCustomStyles;
}
const FieldWrapper = styled(Block).attrs({})<IFieldWrapperProps>`
  position: relative;
  ${Input} {
    ${InputIconPaddingLeft};

    padding-left: ${({ customStyles }) =>
      customStyles &&
      customStyles.input &&
      unit(customStyles.input.padding.left)};

    ${({ darkMode }) => darkMode && DarkModeInputBackgroundColor};

    & + ${Label} {
      ${InputLabelLeft}
      left: ${({ customStyles }) =>
        customStyles && customStyles.label && unit(customStyles.label.left)};
    }

    &:focus + ${Label} {
      ${LabelActive}
    }
  }

  textarea {
    padding-top: ${({ fontSize, theme }) =>
      unit(theme.element.padding[getElementSize(fontSize)] * 2)};
  }
`;

type ReactInputProps = Pick<
  React.InputHTMLAttributes<HTMLInputElement>,
  Exclude<keyof React.InputHTMLAttributes<HTMLInputElement>, "color">
>;

interface IInputFieldProps extends IInputProps, ReactInputProps {
  highlightValid?: boolean;
  isValid?: boolean;
  errorMessage?: any;
  darkMode?: boolean;
  wrapperProps?: IDarkModeBlockProps;
  interactionEl?: React.ReactComponentElement<any>;
  interactionElPosition?: IconPositionType;
  customStyles?: IFullInputCustomStyles;
}

interface IInputFieldWithIconProps extends IInputFieldProps {
  icon?: React.ReactComponentElement<typeof Icon>;
  iconPosition?: IconPositionType;
}

interface IInputFieldWithButtonProps extends IInputFieldProps {
  button?: React.ReactComponentElement<any>;
  buttonPosition?: IconPositionType;
}

const FullInputBase: FunctionComponent<IInputFieldProps> = ({
  value,
  title,
  highlightValid,
  disabled,
  isValid,
  errorMessage,
  darkMode,
  wrapperProps,
  form,
  interactionEl,
  interactionElPosition: _iconPosition,
  fontSize,
  customStyles,
  ...props
}) => {
  const ref = React.createRef<HTMLInputElement>();
  const focusInput = () => ref.current && ref.current.focus();
  const interactionElPosition = _iconPosition || "left";
  if (errorMessage) props.inputStyleType = "alert";
  if (isValid && highlightValid) props.inputStyleType = "success";

  return (
    <FieldWrapper
      transition
      withIcon={!!interactionEl}
      iconPosition={interactionElPosition}
      onClick={focusInput}
      alignSelf="flex-start"
      margin={{ bottom: errorMessage ? 15 : 0 }}
      darkMode={darkMode}
      customStyles={customStyles}
      {...wrapperProps}
    >
      {interactionEl && (
        <InputIcon iconPosition={interactionElPosition}>
          {" "}
          {interactionEl}{" "}
        </InputIcon>
      )}

      <Input
        value={value || ""}
        ref={ref}
        elementWidth="block"
        fontSize={fontSize || "large"}
        disabled={disabled}
        {...props}
      />

      <Label htmlFor={props.name} className={value ? "active" : ""}>
        {title}
      </Label>

      <FieldError
        fontSize="smaller"
        color="white"
        bg="alert"
        errorMessage={errorMessage}
      >
        {errorMessage}
      </FieldError>
    </FieldWrapper>
  );
};

const FullInput: FunctionComponent<IInputFieldWithIconProps> = (
  props
): React.ReactComponentElement<any> => (
  <FullInputBase
    {...props}
    interactionEl={props.icon}
    interactionElPosition={props.iconPosition}
  />
);
const FullInputButton: FunctionComponent<IInputFieldWithButtonProps> = (
  props
): React.ReactComponentElement<any> => (
  <FullInputBase
    {...props}
    interactionEl={props.button}
    interactionElPosition={props.buttonPosition}
  />
);

export default Input;
export {
  Input,
  IInputProps,
  FieldError,
  FieldWrapper,
  FullInput,
  IInputFieldProps,
  IInputFieldWithIconProps,
  ReactInputProps,
  DarkModeWrapper,
  InputIcon,
  FullInputButton,
};
