import {
  css,
  styled,
  Block,
  Media,
  BorderRadiusBase,
  Button,
  unit,
  keyframes,
} from "../../../seenspire-library";
import { ReactComponent as ChannelPlaceholder } from "../../../assets/ChannelPlaceholder.svg";
import { ReactComponent as ChannelPlaceholderDraft } from "../../../assets/ChannelPlaceholderDraft.svg";
import {
  TRANSITION_DURATION,
  SCALE_AMOUNT,
  SIDE_PADDING,
  CUSTOM_BREAKPOINTS,
} from "./variables";
import { SliderItemStatusType } from "./interfaces";

export const SLIDER_ITEM_ACTIVE_SHADOW = "0px 0px 5px #00000030";

export const FadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
export const FadeInAnimationStyle = css`
  animation: ${FadeInAnimation} 400ms ease-in-out 1;
  animation-fill-mode: forwards;
`;
export const FadeOutAnimation = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;
export const FadeOutAnimationStyle = css`
  animation: ${FadeOutAnimation} 400ms ease-in-out 1;
  animation-fill-mode: forwards;
`;
export const SliderTransition = css`
  transition: all ${TRANSITION_DURATION}ms ease-in-out;
  /* Defines the easing of the transition,
  to modify use: http://cubic-bezier.com/#1,.16,.49,.43 */
  /* transition-timing-function: cubic-bezier(0.5,0,0.1,1);  */
`;

/* Controls the aspect ratio of the item */
export const AspectRatioBox = styled(Block)<{
  ratioType?: "1:1" | "16:9" | "4:3";
}>`
  width: 100%;
  height: 0;
  padding: ${({ ratioType }) => {
    switch (ratioType) {
      case "1:1":
        return "50% 0";
      case "16:9":
        return "28.125% 0";
      case "4:3":
        return "75% 0 0 0";
      default:
        return "28.125% 0";
    }
  }};
`;

export const ChannelPlaceholderStyled = styled(ChannelPlaceholder)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  g#ChannelPlaceholder {
    fill: #30415b;
  }
`;

export const ChannelPlaceholderDraftStyled = styled(ChannelPlaceholderDraft)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  g#ChannelPlaceholder {
    fill: #30415b;
  }
`;

const SliderItemContentTyped = styled(Block)<{
  isActive: boolean;
  isInView?: boolean;
  isPlaceholder?: boolean;
  isOpened?: boolean;
  gridMode?: boolean;
}>``;
export const SliderItemContent = styled(SliderItemContentTyped)`
  position: relative;
  z-index: 0;
  ${BorderRadiusBase};
  overflow: hidden;
`;

export const CONTENT_SPACING = 11;

export const ItemOnHoverContainerBase = styled(Block)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
`;

//`${unit(theme.borderRadius)} ${unit(theme.borderRadius)} 0 0`};
export const ItemOnHoverDescriptionContainer = styled(Block)<{
  isOpened?: boolean;
  isActive?: boolean;
}>`
  position: relative;
  z-index: 1000000;
  overflow: hidden;
  border-radius: ${({ theme, isOpened }) =>
    isOpened
      ? `${unit(theme.borderRadius)} ${unit(theme.borderRadius)} 0 0`
      : unit(theme.borderRadius)};
  box-shadow: ${({ theme, isActive }) =>
    isActive ? SLIDER_ITEM_ACTIVE_SHADOW : "0px 0px transparent"};
`;

export const ItemText = styled(Block)`
  text-shadow: ${({ theme }) => theme.shadows.lighter};
`;

export const DescriptionText = styled(Block)`
  font-size: ${({ theme }) => theme.fontSize.small};
`;

export const ItemOnHoverDescriptionContainerInner = styled(
  ItemOnHoverContainerBase
)`
  text-align: center;
  padding: 0 ${({ theme }) => unit(theme.spacing.base * 3)};
  z-index: 10;
  display: ${({ display }) => display || "flex"};
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: ${({ theme }) =>
      theme.mediaSizes
        .xlarge}px) and (max-width: ${CUSTOM_BREAKPOINTS.BETWEEN_LARGE_AND_XLARGE}px) {
    transform: scale(0.8);
    ${DescriptionText} {
      font-size: ${({ theme }) => theme.fontSize.smaller};
    }
  }
`;

export const SliderItemOnHoverContentInner = styled(Block)<{
  isActive: boolean;
}>`
  position: relative;
  z-index: 100000000;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: ${({ theme }) =>
    `0 0 ${unit(theme.borderRadius)} ${unit(theme.borderRadius)}`};
  box-shadow: ${({ isActive }) =>
    isActive ? SLIDER_ITEM_ACTIVE_SHADOW : "0px 0px transparent"};
  /* ${({ isActive }) =>
    isActive ? FadeInAnimationStyle : FadeOutAnimationStyle}; */
`;

export const SliderItemOnHoverContainer = styled(Block)`
  position: absolute;
  height: 100%;
  top: 0;
  left: 0px;
  right: 0px;
  display: grid;
  cursor: pointer;
`;
/* contains elements to show when on hover  */
export const SliderItemOnHoverContentTyped = styled(Block)<{
  isActive: boolean;
  isFirst: boolean;
  isLast: boolean;
  isOpened: boolean;
  skipInnerInteraction?: boolean;
}>``;
export const SliderItemOnHoverContent = styled(SliderItemOnHoverContentTyped)`
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  ${({ isActive }) => (isActive ? FadeInAnimationStyle : FadeOutAnimationStyle)}
  ${SliderTransition}
  transition-duration: 400ms;
  pointer-events: ${({ isActive }) => !isActive && "none"};
  transform: ${({ isOpened }) =>
    isOpened ? "translateY(-25%)" : "translateY(0)"};
  ${SliderItemOnHoverContentInner} {
    transform: ${({ isOpened, skipInnerInteraction }) =>
      isOpened || skipInnerInteraction ? `translateY(0)` : `translateY(-50%)`};
    opacity: ${({ isOpened, skipInnerInteraction }) =>
      isOpened || skipInnerInteraction ? 1 : 0};
    visibility: ${({ isOpened, skipInnerInteraction }) =>
      isOpened || skipInnerInteraction ? "visible" : "hidden"};
    /* ${({ isActive }) =>
      isActive ? FadeInAnimationStyle : FadeOutAnimationStyle}
    ${SliderTransition}
    transition-duration: 450ms;
    transition-delay: 100ms; */
  }
`;

export const CircleContainer = styled(Block).attrs({})<{
  circleSize?: "larger" | "large" | "medium" | "base" | "small";
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  min-width: 34px;
  min-height: 34px;
  max-width: 90px;
  max-height: 90px;
  transform: ${({ circleSize }) => {
    switch (circleSize) {
      case "larger":
        return "scale(3)";
      case "large":
        return "scale(1.5)";
      case "medium":
        return "scale(2.2)";
      default:
        return `scale(1)`;
    }
  }};
  background-color: ${({ theme, bg }) => bg || theme.colors.white.base};
`;
export const ItemOnHoverBackgroundContainer = styled(ItemOnHoverContainerBase)`
  z-index: 1;
  overflow: hidden;
`;

export const SliderItemButton = styled(Button)`
  position: relative;
  height: ${({ theme }) => unit(theme.element.height / SCALE_AMOUNT)};
  cursor: ${({ opacity }) => (opacity === 0.5 ? `default` : `pointer`)};
`;

export const SliderItemButtonIcon = styled(Block).attrs({})<{
  status?: SliderItemStatusType;
}>`
  z-index: 10;
  position: absolute;
  top: -1px;
  right: -1px;
  width: ${({ theme, status }) =>
    status === "DONE" ? "100%" : unit(theme.element.height / SCALE_AMOUNT)};
  height: calc(100% + 2px);
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const PaddedContainer = styled(Block)`
  z-index: ${({ zIndex }) => zIndex || 3};
  position: relative;
  padding: ${({ padding }) => !padding && `0 ${SIDE_PADDING.default}%`};
  ${Media.at.small`padding: 0 ${SIDE_PADDING.small}%`};
  touch-action: pan-y;
`;

export const UnlockButtonStyles = css`
  background-color: ${({ customBackgroundColor, theme }: any) =>
    customBackgroundColor && theme.layout.customColors.upgradeColor.base};
  border-color: ${({ customBackgroundColor, theme }: any) =>
    customBackgroundColor && theme.layout.customColors.upgradeColor.base};
  &:hover {
    background-color: ${({ customBackgroundColor, theme }: any) =>
      customBackgroundColor && theme.layout.customColors.upgradeColor.dark};
    border-color: ${({ customBackgroundColor, theme }: any) =>
      customBackgroundColor && theme.layout.customColors.upgradeColor.dark};
  }
  svg {
    fill: #060a12;
  }
`;
