import { styled, unit, Block, Media, css } from "../../../seenspire-library";
import {
  SIDE_PADDING,
  LAYOUT_MAX_WIDTH,
  SLIDER_ITEMS_SPACING,
} from "../../containers/Dashboard/variables";

const SidePadding = css`
  padding-left: calc(${SIDE_PADDING.default}% + ${SLIDER_ITEMS_SPACING}px);
  padding-right: calc(${SIDE_PADDING.default}% + ${SLIDER_ITEMS_SPACING}px);
  ${Media.at.small`
  padding-left: calc(${SIDE_PADDING.small}% + ${SLIDER_ITEMS_SPACING}px); 
  padding-right: calc(${SIDE_PADDING.small}% + ${SLIDER_ITEMS_SPACING}px);
  `};
`;

const Layout = styled(Block)`
  display: grid;
  position: relative;
  z-index: ${({ theme }) => theme.header.zIndex - 1};
  grid-gap: ${({ theme }) => unit(theme.layout.gap * 2)};
  padding-bottom: 25px;
  max-width: ${unit(LAYOUT_MAX_WIDTH)};
  margin-right: auto;
  margin-left: auto;
`;

export { Layout, SidePadding };
