import React, { FunctionComponent, useContext } from "react";
import { AppContext } from "../../state";
import { styled, Block } from "../../../seenspire-library";
import { ReactComponent as PoweredByImage } from "../../../assets/powered-by-seenspire.svg";

const PoweredByContainer = styled(Block)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: ${({ theme }) => theme.colors.background.base};
`;

export const PoweredBy: FunctionComponent = () => {
  const Context = useContext(AppContext);
  const { authenticated } = Context;

  return (
    <>
      {authenticated && (
        <PoweredByContainer>
          <PoweredByImage />
        </PoweredByContainer>
      )}
    </>
  );
};

export default PoweredBy;
