import React, {
  FunctionComponent,
  useState,
  useRef,
  useEffect,
  CSSProperties,
} from "react";
import {
  styled,
  Block,
  CtaButton,
  PanelHeader,
} from "../../../seenspire-library";
import { Container, IContainer } from "../Container/Container";
import { useTranslation } from "react-i18next";
import { AspectRatioBox } from "../../containers/Dashboard/Styles";
import { FormikProps } from "formik";

const Video = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;
  border: 0;
`;

export interface IPreviewConfig {
  id: string;
  accountId?: string;
  preview?: boolean;
  sourceId?: string;
  regionId?: string;
  languageId?: string;
  presentationDesignSlug?: string;
}
interface IPreviewProps extends IContainer {
  config: IPreviewConfig;
  data?: any;
  form?: FormikProps<any>;
  onSubmit: (() => void) | null;
  onCancel: () => void;
  skipInnerWrapper?: boolean;
}

const FauxPreview: FunctionComponent<
  IPreviewProps & { style?: Partial<CSSProperties> }
> = ({ onSubmit, onCancel, config, skipInnerWrapper, ...props }) => {
  const video = (
    <Video
      autoPlay
      src={`https://storage.googleapis.com/static-seenspire/assets/portals/thumbs/infotainment/Public_Transport.mp4`}
    ></Video>
  );
  return (
    <Container skipContentPadding withHeader={!!onSubmit} {...props}>
      {skipInnerWrapper ? (
        video
      ) : (
        <Block position="relative">
          {video}
          <AspectRatioBox />
        </Block>
      )}
    </Container>
  );
};

export default FauxPreview;
