import { generateClient } from "seenspire-api-client";

import { api, openApi } from "./api";

const loadClient = () => {
  return generateClient({
    basePath: process.env.REACT_APP_API,
    extendedBasePath:
      "https://us-central1-seenspire-platform-testing.cloudfunctions.net",
    api,
    openApi,
  });
};

export default { loadClient };
