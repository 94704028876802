import { ITheme, ColorType, ColorShadeType } from "./interface";

const unit = (propName: number) => propName + "px";
const scaleFontSize = (fontSize: string, scaleAmount: number) =>
  `${(fontSize.replace("rem", "") as any) / scaleAmount}rem`;

const hsla = (hsl: string, alpha: number) => {
  const asNumber = Number(alpha);
  const _alpha = asNumber && asNumber > 1 ? 1 : asNumber;
  return hsl.replace("hsl", "hsla").replace(")", `, ${_alpha})`);
};

const renderShadow = (shadowColor: string, colorToUse?: string) => {
  return colorToUse
    ? shadowColor.split("hsl")[0].concat(colorToUse)
    : shadowColor;
};

const renderColor = (
  theme: ITheme,
  color?: ColorType,
  shade?: ColorShadeType
) => {
  if (color) {
    return theme.colors[color || "primary"][shade || "base"];
  }
  return null;
};

function hexToHSL(hex: string, lightnessAmount: number = 0) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (!result) return "hsl(0, 0%, 50%)";

  let r = parseInt(result[1], 16);
  let g = parseInt(result[2], 16);
  let b = parseInt(result[3], 16);
  (r /= 255), (g /= 255), (b /= 255);
  const max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h: any;
  let s: any;
  let l = (max + min) / 2;
  if (max == min) {
    h = s = 0; // achromatic
  } else {
    let d = max - min;

    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  s = s * 100;
  s = Math.round(s);
  l = l * 100;
  l = Math.round(l) + lightnessAmount;
  h = Math.round(360 * h);

  return `hsl(${h}, ${s}%, ${l > 100 ? 100 : l < 0 ? 0 : l}%)`;
}

export { unit, scaleFontSize, hsla, renderColor, renderShadow, hexToHSL };
