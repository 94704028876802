import React, { FunctionComponent, useContext } from "react";
import {
  Block,
  FieldError,
  styled,
  BorderRadiusBase,
} from "../../../seenspire-library";
import { UploadButton } from "../UploadButton/UploadButton";
import { AppContext } from "../../state";

const ImagePreviewContainer = styled(Block).attrs({})<{
  imageFieldBackground: string;
  fieldSize?: "large" | "small";
}>`
  position: relative;
  overflow: hidden;
  background-color: ${({ imageFieldBackground, fieldSize }) =>
    fieldSize === "small"
      ? imageFieldBackground || "#ffffff0d"
      : imageFieldBackground || "white"};
  height: ${({ fieldSize }) => (fieldSize === "small" ? "128px" : "180px")};
  border: 1px solid
    ${({ theme, fieldSize }) =>
      fieldSize === "small" ? "#ffffff0d" : theme.colors.gray.lighter};
  ${BorderRadiusBase};
  img {
    max-height: 45px;
    max-width: 150px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
`;

const ImagePreviewLabel = styled(Block)`
  position: absolute;
  top: 10px;
  left: 16px;
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.thin};
  color: ${({ theme }) => theme.colors.gray.base};
`;

const ImageField: FunctionComponent<any> = ({
  field,
  form,
  imageFieldBackground,
  imageFieldLabel,
  fieldSize,
  previewHide,
  resetValue,
  isMulti,

  ..._props
}) => {
  const { theme } = useContext(AppContext);
  const props = {
    ..._props,
    ...field,
    isValid: !!(!form.errors[field.name] && form.touched[field.name]),
    errorMessage: form.errors[field.name],
  };

  const uploadButton = (
    <UploadButton
      isMulti={isMulti}
      darkMode={fieldSize === "small"}
      onDone={(file) => form.setFieldValue(field.name, isMulti ? file : file.base64)}
      onClear={() => form.setFieldValue(field.name, resetValue || "")}
      value={form.values[field.name]}
      {...props}
    />
  );

  const preview = previewHide ? null : (
    isMulti ? (
      <>
      {form.values[field.name]?.map((file: any) => (
        <ImagePreviewContainer
          key={`${field.name}-${file.name}`}
          imageFieldBackground={imageFieldBackground}
          fieldSize={fieldSize}
        >
          {field.name && form.values[field.name] && (
            <img alt="name" src={file.base64} />
          )}
          {imageFieldLabel && (
            <ImagePreviewLabel>{imageFieldLabel}</ImagePreviewLabel>
          )}
        </ImagePreviewContainer>
      ))}
      </>
    ) : (
      <ImagePreviewContainer
        imageFieldBackground={imageFieldBackground}
        fieldSize={fieldSize}
      >
        {field.name && form.values[field.name] && (
          <img alt="name" src={form.values[field.name]} />
        )}
        {imageFieldLabel && (
          <ImagePreviewLabel>{imageFieldLabel}</ImagePreviewLabel>
        )}
      </ImagePreviewContainer>
    )
  );

  return (
    <Block position="relative" display="grid" gridGap={theme.layout.gap}>
      {fieldSize === "small" ? (
        <>
          {preview}
          {uploadButton}
        </>
      ) : (
        <>
          {uploadButton}
          {preview}
        </>
      )}
      <FieldError
        fontSize="smaller"
        color="white"
        bg="alert"
        errorMessage={props.errorMessage}
      >
        {props.errorMessage}
      </FieldError>
    </Block>
  );
};
export default ImageField;
