import React, {
  useState,
  FunctionComponent,
  useContext,
  useEffect,
} from "react";
import {
  Block,
  Modal,
  Select,
  PanelHeader,
  IModalProps,
  Icon,
  Button,
  ModalTitle,
  ModalSubTitle,
  ModalDescription,
  ModalIconContainer,
  ModalTitleContainer,
  ModalCtaContainer,
  IconName,
} from "../../../seenspire-library";
import PanelForm from "../../components/PanelForm/PanelForm";
import { Formik, FormikHelpers, Field } from "formik";
import * as Yup from "yup";
import {
  Invite,
  ActiveChannelType,
  Subscription,
  Plan,
  UpgradeRequest,
  UpgradeRequest5,
  Plan5,
} from "seenspire-api-client";
import {
  formatServerValidationErrors,
  FeedTypeEnum,
  FeedType,
} from "../../util";
import InputField from "../../components/InputField/InputField";
import { AppContext } from "../../state";
import { useTranslation } from "react-i18next";
import {
  ModalButtons,
  FormModalButtons,
} from "../../components/ModalButtons/ModalButtons";
import { ISocialNetworksTypesConfig } from "../../containers/AccountSocialNetworks/AccountSocialNetworks";
import { chain, find, every } from "lodash";
import { AuthRoutNames } from "../../routes/AuthRoutes";
import { FeaturesConfig } from "../../containers/Dashboard/MethodsAndLogic";
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator";
import ConfirmationModal from "../ConfirmationModal";
import { orderBy } from "lodash";

const DeleteFeedModal: FunctionComponent = () => {
  const context = useContext(AppContext);
  const { client, setMyStreams, setLoadedStream } = useContext(AppContext);
  const { t } = useTranslation();
  return (
    <ConfirmationModal
      isOpen={!!context.deleteChannel}
      onConfirm={async () => {
        if (!context.deleteChannel) return;
        context.setMyChannels(
          orderBy(
            context.myChannels
              ? context.myChannels.filter(
                  (_channel) =>
                    context.deleteChannel &&
                    _channel.id !== context.deleteChannel.id
                )
              : [],
            "id",
            "desc"
          )
        );
        context.setDeleteChannel(undefined);
        await context.client.ActiveChannels.deleteActiveChannel(
          String(context.deleteChannel.id)
        );

        const myStreams = (await client.Stream.getStreams(
          undefined,
          undefined,
          "id",
          undefined,
          "DESC"
        )) as any;
        setMyStreams(myStreams.data);

        let lStream = myStreams.data[0];
        if (localStorage.getItem("loadedStream")) {
          myStreams.data.forEach((stream: any) => {
            if (stream.id == localStorage.getItem("loadedStream")) {
              lStream = stream;
            }
          });
        }

        setLoadedStream(lStream);

        return Promise.resolve();
      }}
      icon="deleteSign"
      title=" "
      confirmLabel={t("feeds.delete_feed_button_delete_feed")}
      subTitle={t("feeds.delete_feed_title")}
      description={t("feeds.delete_feed_description")}
      onCancel={() => context.setDeleteChannel(undefined)}
    />
  );
};

export { DeleteFeedModal };
