import { AccountUserRoleMeAccount, AccountTypeEnum } from "../interfaces";
import { AccountUserRoleMe, User10 } from "seenspire-api-client";
import { find, get } from "lodash";

export const isMaster = (account: AccountUserRoleMeAccount) =>
  account.type === AccountTypeEnum.master;
export const isDistributor = (account: AccountUserRoleMeAccount) =>
  account.type === AccountTypeEnum.distributor;
export const isReseller = (account: AccountUserRoleMeAccount) =>
  account.type === AccountTypeEnum.reseller;
export const isReferral = (account: AccountUserRoleMeAccount) =>
  account.type === AccountTypeEnum.reseller && account.referral;
export const isClient = (account: AccountUserRoleMeAccount) =>
  account.type === AccountTypeEnum.client;
export const isPartner = (partnerSettings: any) =>
  ![AccountTypeEnum.client, AccountTypeEnum.contentPartner].includes(
    partnerSettings.type as any
  );
export const getCurrentRole = (user: any, account: AccountUserRoleMe) => {
  const userAccount = find(user.account_users, {
    account: { id: account.account.id },
  }) as any;
  return userAccount && userAccount.roles ? userAccount.roles[0] : {};
};
export const getCurrentRoles = (user: any, account: AccountUserRoleMe) => {
  const userAccount = find(user.account_users, {
    account: { id: account.account.id },
  }) as any;
  return userAccount && userAccount.roles ? userAccount.roles : [];
};

export const fullName = (user: any = {}) =>
  `${get(user, "first_name") || ""} ${get(user, "last_name") || ""}`;
