import React, {
  useState,
  FunctionComponent,
  useContext,
  useEffect,
} from "react";
import {
  Block,
  Modal,
  IModalProps,
  Icon,
  Button,
  ModalCtaContainer,
  styled,
  Checkbox,
} from "../../../seenspire-library";
import { AppContext } from "../../state";
import { TermsAndConditionsText } from "../../containers/Auth/Register/Register";

const StyledHeader = styled(Block)`
  text-align: center;
  background-color: ${({ theme }) => theme.colors.primary.dark};
  min-height: 120px;
  display: grid;
  align-items: center;
  align-content: center;
  grid-gap: 10px;
`;
const StyledHeaderBG = styled(Block)`
  background-color: ${({ theme }) => theme.colors.primary.dark};
`;

const StyledDescription = styled.ul`
  margin: 0;
  li {
    margin-bottom: 10px;
  }
  li:last-child {
    margin-bottom: 0;
  }
`;

const AcceptTermsModal: FunctionComponent<IModalProps> = ({
  isOpen: ignored,
  ...props
}) => {
  const { client, theme, account, acceptTermsModalIsOpen } =
    useContext(AppContext);
  const [accepted, setAccepted] = useState(false);
  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    setAccepted(false);
  }, [acceptTermsModalIsOpen]);

  const submitData = async () => {
    setSubmitting(true);
    try {
      await client.Accounts.patchAcceptTermsAction(
        String(account.account.id),
        "true"
      );
      window.location.reload();
      setSubmitting(false);
    } catch (_error) {
      setSubmitting(false);
    }
  };

  return (
    <Modal
      isOpen={acceptTermsModalIsOpen}
      theme={theme}
      skipContentPadding={true}
      customStyle={{
        content: {
          background: `linear-gradient(180deg, ${theme.colors.primary.dark} 0%, ${theme.colors.primary.dark} 6%, white 7%, white 100%)`,
        },
      }}
      {...props}
    >
      <Block>
        <StyledHeader>
          <Block style={{ fontSize: "24px" }} color="white" fontWeight="bold">
            Welcome to the New Seenspire!
          </Block>
          <Block color="white">
            The first all-in-one content streaming service for employee
            communications
          </Block>
        </StyledHeader>
        <StyledHeaderBG />
        <Block bg="background">
          <Block display="grid" gridGap={10} padding={{ all: 24 }}>
            <Block fontWeight="bold">What's new?</Block>
            <StyledDescription>
              <li>
                A unified user experience to put all Social, Infotainment and
                our latest Teams content at your fingertips, in one place.
              </li>
              <li>
                Availability of an entirely new content type: Teams Content.
                Valuable and inspiring employee-generated content sourced from
                Workplace, Microsoft Teams, Viva Engage,{/** Zoom,*/} Webex Teams and
                visualized by Seenspire on your screens.
              </li>
              <li>Set your design and branding defaults for all your feeds.</li>
              <li>
                One-time authentication to connect your social media or teams
                accounts for all feeds you create
              </li>
              <li>Enterprise ready and ISO 27001 certified</li>
            </StyledDescription>
          </Block>
        </Block>

        <Block display="grid" gridGap={20} padding={{ all: 24 }}>
          <Block>
            Before you get started please read and agree to our standard terms
            and conditions by checking the below box.
          </Block>

          <Block>
            <Checkbox
              checked={accepted}
              fontWeight="normal"
              title={(<TermsAndConditionsText />) as any}
              onChange={(event) => {
                setAccepted(event.target.checked);
              }}
            />
          </Block>

          <ModalCtaContainer>
            <Button
              elementWidth="block"
              disablePointer={submitting || !accepted}
              opacity={submitting || !accepted ? 0.4 : 1}
              disabled={submitting || !accepted}
              onClick={() => submitData()}
              buttonType={submitting ? "accent" : "cta"}
            >
              {submitting ? (
                <Icon name="loading" strokeColor="white" />
              ) : (
                `Get started`
              )}
            </Button>
          </ModalCtaContainer>
        </Block>
      </Block>
    </Modal>
  );
};

export { AcceptTermsModal };
