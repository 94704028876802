import * as React from "react";
import { FunctionComponent } from "react";
import { styled } from "../../styled-components";
import { Block } from "../Block/Block";
import { Transition, TransitionBase } from "../styles";
import { Icon, IconName, IIconProps } from "../Icon/Icon";
import { FieldError } from "../Input/shared";
interface ICheckboxProps {
  checked?: boolean | undefined;
  hiddenCheckbox?: boolean | undefined;
  errorMessage?: any;
  title?: any;
  description?: any;
  fontWeight?: any;
  marginRight?: string;
}
const CheckboxContainer = styled.label<{ hasError?: boolean }>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin-bottom: ${({ hasError }) => (hasError ? "20px" : 0)};
  ${TransitionBase};
`;

const _Icon = styled.svg`
  fill: none !important;
  stroke: white;
  stroke-width: 4px;
`;
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })<any>`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled(Block).attrs({})<ICheckboxProps>`
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: ${({ checked, theme }) => checked && theme.colors.cta.base};
  border-radius: 3px;
  border: solid 1px ${({ theme }) => theme.colors.gray.base};
  border-color: ${({ checked, theme }) => checked && theme.colors.cta.base};
  margin-right: ${({marginRight}) => marginRight || "10px"};
  ${Transition};
  ${HiddenCheckbox}:focus + & {
    /* box-shadow: 0 0 0 3px pink; */
  }

  ${_Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;
const CheckboxLabel = styled(Block)`
  color: ${({ theme }) => theme.colors.gray.darkest};
`;

const Checkbox: FunctionComponent<
  React.InputHTMLAttributes<HTMLInputElement> & ICheckboxProps
> = ({ checked, hiddenCheckbox, marginRight, ...props }) => {
  return (
    <CheckboxContainer hasError={!!props.errorMessage}>
      <Block display="flex" alignItems="center">
        <HiddenCheckbox checked={checked} {...props} />
        <StyledCheckbox
          className={`${props.className} ${checked ? "checkbox-checked" : ""} ${
            hiddenCheckbox ? "checkbox-hidden" : ""
          }`}
          checked={checked}
          marginRight={marginRight}
        >
          <_Icon viewBox="0 0 24 24">
            <polyline points="20 6 9 17 4 12" />
          </_Icon>
        </StyledCheckbox>
        <CheckboxLabel display="grid" gridGap={5}>
          <Block fontWeight={props.fontWeight || "bold"}>{props.title}</Block>
          {props.description && (
            <Block fontSize="smaller" opacity={0.75}>
              {props.description}
            </Block>
          )}
        </CheckboxLabel>
      </Block>
      <FieldError
        fontSize="smaller"
        color="white"
        bg="alert"
        withoutField={true}
        errorMessage={props.errorMessage}
      >
        {props.errorMessage}
      </FieldError>
    </CheckboxContainer>
  );
};

const CheckBoxIconLabel: FunctionComponent<
  IIconProps & {
    label: string;
    iconName?: IconName;
    active: boolean;
    className?: string;
    verified?: boolean;
  }
> = ({ iconName, label, active, className, verified, ...props }) => (
  <CheckboxLabel
    display="grid"
    direction="column"
    justifyContent="start"
    alignItems="center"
    gridGap={0}
    className={className}
  >
    <Icon
      opacity={active ? 0.9 : 0.25}
      name={iconName}
      transition
      {...props}
      padding={{ right: 5 }}
    />
    {label}
    {verified && (
      <Icon
        opacity={active ? 0.9 : 0.25}
        name={"verifyBadge"}
        transition
        {...props}
      />
    )}
  </CheckboxLabel>
);

export default Checkbox;
export { Checkbox, StyledCheckbox, CheckBoxIconLabel, CheckboxLabel };
