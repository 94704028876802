import React, {
  useState,
  FunctionComponent,
  useContext,
  useEffect,
} from "react";
import {
  Block,
  Modal,
  Select,
  PanelHeader,
  IModalProps,
  Icon,
  Button,
  ModalTitle,
  ModalSubTitle,
  ModalDescription,
  ModalIconContainer,
  ModalTitleContainer,
  ModalCtaContainer,
  IconName,
} from "../../../seenspire-library";
import PanelForm from "../../components/PanelForm/PanelForm";
import { Formik, FormikHelpers, Field } from "formik";
import * as Yup from "yup";
import {
  Invite,
  ActiveChannelType,
  Subscription,
  Plan,
  UpgradeRequest,
  UpgradeRequest5,
  Plan5,
} from "seenspire-api-client";
import {
  formatServerValidationErrors,
  FeedTypeEnum,
  FeedType,
} from "../../util";
import InputField from "../../components/InputField/InputField";
import { AppContext } from "../../state";
import { useTranslation } from "react-i18next";
import {
  ModalButtons,
  FormModalButtons,
} from "../../components/ModalButtons/ModalButtons";
import { ISocialNetworksTypesConfig } from "../../containers/AccountSocialNetworks/AccountSocialNetworks";
import { chain, find, every } from "lodash";
import { AuthRoutNames } from "../../routes/AuthRoutes";
import { FeaturesConfig } from "../../containers/Dashboard/MethodsAndLogic";
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator";

const SwitchAccountModal: FunctionComponent<IModalProps> = ({
  isOpen: ignored,
  onSubmit,
  ...props
}) => {
  const {
    theme,
    switchAccountModalIsOpen,
    setSwitchAccountModalIsOpen,
    _switchAccount,
  } = useContext(AppContext);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(false);
  }, [switchAccountModalIsOpen]);

  const excSwitchAccount = async () => {
    if (!switchAccountModalIsOpen) return;
    setLoading(true);
    try {
      await _switchAccount(
        switchAccountModalIsOpen.account,
        switchAccountModalIsOpen.routeName
      );
      setSwitchAccountModalIsOpen(undefined);
    } catch (error) {
      setLoading(false);
      setSwitchAccountModalIsOpen(undefined);
    }
  };
  return (
    <Modal
      isOpen={!!switchAccountModalIsOpen}
      onRequestClose={() => setSwitchAccountModalIsOpen(undefined)}
      theme={theme}
      {...props}
    >
      <Block>
        <ModalIconContainer>
          <Icon iconSize="larger" name="remoteLogin" />
        </ModalIconContainer>

        <ModalTitleContainer>
          <ModalSubTitle> </ModalSubTitle>
          <ModalTitle>Remote login</ModalTitle>
        </ModalTitleContainer>

        <ModalDescription>
          <Block>
            You are about to remote login to{" "}
            {switchAccountModalIsOpen &&
              switchAccountModalIsOpen.account.organization_info.name}
            ’s account
          </Block>
          <Block padding={{ top: 15 }}>
            Any changes you make in their account will be saved and potentially
            have an impact on their screens.
          </Block>
        </ModalDescription>
      </Block>

      <ModalCtaContainer>
        <Button
          elementWidth="block"
          disablePointer={loading}
          onClick={() => setSwitchAccountModalIsOpen(undefined)}
          buttonType="outline"
        >
          {t("Cancel")}
        </Button>
        <Button
          elementWidth="block"
          disablePointer={loading}
          onClick={excSwitchAccount}
          buttonType="cta"
        >
          {loading ? <Icon name="loading" strokeColor="white" /> : "Continue"}
        </Button>
      </ModalCtaContainer>
    </Modal>
  );
};

export { SwitchAccountModal };
