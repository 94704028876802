import React, { FunctionComponent, useEffect, useState } from "react";
import { styled, Button, Block, Input } from "../../../seenspire-library";
import { truncate } from "lodash";
import ConfirmationModal from "../../modals/ConfirmationModal";
import { useTranslation, Trans } from "react-i18next";

const UploadButtonStyled = styled(Button)`
  background-color: #26354c;
  color: #ffffff;
  width: 100px;
  height: 40px;
  white-space: nowrap;
  text-transform: none;
  :hover {
    background-color: #3c495e;
    color: #ffffff;
  }
  border-color: #3c495e;
  border-radius: 4px;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
`;
const UploadButtonInput = styled(Input)`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  background-color: #fafafa;
  font-family: "Open Sans", sans-serif;

  padding: 0;

  font-family: "Open Sans", sans-serif;
  z-index: 100;
`;

const UploadButtonWrapper = styled(Block)`
  position: relative;
  margin-right: 5px;
`;

const FileInfoWrapper = styled(Block)`
  position: relative;
  padding-top: 15px;
`;

const UploadButton: FunctionComponent<{
  onDone: (data: any) => void;
  onClear: () => void;
  label?: string;
  darkMode?: boolean;
  isMulti?: boolean;
  value?: any;
}> = (props) => {
  const { isMulti=false, value } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [fileInfos, setFileInfos] = useState<any[]>([]);
  const { t } = useTranslation();

  const truncatedLabel = (fileInfo: any) => truncate(
    (fileInfo.name || "").split("").reverse().join(""),
    {
      length: 20,
    }
  )
    .split("")
    .reverse()
    .join("");

  async function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.files || !e.target.files[0]) return;
    const file = e.target.files[0];
    if (!file) return;
    const files = e.target.files;
    let isExceedSize = false;
    for (let i = 0; i < files.length; i++) {
      isExceedSize = files[i].size > 1000 * 1000; // 1M
      if (isExceedSize) break;
    }
    if (isExceedSize) {
      setIsOpen(true);
      return;
    }
    const _fileInfos: any[] = [];
    let promises = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const filePromise = new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const _fileInfo = {
            name: file.name,
            type: file.type,
            size: Math.round(file.size / 1000) + " kB",
            base64: reader.result,
            file: file,
          };
          _fileInfos.push(_fileInfo);
          resolve(_fileInfo);
        }
      })
      promises.push(filePromise);
    }
    const res = await Promise.all(promises);
    
    if (res) {
      setFileInfos(_fileInfos);
      props.onDone(isMulti ? _fileInfos : _fileInfos[0]);
    }
  };

  const handleDeleteFile = (fileName: string) => {
    if (fileInfos.length === 1) {
      props.onClear();
      setFileInfos([]);
      return;
    }
    setFileInfos((prev) => {
      const newValue = prev.filter((f: any) => f.name !== fileName);
      props.onDone(isMulti ? newValue : newValue[0]);
      return newValue;
    })
  }

  useEffect(() => {
    if(!value || (value && value.length === 0)) {
      setFileInfos([]);
    }
  }, [value]);

  return (
    <>
      <Block display="flex" direction={isMulti ? "column" : "row"}>
        <UploadButtonWrapper>
          <UploadButtonStyled
            buttonType="outline"
            elementWidth="free"
            elementHeightBase={false}
          >
            {props.label || "Browse File"}
          </UploadButtonStyled>
          <UploadButtonInput
            type="file"
            multiple={isMulti}
            onChange={handleChange}
            accept=".jpg,.jpeg,.svg,.png"
          />
        </UploadButtonWrapper>
        <FileInfoWrapper>
          {fileInfos.map((fileInfo) => (
            <Block key={fileInfo.name} display="grid" gridGap={10} direction="column">
              <Block fontSize="smaller" title={fileInfo.name} alignSelf="center">
                {truncatedLabel(fileInfo)}
              </Block>
              <Block
                color={"alert"}
                cursor="pointer"
                shade="darkest"
                textDecoration="underline"
                textAlign="right"
                alignSelf="center"
                onClick={() => handleDeleteFile(fileInfo.name)}
              >
                (Delete)
              </Block>
            </Block>
          ))}
        </FileInfoWrapper>
      </Block>
      {isOpen && (
        <ConfirmationModal
          isOpen={isOpen}
          notificationMode={true}
          onConfirm={() => Promise.resolve(setIsOpen(false))}
          title={" "}
          confirmLabel={t("components.image_too_large_button")}
          subTitle={t("components.image_too_large_title")}
          description={
            <Trans i18nKey="components.image_too_large_description" />
          }
        />
      )}
    </>
  );
};

export { UploadButton };
