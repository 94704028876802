import React, { FunctionComponent, useState } from "react";
import { Form, FormikProps } from "formik";
import {
  IBlockProps,
  IResponsive,
  Panel,
  PanelHeader,
  Block,
  Button,
  IPanelProps,
  IPanelHeaderProps,
  Icon,
} from "../../../seenspire-library";
import { useMedia } from "../../util";
import { useTranslation } from "react-i18next";

const FORM_DEFAULTS = {
  gap: 15,
  gridColumnTemplate: {
    large: "repeat(auto-fill, minmax(300px, 1fr))",
    xlarge: "repeat(auto-fill, minmax(300px, 1fr))",
  },
};

export interface IPanelFormProps
  extends IBlockProps,
    IPanelProps,
    IPanelHeaderProps {
  title?: string;
  form?: FormikProps<any>;
  active?: boolean;
  disable?: boolean;
  hideHeader?: boolean;
  innerContainerProps?: IBlockProps;
}

const PanelFormHeader: FunctionComponent<IPanelFormProps> = ({
  form,
  active,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <PanelHeader {...props}>
      {form && (
        <Block
          display="grid"
          direction="column"
          gridGap={FORM_DEFAULTS.gap}
          opacity={active || form.isSubmitting ? 1 : 0}
          disablePointer={form.isSubmitting}
          transition
        >
          <Button
            onClick={() => form.resetForm()}
            type="button"
            fontSize="smaller"
            elementWidth="free"
            buttonType="transparent"
            elementHeightBase={false}
          >
            {t("Cancel")}
          </Button>

          <Button
            icon={form.isSubmitting ? "empty" : "save"}
            type="submit"
            fontSize="smaller"
            elementWidth="free"
            buttonType={form.isSubmitting ? "accent" : "cta"}
            elementHeightBase={false}
          >
            {form.isSubmitting ? (
              <Icon name="loading" strokeColor="white" />
            ) : (
              t("SAVE")
            )}
          </Button>
        </Block>
      )}
    </PanelHeader>
  );
};

const PanelForm: FunctionComponent<IPanelFormProps> = ({
  children,
  form,
  title,
  description,
  disable,
  hideHeader,
  innerContainerProps,
  gridTemplate,
  ...parentProps
}) => {
  const media = useMedia();
  const [active, setActive] = useState(false);
  const combinedGridTemplate =
    !media.isSmall && !media.isMedium
      ? {
          columns: { ...FORM_DEFAULTS.gridColumnTemplate },
          ...(gridTemplate || {}),
        }
      : undefined;
  return (
    <Form>
      <Panel
        display="grid"
        gridGap={FORM_DEFAULTS.gap}
        onMouseOver={() => setActive(true)}
        onMouseLeave={() => setActive(false)}
        {...parentProps}
      >
        {!hideHeader && (
          <PanelFormHeader
            form={form}
            title={title}
            description={description}
            headerSize="small"
            active={form && form.dirty}
          />
        )}

        <Block
          display="grid"
          opacity={disable || (form && form.isSubmitting) ? 0.4 : 1}
          disablePointer={disable || (form && form.isSubmitting)}
          gridGap={FORM_DEFAULTS.gap}
          gridTemplate={combinedGridTemplate}
          {...innerContainerProps}
        >
          {/* Form fields goes here */}
          {children}
        </Block>
      </Panel>
    </Form>
  );
};
export { FORM_DEFAULTS, PanelFormHeader };
export default PanelForm;
