import React, { FunctionComponent } from "react";
import { FieldProps } from "formik";
import {
  IInputFieldProps,
  FullInput,
  Checkbox,
  Select,
} from "../../../seenspire-library";
import { ColorInput } from "../ColorInput/ColorInput";
import { get } from "lodash";

// @TODO Improve typing
interface IInputFieldExtraProps {
  options: any;
  styles: any;
  isValuePrimitive?: boolean;
  withDescription?: boolean;
  menuPlacement?: "auto" | "bottom" | "top" | undefined;
  customOnChange?: (value: any) => void;
}

const InputField: FunctionComponent<
  IInputFieldProps & FieldProps<any> & IInputFieldExtraProps
> = ({ field, form, ..._props }) => {
  const props = {
    ..._props,
    ...field,
    isValid: !!(!get(form.errors, field.name) && get(form.touched, field.name)),
    errorMessage: get(form.touched, field.name) && get(form.errors, field.name),
  };

  switch (props.type) {
    case "checkbox":
      return <Checkbox checked={form.values[field.name] || ""} {...props} />;

    case "textColor":
      return (
        <ColorInput
          onColorSelected={(color) => form.setFieldValue(field.name, color)}
          {...props}
        />
      );

    case "select":
      return (
        <Select
          errorMessage={props.errorMessage}
          menuPlacement={props.menuPlacement}
          withDescription={props.withDescription}
          primitiveValue={props.isValuePrimitive}
          title={props.title}
          value={field.value}
          options={_props.options}
          styles={_props.styles}
          onChange={(value: any) => {
            const _value = props.isValuePrimitive ? value.value : value;
            if (_props.customOnChange) {
              const customValue = _props.customOnChange(_value);
              form.setFieldValue(field.name, customValue);
            } else {
              form.setFieldValue(field.name, _value);
            }
          }}
        />
      );
    default:
      return (
        <FullInput
          {...props}
          onChange={(event: any) => {
            const _value = event.target.value;
            if (_props.customOnChange) {
              const customValue = _props.customOnChange(_value);
              form.setFieldValue(field.name, customValue);
            } else {
              form.setFieldValue(field.name, _value);
            }
          }}
        />
      );
  }
};
export default InputField;
