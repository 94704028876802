import {
  ISpacing,
  ITextTransform,
  IFontWeight,
  IFontSize,
  ITextColor,
  ITextAlign,
  IOpacity,
  IVisibility,
  ITransition,
  IPointer,
  ITextDecoration,
} from "../../styled-components/interface";
import { styled, css } from "../../styled-components";
import {
  TextTransform,
  FontSize,
  FontWeight,
  Color,
  TextAlign,
  Opacity,
  Visibility,
  Transition,
  Pointer,
  TextDecoration,
} from "../styles";

interface ITextProps
  extends ISpacing,
    ITransition,
    ITextTransform,
    IFontWeight,
    IFontSize,
    ITextColor,
    ITextAlign,
    IOpacity,
    IVisibility,
    ITextDecoration,
    IPointer {}

const TextStyles = css`
  ${TextAlign};
  ${TextTransform};
  ${FontWeight};
  ${FontSize};
  ${Color};
  ${Opacity};
  ${Visibility};
  ${Transition};
  ${Pointer};
  ${TextDecoration};
`;

const Text = styled.div.attrs({})<ITextProps>`
  ${TextStyles};
`;

export { Text, ITextProps, TextStyles };
