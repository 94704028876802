import React from "react";
import { RouteConfig } from ".";
import { Channel } from "../interfaces";
import { isFeedType, FeedType } from "../util";
import { get, cloneDeep } from "lodash";
import { IAppContext } from "../state";
import { canView } from "./routes";

const ManageFeed = React.lazy(() => import("../containers/ManageFeed"));
const FeedDisplaySettings = React.lazy(
  () => import("../containers/FeedDisplaySettings")
);
const ChannelCurateContent = React.lazy(
  () => import("../containers/ChannelCurateContent")
);
// const FeedCta = React.lazy(() => import('../containers/__FeedCta'));
const ChannelUrl = React.lazy(() => import("../containers/ChannelUrl"));
export const EXCLUDE_FROM_CURATION_PRESENTATION_DESIGN_SLUGS = [
  "TRAFFIC",
  "AIRQUALITY",
  "WEATHER_FORECAST",
  "WEATHERMAP_NATIONAL",
  "WEATHERMAP_TEMPERATURE",
  "WEATHERMAP_PRECIPITATION",
  "FINANCIAL",
  "SPORTS",
  "HERE_TRANSPORT",
];

const ORDER = [
  "feed_curate_content",
  "feed_display_design_settings",
  "feed_settings",
  "feed_url",
];

export const generateFeedRoutes = (
  feedRoutes: RouteConfig[] = FeedRoutes,
  channel: Channel,
  Context?: IAppContext
) => {
  const _feedRoutes = cloneDeep(feedRoutes)
    .filter((item) => {
      if (get(channel, "draft") && item.name === "feed_settings") {
        item.label = "Finish setup";
        return true;
      } else if (!get(channel, "draft")) {
        switch (true) {
          case isFeedType(channel) === "PRECURATEDSOCIAL" &&
            item.name === "feed_display_design_settings":
          case isFeedType(channel) === "PRECURATEDSOCIAL" &&
            item.name === "feed_curate_content":
          case (isFeedType(channel) === "PRECURATEDSOCIAL" ||
            isFeedType(channel) === "INFOTAINMENT" ||
            isFeedType(channel) === "TEAMS") &&
            item.name === "feed_cta":
          case isFeedType(channel) === "INFOTAINMENT" &&
            EXCLUDE_FROM_CURATION_PRESENTATION_DESIGN_SLUGS.includes(
              channel.presentation_design.slug
            ) &&
            channel.title !== "Top Currencies" &&
            channel.title !== "Currencies" &&
            ["feed_curate_content"].includes(item.name):
            return false;
          default:
            return true;
        }
      }
    })
    .map((item: RouteConfig) => {
      // @TODO Fix forwarding to pending if there aren't any approved items.
      const curationStatus =
        isFeedType(channel) === "CUSTOM" ? "pending" : "approved";
      const path = item.path
        .replace(":id", String(get(channel, "code")))
        .replace(":statusType", curationStatus);
      return { ...item, path };
    })
    .filter((item) => {
      if (!Context) return true;
      const { hasFeature, hasTrialStarted, hasTrialEnded } = Context;
      const correctFeedType: FeedType =
        isFeedType(channel) === "PRECURATEDSOCIAL"
          ? "INFOTAINMENT"
          : isFeedType(channel);
      const allowedToView = canView(item, Context);
      const allowedToUseFeature =
        hasFeature(correctFeedType) || (hasTrialStarted() && !hasTrialEnded());
      return allowedToView && allowedToUseFeature;
    });

  if (channel.type.slug === "CUSTOM") {
    _feedRoutes.sort((a, b) => ORDER.indexOf(a.name) - ORDER.indexOf(b.name));

    _feedRoutes.forEach((item) => {
      if (item.name === "feed_curate_content") {
        item.label = "Add content";
      }
    });
  }

  return [..._feedRoutes];
};

export type FeedRoutesNames =
  | "feed_settings"
  | "feed_display_design_settings"
  | "feed_curate_content"
  | "feed_cta"
  | "feed_url";

const FeedRoutes: RouteConfig[] = [
  {
    name: "feed_settings",
    label: "feeds.feed_settings",
    path: "/:slug/feed/:id/settings",
    requiredUserRoles: ["ROLE_MANAGER", "ROLE_OWNER"],
    component: ManageFeed,
  },
  {
    name: "feed_display_design_settings",
    label: "feeds.feed_layout",
    path: "/:slug/feed/:id/feed-layout",
    requiredUserRoles: ["ROLE_MANAGER", "ROLE_OWNER"],
    component: FeedDisplaySettings,
  },
  {
    name: "feed_curate_content",
    label: "feeds.curate_content",
    path: "/:slug/feed/:id/curate-content/:statusType",
    requiredUserRoles: ["ROLE_MANAGER", "ROLE_OWNER", "ROLE_CURATOR"],
    component: ChannelCurateContent,
  },
  {
    name: "feed_url",
    label: "Copy feed URL",
    path: "/:slug/feed/:id/url",
    requiredUserRoles: ["ROLE_MANAGER", "ROLE_OWNER"],
    component: ChannelUrl,
  },
  {
    name: "redirect",
    label: "",
    path: "/:slug/feed/:id/settings",
    redirect: true,
    component: () => <div></div>,
  },
];

export default FeedRoutes;
