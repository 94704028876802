import React, {
  useState,
  FunctionComponent,
  useContext,
  useEffect,
} from "react";
import ReactModal from "react-modal";
import {
  Block,
  Modal,
  IModalProps,
  Icon,
  Button,
  ModalCtaContainer,
  Checkbox,
} from "../../../seenspire-library";
import { AppContext } from "../../state";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { SIDE_PADDING } from "../../containers/Dashboard/variables";
import ConfirmationModal from "../ConfirmationModal";

const NotificationIcon = styled(Icon)`
  width: 34px;
  height: 34px;
  margin-bottom: 10px;
`;

const getLocalStorageDoNotShow = () => {
  return localStorage.getItem("notifications") === "True" ? true : false;
};

const NotificationModal: FunctionComponent<IModalProps> = ({
  isOpen: ignored,
  ...props
}) => {
  const { client, user, theme, notificationConfig, setNotificationConfig } =
    useContext(AppContext);
  const { t } = useTranslation();
  const [error, setError] = useState();
  const [doNotShow, setDoNotShow] = useState(getLocalStorageDoNotShow());
  const [resenting, setResenting] = useState<boolean>(false);
  const customStyle: ReactModal.Styles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.25)",
    },
    content: {
      border: "none",
      top: theme.header.height + 10,
      left: "unset",
      right: `${SIDE_PADDING.default}%`,
      bottom: "unset",
      background: theme.colors.white.base,
      minWidth: "unset",
      width: 360,
      minHeight: 200,
      padding: "24px",
      paddingTop: "10px",
      display: "grid",
      gridGap: 24,
      alignContent: "space-between",
      transform: "none",
    },
  };

  const setDoNotShowExtended = () => {
    if (notificationConfig.skipDontShow) return;
    const currentDoNotShow = getLocalStorageDoNotShow();
    localStorage.setItem("notifications", currentDoNotShow ? "False" : "True");
    setDoNotShow(!currentDoNotShow);
  };

  return (
    <>
      <Modal
        isOpen={
          !!(notificationConfig && notificationConfig.modalSize === "small") &&
          !doNotShow
        }
        customStyle={customStyle}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => setNotificationConfig(false)}
        theme={theme}
        {...props}
      >
        {notificationConfig && (
          <>
            <Block style={{ lineHeight: "16px" }} display="grid" gridGap={5}>
              <NotificationIcon color="gray" shade="darkest" name="url" />
              <Block color="gray" shade="darkest" fontWeight="bold">
                {notificationConfig.title}
              </Block>
              <Block color="gray" shade="darkest">
                {notificationConfig.description}
              </Block>
            </Block>

            <ModalCtaContainer>
              <Button
                elementWidth="block"
                onClick={() => setNotificationConfig(false)}
                buttonType="cta"
              >
                {t("Got it")}
              </Button>
              <Block display="grid" justifyContent="center" alignItems="center">
                <Checkbox
                  title="Don't show again"
                  checked={doNotShow}
                  onChange={setDoNotShowExtended}
                />
              </Block>
            </ModalCtaContainer>
          </>
        )}
      </Modal>
      <ConfirmationModal
        notificationMode={true}
        onConfirm={() => Promise.resolve(setNotificationConfig(false))}
        isOpen={notificationConfig && notificationConfig.modalSize === "large"}
        title={notificationConfig && notificationConfig.title}
        subTitle={notificationConfig && notificationConfig.subTitle}
        description={notificationConfig && notificationConfig.description}
        confirmLabel={notificationConfig && notificationConfig.confirmLabel}
      />
    </>
  );
};

export { NotificationModal };
