import React, { FunctionComponent, useEffect, useState } from "react";
import { IRouted } from "../../routes";
import { Channel } from "../../interfaces";
import PreviewModal from "../../modals/PreviewModal";
import { Block, styled, createGlobalStyle } from "../../../seenspire-library";

const SliderPreviewStyled = styled(Block)<{ active: boolean }>`
  height: 100vh;
  width: 100vw;
  display: grid;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  display: ${({ active }) => (active ? "grid" : "none")};
`;

const TransparentBG = createGlobalStyle`
  body {
    background-color: transparent !important;
  }
`;

const SliderPreview: FunctionComponent<IRouted<{ id: string }>> = ({
  match,
  client,
}) => {
  const [channelInPreview, setChannelInPreview] = useState<Channel>();
  useEffect(() => {
    window.addEventListener("message", receiveMessage, false);
    function receiveMessage(event: any) {
      // console.log(`message from ${event.origin} with data: `, event.data);
      if (event.data.isSliderPreview) {
        const _channelInPreview = event.data.channel;
        setChannelInPreview(_channelInPreview);
      }
    }
  }, []);

  const closeSliderPreview = () => {
    setChannelInPreview(undefined);
    window.parent.postMessage({ closeSliderPreview: true }, "*");
  };

  return (
    <>
      <TransparentBG />
      <SliderPreviewStyled active={!!channelInPreview}>
        <PreviewModal
          isOpen={!!channelInPreview}
          channel={channelInPreview}
          channelType="open"
          onCancel={() => closeSliderPreview()}
        />
      </SliderPreviewStyled>
    </>
  );
};

export { SliderPreview };
