import React, { FunctionComponent, useContext, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { matchPath } from "react-router";
import { useTranslation } from "react-i18next";
import { useMedia } from "../util";
import { isReferral } from "../util/AccountUtil";
import { AppContext, IAppContext } from "../state";
import { RouteConfig } from "./interfaces";
import AccountRoutes from "./AccountRoutes";
import PartnerRoutes from "./PartnerRoutes";
import FeedRoutes from "./FeedRoutes";
import AuthRoutes from "./AuthRoutes";
import ContentPartnerRoutes from "./ContentPartnerRoutes";
import { UserMe } from "seenspire-api-client";
import { find, intersection, isEmpty, chain } from "lodash";
import xss from "xss";
import {
  UserRoles,
  UserRolesEnum,
  AccountType,
  AccountUserRoleMeAccount,
} from "../interfaces";
import SliderPreview from "../containers/SliderPreview";
import StreamRoutes from "./StreamRoutes";

const Slider = React.lazy(() => import("../containers/Slider"));
const Dashboard = React.lazy(() => import("../containers/Dashboard"));
const Category = React.lazy(() => import("../containers/Category"));
const MyFeeds = React.lazy(() => import("../containers/MyFeeds"));
const Profile = React.lazy(() => import("../containers/Profile"));
const ManageFeed = React.lazy(() => import("../containers/ManageFeed"));
const ManagePlan = React.lazy(() => import("../containers/ManagePlan"));
const Feed = React.lazy(() => import("../containers/Feed"));
const Account = React.lazy(() => import("../containers/Account"));
const Partner = React.lazy(() => import("../containers/Partner"));
const ContentPartner = React.lazy(() => import("../containers/ContentPartner"));
const Redirection = React.lazy(() => import("../containers/Redirection"));
const Stream = React.lazy(() => import("../containers/Stream/index"));

const SEENSPIRE_CONNECT_SLUG = "seenspire-connect";
// const SEENSPIRE_CONNECT_SLUG = '2a059457';
export const isSeenspireConnectHost = () => {
  return window.location.host.split(".")[0].includes(SEENSPIRE_CONNECT_SLUG);
};

export const getSlug = () => {
  const slug = window.location.pathname.split("/")[1];
  return slug || "seenspire";
};
export const storeSlug = (slug: string | undefined) => {
  return slug
    ? localStorage.setItem("slug", slug)
    : localStorage.removeItem("slug");
};

export const RoutesConfig: RouteConfig[] = [
  ...AuthRoutes,
  {
    name: "redirection",
    label: "Redirection",
    path: "/:slug/redirection",
    component: Redirection,
    open: true,
  },
  {
    name: "slider_preview",
    label: "Slider Preview",
    path: "/:slug/slider-preview",
    component: SliderPreview,
    open: true,
  },
  {
    name: "slider",
    label: "Slider",
    path: "/:slug/slider/:id",
    component: Slider,
    open: true,
  },
  {
    name: "dashboard",
    label: "Dashboard",
    path: "/:slug/dashboard",
    component: Dashboard,
    open: false,
  },
  {
    name: "category",
    label: "Category",
    path: "/:slug/category/:id",
    component: Category,
  },
  {
    name: "stream",
    label: "Stream",
    path: "/:slug/stream",
    component: Stream,
    routes: StreamRoutes,
  },
  {
    name: "my_feeds",
    label: "My Feeds",
    path: "/:slug/my-feeds",
    component: MyFeeds,
  },
  {
    name: "profile",
    label: "My Profile",
    path: "/:slug/profile",
    component: Profile,
  },
  {
    name: "feed",
    label: "Feed",
    path: "/:slug/feed/",
    component: Feed,
    routes: FeedRoutes,
  },
  {
    name: "account",
    label: "Account",
    path: "/:slug/account/",
    component: Account,
    routes: AccountRoutes,
  },
  {
    name: "partner",
    label: "Partner",
    path: "/:slug/partner/",
    component: Partner,
    routes: PartnerRoutes,
  },
  {
    name: "content_partner",
    label: "Content Partner",
    path: "/:slug/content-partner/",
    component: ContentPartner,
    routes: ContentPartnerRoutes,
  },
  {
    name: "redirect",
    label: "redirect",
    path: `/${getSlug()}/login`,
    redirect: true,
    component: () => <div></div>,
  },
  {
    name: "redirect",
    label: "redirect",
    path: `/${getSlug()}`,
    redirect: true,
    component: () => <div></div>,
  },
];

export const replaceSlug = (pathname: string, newSlug: any) => {
  const array = pathname.split("/");
  array[1] = newSlug;
  return array.join("/");
};

export const excludeSlugFromPathname = (pathname: string) => {
  return pathname.split("/:slug/")[1];
};

export const getEntityId = (entityName: string, pathname: string) => {
  const entityPath = pathname.split(`/${entityName}/`) || [];
  const entityId =
    entityPath[1] && entityPath[1].split("/") && entityPath[1].split("/")[0];
  return entityId;
};

export const getStreamEntityId = (entityName: string, pathname: string) => {
  const entityPath = pathname.split(`/${entityName}/`) || [];
  const entityId =
    entityPath[1] && entityPath[1].split("/") && entityPath[1].split("/")[0];
  return entityId;
};

export const getParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  let params: { [key: string]: any } = {};
  searchParams.forEach((value, key) => {
    params[key] = value;
  });
  return params;
};

export const deleteQueryParam = (parameter: string) => {
  //prefer to use l.search if you have a location/link object
  const urlparts = window.location.href.split("?");
  if (urlparts.length >= 2) {
    const prefix = encodeURIComponent(parameter) + "=";
    const pars = urlparts[1].split(/[&;]/g);

    //reverse iteration as may be destructive
    for (var i = pars.length; i-- > 0; ) {
      //idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    return pars.length > 0 ? "?" + pars.join("&") : "";
  }
  return "";
};

export const useCurrentRoute = (
  location: any, // issue with type mismatch with history, to investigate further
  routesConfig: RouteConfig[]
) => {
  const exactRoute = chain(routesConfig)
    .reduce((results, current) => {
      results = [...results, current];
      if (current.routes) results = [...results, ...current.routes];
      return results;
    }, [] as RouteConfig[])
    .find((route) => {
      const match = matchPath(location.pathname, route.path);
      return !!(match && match.isExact);
    })
    .value();

  const currentRoute =
    routesConfig.find((route) => !!matchPath(location.pathname, route.path)) ||
    routesConfig[0];

  const match = currentRoute
    ? matchPath<any>(location.pathname, currentRoute.path)
    : null;

  return { currentRoute, exactRoute, match };
};

export const getCurrentUserAccount = (user: UserMe, slug: string) => {
  return find(user.account_users, (account) => {
    return slug === account.account.organization_info.slug;
  });
};

export const isRemoteLogin = (_user: UserMe, slug: string) => {
  return !getCurrentUserAccount(_user, slug);
};

export const allowForNoneReferralUsers = (
  account: AccountUserRoleMeAccount,
  user: UserMe,
  slug: string
) => {
  return !isReferral(account) || isRemoteLogin(user, slug);
};

export const hasRequiredUserRoles = (
  userRoles: UserRoles[] = [],
  route: RouteConfig
) => {
  if (!route.requiredUserRoles) return true;
  const intersected = intersection(userRoles, route.requiredUserRoles);
  return !isEmpty(intersected);
};

export const hasPartnerPortalManagerRole = (user: UserMe) => {
  return chain(user.account_users)
    .map("roles")
    .flatten()
    .map("slug")
    .includes(UserRolesEnum.ROLE_PARTNER_PORTAL_MANAGER)
    .value();
};

export const canView = (
  route: RouteConfig,
  { authenticated, activated, user, account, slug }: IAppContext,
  skipActivation: boolean = false
) => {
  const currentUserAccount = getCurrentUserAccount(user, slug);

  let hasUserRoles = false;
  if (currentUserAccount) {
    hasUserRoles = hasRequiredUserRoles(
      currentUserAccount.roles.map((r: any) => r.slug),
      route
    );
  } else {
    // Remote login
    hasUserRoles = hasPartnerPortalManagerRole(user);
  }

  const passRouteRestriction = route.restrictOn
    ? !route.restrictOn.includes(account.account.type as AccountType)
    : true;
  const passActivation = skipActivation ? true : activated;

  return (
    (route.open && !route.requiredUserRoles) ||
    (passActivation && authenticated && hasUserRoles && passRouteRestriction) ||
    route.name === "dashboard"
  );
};

const Routes: FunctionComponent<{ routesConfig: RouteConfig[] }> = ({
  routesConfig,
}) => {
  const media = useMedia();
  const Translation = useTranslation();
  const Context = useContext(AppContext);
  const { loading, exactRoute: route } = Context;

  useEffect(() => {}, [loading]);

  useEffect(() => {
    console.log(Context.account?.id, document.body.getElementsByClassName("beamer-config").length);
    if (Context.account?.id > 0 && document.body.getElementsByClassName("beamer-config").length === 0) {
      
      const scriptConfig = document.createElement("script");
      scriptConfig.className = "beamer-config";

      scriptConfig.text = `
        var beamer_config = {
          product_id : 'espKGuCE56878',
          user_firstname : "${xss(Context.user.first_name)}",
          user_lastname : "${xss(Context.user.last_name)}",
          user_email : "${xss(Context.user.email)}"
        };
      `;

      document.body.appendChild(scriptConfig);

      const script = document.createElement("script");
      script.src = "https://app.getbeamer.com/js/beamer-embed.js";
      script.async = true;
  
      document.body.appendChild(script);
    }
  }, [Context]);

  switch (true) {
    case isEmpty(route):
      return <Redirect to={`/${getSlug()}/login?${window.location.search}`} />;

    // @TODO Use the correct redirect based on the main route
    // (example: /:slug/account/wrong-path => /:slug/account/home)
    case route.redirect:
      return <Redirect to={`/${getSlug()}/dashboard`} />;

    case canView(route, Context):
      return (
        <Route
          path={route.path}
          render={(props) => {
            const Component = route.component as any;
            // pass the sub-routes down to keep nesting
            return (
                <Component
                  media={media}
                  {...Context}
                  {...Translation}
                  route={route}
                  {...props}
                />
            );
          }}
        />
      );

    default:
      // @TODO Apply proper loading indicator to improve UX
      // console.log('can not view : ', route.path)
      return <span>...</span>;
  }
};

export const goTo = (
  routeName: string,
  reload?: boolean,
  replace?: boolean
) => {
  const route = replace ? routeName : `/${getSlug()}/${routeName}`;
  if (reload) {
    window.history.pushState(undefined, "", route);
    window.location.reload();
  } else {
    window.history.pushState(undefined, "", route);
  }
};

export default Routes;
