import { ThemedCssFunction } from "styled-components";
import { css } from "./styled-components";
import { ITheme, IResponsive } from "./interface";
import { theme } from "./theme";
import { useMedia } from "react-use";
import {
  kebabCase,
  isString,
  isNumber,
  isArrayLikeObject,
  isPlainObject,
  isUndefined,
  isObjectLike,
  reduce,
} from "lodash-es";
const MediaGT = (
  Object.keys(theme.mediaSizes) as (keyof typeof theme.mediaSizes)[]
).reduce((acc, label) => {
  acc[label] = (first: any, ...interpolations: any[]) => css`
    @media (min-width: ${theme.mediaSizes[label]}px) {
      ${css(first, ...interpolations)}
    }
  `;
  return acc;
}, {} as { [key in keyof typeof theme.mediaSizes]: ThemedCssFunction<ITheme> });

const MediaLT = (
  Object.keys(theme.mediaSizes) as (keyof typeof theme.mediaSizes)[]
).reduce((acc, label) => {
  acc[label] = (first: any, ...interpolations: any[]) => {
    let level = ``;
    switch (label) {
      case "medium":
        level = `(max-width: ${theme.mediaSizes.small - 1}px)`;
        break;
      case "large":
        level = `(max-width: ${theme.mediaSizes.medium - 1}px)`;
        break;
      case "xlarge":
        level = `(max-width: ${theme.mediaSizes.large - 1}px)`;
        break;
    }
    return css`
      @media ${level} {
        ${css(first, ...interpolations)}
      }
    `;
  };
  return acc;
}, {} as { [key in keyof typeof theme.mediaSizes]: ThemedCssFunction<ITheme> });

const MediaAt = (
  Object.keys(theme.mediaSizes) as (keyof typeof theme.mediaSizes)[]
).reduce((acc, label) => {
  acc[label] = (first: any, ...interpolations: any[]) => {
    // Start with small
    const range = useMediaRange(label);
    return css`
      @media ${range} {
        ${css(first, ...interpolations)}
      }
    `;
  };

  return acc;
}, {} as { [key in keyof typeof theme.mediaSizes]: ThemedCssFunction<ITheme> });

const useMediaRange = (label: keyof typeof theme.mediaSizes) => {
  let range = `(max-width: ${theme.mediaSizes.small - 1}px)`;
  switch (label) {
    case "medium":
      range = `(min-width: ${theme.mediaSizes.small}px) and (max-width: ${
        theme.mediaSizes.medium - 1
      }px)`;
      break;
    case "large":
      range = `(min-width: ${theme.mediaSizes.medium}px) and (max-width: ${
        theme.mediaSizes.large - 1
      }px)`;
      break;
    case "xlarge":
      range = `(min-width: ${theme.mediaSizes.large}px) and (max-width: ${
        theme.mediaSizes.xlarge - 1
      }px)`;
      break;
  }
  return range;
};

const mapValuesToMediaSizes = (
  cssPropertyName: keyof React.CSSProperties,
  propertyValue: IResponsive<any>,
  typeToValueMap: { [key: string]: any }
) => {
  if (isObjectLike(propertyValue)) {
    const map = reduce(
      propertyValue,
      (acc: any, value: any, mediaSizeKey: any) => {
        acc[mediaSizeKey] =
          !isUndefined(propertyValue[mediaSizeKey]) && typeToValueMap[value];
        return acc;
      },
      {} as any
    );
    return withMedia(cssPropertyName, map);
  }
  return withMedia(cssPropertyName, typeToValueMap[propertyValue]);
};

const cssKeyValue = (
  cssPropertyName: string,
  value: string | number,
  middleware?: Function
) =>
  value || value === 0
    ? `${kebabCase(cssPropertyName)} : ${
        middleware ? middleware(value) : value
      }`
    : null;

const withMedia = (
  cssPropertyName: keyof React.CSSProperties,
  propertyValue: IResponsive<any>,
  middleware?: Function,
  conditionConfig?: [IResponsive<any>, any]
) => {
  const [condition, conditionValue] = conditionConfig || [null, null];
  const isPropertyValuePrimitive =
    isString(propertyValue) || isNumber(propertyValue);

  let small, medium, large, xlarge;
  let conditionForSmall = !conditionConfig;
  let conditionForMedium = !conditionConfig;
  let conditionForLarge = !conditionConfig;
  let conditionForXlarge = !conditionConfig;
  let primitiveCondition = condition === conditionValue;

  // check condition
  switch (true) {
    case isPlainObject(condition):
      conditionForSmall = condition.small === conditionValue;
      conditionForMedium = isUndefined(condition.medium)
        ? conditionForSmall
        : condition.medium === conditionValue;
      conditionForLarge = isUndefined(condition.large)
        ? conditionForMedium
        : condition.large === conditionValue;
      conditionForXlarge = isUndefined(condition.xlarge)
        ? conditionForLarge
        : condition.xlarge === conditionValue;
      break;
    case isArrayLikeObject(condition):
      conditionForSmall = condition[0] === conditionValue;
      conditionForMedium = isUndefined(condition[1])
        ? conditionForSmall
        : condition[1] === conditionValue;
      conditionForLarge = isUndefined(condition[2])
        ? conditionForMedium
        : condition[2] === conditionValue;
      conditionForXlarge = isUndefined(condition[3])
        ? conditionForLarge
        : condition[3] === conditionValue;
      break;
    case isString(condition):
      conditionForSmall = primitiveCondition;
      conditionForMedium = primitiveCondition;
      conditionForLarge = primitiveCondition;
      conditionForXlarge = primitiveCondition;
  }

  // check propertyValue
  switch (true) {
    case isPlainObject(propertyValue):
      small = conditionForSmall && propertyValue.small;
      medium =
        conditionForMedium &&
        (isUndefined(propertyValue.medium) ? small : propertyValue.medium);
      large =
        conditionForLarge &&
        (isUndefined(propertyValue.large) ? medium : propertyValue.large);
      xlarge =
        conditionForXlarge &&
        (isUndefined(propertyValue.xlarge) ? large : propertyValue.xlarge);
      break;
    case isArrayLikeObject(propertyValue):
      small = conditionForSmall && propertyValue[0];
      medium =
        conditionForMedium &&
        (isUndefined(propertyValue[1]) ? small : propertyValue[1]);
      large =
        conditionForLarge &&
        (isUndefined(propertyValue[2]) ? medium : propertyValue[2]);
      xlarge =
        conditionForXlarge &&
        (isUndefined(propertyValue[3]) ? large : propertyValue[3]);
      break;
  }

  // return the actual css value or null;
  switch (true) {
    case isPropertyValuePrimitive && primitiveCondition:
      return cssKeyValue(cssPropertyName, propertyValue, middleware);
    case isPropertyValuePrimitive && !primitiveCondition:
      return css`
        ${conditionForSmall &&
        Media.at.small`${cssKeyValue(
          cssPropertyName,
          propertyValue,
          middleware
        )}`}
        ${conditionForMedium &&
        Media.at.medium` ${cssKeyValue(
          cssPropertyName,
          propertyValue,
          middleware
        )}`}
        ${conditionForLarge &&
        Media.at.large` ${cssKeyValue(
          cssPropertyName,
          propertyValue,
          middleware
        )}`}
        ${conditionForXlarge &&
        Media.gt.large` ${cssKeyValue(
          cssPropertyName,
          propertyValue,
          middleware
        )}`}
      `;
    case isPlainObject(propertyValue) || isArrayLikeObject(propertyValue):
      return css`
        ${conditionForSmall &&
        Media.at.small` ${cssKeyValue(cssPropertyName, small, middleware)} `}
        ${conditionForMedium &&
        Media.at.medium` ${cssKeyValue(cssPropertyName, medium, middleware)} `}
        ${conditionForLarge &&
        Media.at.large` ${cssKeyValue(cssPropertyName, large, middleware)} `}
        ${conditionForXlarge &&
        Media.gt.large` ${cssKeyValue(cssPropertyName, xlarge, middleware)} `}
      `;
    default:
      return null;
  }
};

// @TODO Investigate using hooks from within the library
const _useMedia = () => {
  const isSmall = useMedia(useMediaRange("small"));
  const isMedium = useMedia(useMediaRange("medium"));
  const isLarge = useMedia(useMediaRange("large"));
  const isXLarge = useMedia(useMediaRange("xlarge"));
  return { isSmall, isMedium, isLarge, isXLarge };
};

const Media = {
  gt: MediaGT,
  lt: MediaLT,
  at: MediaAt,
  useMediaRange,
  useMedia: _useMedia,
};
interface reuse2 extends React.HTMLProps<any> {}
export { Media, withMedia, mapValuesToMediaSizes, reuse2 };
