import { useState, useEffect, useRef } from "react";
import { useMedia as _useMedia } from "react-use";
import { Media } from "../../seenspire-library";
import { reduce, get, transform, isEqual, isObject } from "lodash";
import {
  User,
  UsersAccount,
  FeedType as API_FeedType,
  FeedTypeEnum,
} from "seenspire-api-client";
import formatDuration from "format-duration";
import { Channel, ActiveChannel } from "../interfaces";

export * from "./AccountUtil";
export * from "./MediaUtil";

export function isValidDateString(dateText: string | Date | undefined) {
  if (!dateText) return false;
  return (new Date(dateText) as any) != "Invalid Date";
}
export function toTime(dateText: string | Date): string {
  if (!isValidDateString(dateText)) return "-";
  return new Date(dateText).toISOString().slice(11, 16);
}
export function toDayDate(dateText: string | Date): string {
  if (!isValidDateString(dateText)) return "-";
  return new Date(dateText).toDateString();
}
export function formattedDate(
  dateText: string | Date,
  singleDigits?: boolean
): string {
  if (!isValidDateString(dateText)) return "-";
  let d: number | string = new Date(dateText).getUTCDate();
  let m: number | string = new Date(dateText).getMonth() + 1;
  let y = new Date(dateText).getFullYear();
  if (!singleDigits) {
    d = d.toString().length < 2 ? `0${d}` : d;
    m = m.toString().length < 2 ? `0${m}` : m;
  }
  return `${d}-${m}-${y}`;
}
export function dt(dateText: string | Date | undefined) {
  if (!isValidDateString(dateText) || dateText === undefined) return "-";
  return `${formattedDate(dateText)} - ${toTime(dateText)}`;
}
export function durationSince(dateText: string | Date): string {
  return formatDuration(new Date(dateText).getTime());
}

export function formattedPriceWithCurrency(rawPrice: string | number, currency: {
  rateRatio: number;
  symbol: string;
} | undefined) {

  console.log("formattedPriceWithCurrency", rawPrice);

  if (!currency) {
    return formattedPrice(rawPrice);
  }

  const price = Number(rawPrice) * (currency?.rateRatio || 1);

  if (price === Number.NaN) {
    return "-";
  }

  return `${currency?.symbol}${price % 1 > 0 ? Number(price).toFixed(2) : Number(price)}`;
}

export function formattedPrice(rawPrice: string | number) {

  return Number(rawPrice) ? `$${Number(rawPrice)}` : "-";
}

export interface IMediaSizes {
  isSmall: boolean;
  isGTSmall: boolean;
  isMedium: boolean;
  isGTMedium: boolean;
  isLarge: boolean;
  isGTLarge: boolean;
  isXLarge: boolean;
  isGTXLarge: boolean;
}
export const useMedia = (): IMediaSizes => {
  const isSmall = _useMedia(Media.useMediaRange("small"));
  const isGTSmall = !isSmall;

  const isMedium = _useMedia(Media.useMediaRange("medium"));
  const isGTMedium = isGTSmall && !isMedium;

  const isLarge = _useMedia(Media.useMediaRange("large"));
  const isGTLarge = isGTMedium && !isLarge;

  const isXLarge = _useMedia(Media.useMediaRange("xlarge"));
  const isGTXLarge = isGTLarge && !isXLarge;

  return {
    isSmall,
    isGTSmall,
    isMedium,
    isGTMedium,
    isLarge,
    isGTLarge,
    isXLarge,
    isGTXLarge,
  };
};

interface ScrollPosition {
  x: number;
  y: number;
}

function getScrollPosition(): ScrollPosition {
  return { x: window.pageXOffset, y: window.pageYOffset };
}

export function useScrollPosition(): ScrollPosition {
  const [position, setScrollPosition] = useState<ScrollPosition>(
    getScrollPosition()
  );
  useEffect(() => {
    let requestRunning: number | null = null;
    function handleScroll() {
      if (requestRunning === null) {
        requestRunning = window.requestAnimationFrame(() => {
          setScrollPosition(getScrollPosition());
          requestRunning = null;
        });
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return position;
}

export function useScrollXPosition(): number {
  const { x } = useScrollPosition();
  return x;
}

export function useScrollYPosition(): number {
  const { y } = useScrollPosition();
  return y;
}

export function formatServerValidationErrors(errorRespond: any) {
  if (errorRespond.response && errorRespond.response.data) {
    console.log(errorRespond.response && errorRespond.response.data);
    return reduce(
      errorRespond.response.data.errorMessage,
      (
        acc: { [x: string]: string },
        error: { message: string },
        key: string
      ) => {
        acc[key] = error.message;
        return acc;
      },
      {} as { [key: string]: string }
    );
  }
  return {};
}

export function formatServerGeneralError(errorRespond: any) {
  return (
    get(errorRespond, "response.data.message") ||
    get(errorRespond, "response.data.error_description") ||
    get(errorRespond, "response.data.error.message") ||
    get(errorRespond, "response.data.errorMessage[0].message")
  );
}

export function nullToEmptyString<T>(object: { [key: string]: any }) {
  let _object: { [key: string]: any } = {};
  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      _object[key] = object[key] === null ? "" : object[key];
    }
  }
  return _object as T;
}

export function name(user: User | UsersAccount) {
  const { first_name, last_name } = user;
  return `${first_name || "-"} ${last_name || "-"}`;
}

export const useInfiniteScroll = (callback: any, threshold: number = 0) => {
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (!isFetching) return;
    callback();
  }, [isFetching]);

  function handleScroll() {
    // if (document.documentElement.offsetHeight - (window.innerHeight + document.documentElement.scrollTop) <= threshold  || isFetching) return;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.offsetHeight;
    const scrollTop = document.documentElement.scrollTop;
    const difference = windowHeight + scrollTop - documentHeight;
    const shouldFetch = Math.abs(difference) < threshold;
    const condition = !shouldFetch || isFetching;
    // console.table({
    //   isFetching,
    //   windowHeight,
    //   documentHeight,
    //   scrollTop,
    //   difference,
    //   shouldFetch
    // })
    // console.log({condition,isFetching})
    if (condition) return;
    setIsFetching(true);
  }

  return { isFetching, setIsFetching };
};

export function useInterval(callback: () => any, delay: number) {
  const savedCallback = useRef(callback);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const defaultState: {
  image: HTMLImageElement | undefined;
  loaded: boolean;
  status: string;
} = { image: undefined, loaded: false, status: "loading" };

export function useImage({
  url,
  crossOrigin,
}: {
  url: string;
  crossOrigin?: string;
}): [any, boolean] {
  const [{ loaded, image }, setState] = useState(defaultState);

  useEffect(
    function () {
      if (!url) return;
      const img = document.createElement("img");

      function onload() {
        setState({ image: img, loaded: true, status: "loaded" });
      }

      function onerror() {
        setState({ image: undefined, loaded: false, status: "failed" });
      }

      img.addEventListener("load", onload);
      img.addEventListener("error", onerror);
      crossOrigin && (img.crossOrigin = crossOrigin);
      img.src = url;

      return function cleanup() {
        img.removeEventListener("load", onload);
        img.removeEventListener("error", onerror);
        setState(defaultState);
      };
    },
    [url, crossOrigin]
  );

  return [image, loaded];
}

interface imageConfig {
  loaded: boolean;
  url: string;
}
export function useImages(media: any[]): [imageConfig[], boolean] {
  const [images, setImages] = useState<imageConfig[]>([]);

  useEffect(
    function () {
      if (!media) return;

      const cleanups = media
        .filter((mediaItem) => mediaItem.thumb)
        .map((mediaItem) => {
          const img = document.createElement("img");

          function onload() {
            setImages((prevImages) => [
              ...prevImages,
              { loaded: true, url: mediaItem.thumb.url },
            ]);
          }
          img.addEventListener("load", onload);
          img.src = mediaItem.thumb.url;

          return function cleanup() {
            img.removeEventListener("load", onload);
          };
        });

      return () => {
        cleanups.map((cb) => cb());
      };
    },
    [media]
  );

  const imageLoaded = !!(
    media &&
    media.length &&
    media.filter((mediaItem) => !!mediaItem.thumb).length === images.length &&
    images.every((image) => image.loaded)
  );

  return [images, imageLoaded];
}

export type FeedType = API_FeedType;
export { FeedTypeEnum };
export const isFeedType = (channel: Channel | ActiveChannel): FeedType => {
  switch (true) {
    // @ts-ignore-next-line
    case !!get(channel, "active_channel_template"):
      console.log("ISSUE active_channel_template");
      return "PRECURATEDSOCIAL";
    default:
      return channel.type && (channel.type.slug as FeedType);
  }
};

export function translateCollection<T>(
  collection: T[],
  t: any,
  label: string = "label"
) {
  return collection.map((item: any) => {
    item[label] = t(item[label]);
    return item as T;
  });
}

export function difference(object: any, base: any) {
  return transform(object, (result: any, value: any, key: any) => {
    if (!isEqual(value, base[key])) {
      result[key] =
        isObject(value) && isObject(base[key])
          ? difference(value, base[key])
          : value;
    }
  });
}
