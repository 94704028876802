import { useContext, useEffect, useState } from "react";
import { getToken } from "../../backend/session";
import { api } from "../../backend/api";
import { AppContext } from "../../state";

interface UseAccountInvoicesReturnType {
  invoices: Invoice[];
  loading: boolean;
  getAll: () => void;
  openPDF: (id: number) => void;
}

interface Plan {
  name: string;
}

interface UpgradeRequest {
  licences: number;
  plan: Plan;
  payment: any;
}

interface Payment {
  confirmed: boolean;
  created_at: string;
  amount: number;
  status: InvoiceStatus;
  upgrade_request: UpgradeRequest | undefined;
  currency: "gbp" | "eur" | "usd";
}

export type InvoiceStatus = "succeeded" | "failed";

export interface Invoice {
  payment: Payment;
  external_id: string | undefined | null;
  id: number;
  json_data: string;
}

export const useAccountInvoices = (): UseAccountInvoicesReturnType => {
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { reAuthenticate } = useContext(AppContext);

  useEffect(() => {
    getAll();
  }, []);

  useEffect(() => {
    if (!reAuthenticate) return;

    getAll();
  }, [reAuthenticate]);

  const getAll = async () => {
    setLoading(true);
    const invoces = await getInvoices();
    setInvoices(invoces);
    setLoading(false);
  };

  const openPDF = (id: number) => {
    const access_token = getToken().access_token;
    const url = `${process.env.REACT_APP_API}/api/externalinvoices/${id}/pdf?access_token=${access_token}`;
    window.open(url, "_blank");
  };

  return {
    invoices,
    loading,
    getAll,
    openPDF,
  };
};

const getHeaders = () => {
  const currentToken = getToken();

  const headers = {
    Authorization: `Bearer ${currentToken.access_token}`,
  };

  return headers;
};

const getInvoices = async () => {
  try {
    const response = await api.get(
      `${process.env.REACT_APP_API}/api/externalinvoices.json`,
      {
        method: "GET",
        headers: getHeaders(),
      }
    );

    console.log("response:", response);

    return response.data.data || [];
  } catch (e) {
    console.error("Get List of Invoices Error:", e);
  }
};

export const formatCurrency = (currency?: "gbp" | "eur" | "usd") => {
  if (currency === "gbp") {
    return "£";
  }

  if (currency === "eur") {
    return "€";
  }

  if (currency === "usd") {
    return "$";
  }

  return "-";
};
