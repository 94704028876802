import React, {
  useState,
  FunctionComponent,
  useContext,
  useEffect,
} from "react";
import {
  Block,
  Modal,
  Select,
  PanelHeader,
  IModalProps,
  Icon,
  Button,
  ModalTitle,
  ModalSubTitle,
  ModalDescription,
  ModalIconContainer,
  ModalTitleContainer,
  ModalCtaContainer,
  styled,
} from "../../../seenspire-library";
import PanelForm from "../../components/PanelForm/PanelForm";
import { Formik, FormikHelpers, Field } from "formik";
import * as Yup from "yup";
import { Invite } from "seenspire-api-client";
import { formatServerValidationErrors } from "../../util";
import InputField from "../../components/InputField/InputField";
import { AppContext } from "../../state";
import { useTranslation, Trans } from "react-i18next";
import {
  ModalButtons,
  FormModalButtons,
} from "../../components/ModalButtons/ModalButtons";
import { ISocialNetworksTypesConfig } from "../../containers/AccountSocialNetworks/AccountSocialNetworks";
import { find } from "lodash";
import { AuthRoutNames } from "../../routes/AuthRoutes";

const StyledLink = styled.a`
  font-weight: 600;
`;

const ConfirmEmailModal: FunctionComponent<IModalProps> = ({
  isOpen: ignored,
  ...props
}) => {
  const {
    slug,
    client,
    user,
    authenticated,
    activated,
    theme,
    currentRoute,
    logout,
    confirmEmailModalIsOpen,
    setConfirmEmailModalIsOpen,
  } = useContext(AppContext);
  const { t } = useTranslation();
  const [error, setError] = useState<string | unknown>();
  const [sent, setSent] = useState(false);
  const [resenting, setResenting] = useState<boolean>(false);

  useEffect(() => {
    setSent(false);
  }, [confirmEmailModalIsOpen]);

  const submitData = async () => {
    setResenting(true);
    try {
      await client.OpenUsers.postResendConfirmation({ email: user.email });
      setResenting(false);
      setSent(true);
    } catch (error) {
      let message = "Unknown error";
      if (error instanceof Error) {
        message = error.message;
      }
      setResenting(false);
      setError(message);
      setSent(false);
    }
  };

  return (
    <Modal
      isOpen={confirmEmailModalIsOpen}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => setConfirmEmailModalIsOpen(false)}
      theme={theme}
      {...props}
    >
      <Block>
        <ModalIconContainer>
          <Icon iconSize="larger" name="mail" />
        </ModalIconContainer>

        <ModalTitleContainer>
          <ModalSubTitle>&nbsp;</ModalSubTitle>
          <ModalTitle>{t("auth.confirm_email_title")}</ModalTitle>
        </ModalTitleContainer>

        <ModalDescription>
          <Trans i18nKey="auth.email_confirmation_description"></Trans>
          <br />
          <Trans i18nKey="auth.if_you_need_assistance">
            <span></span>
            <StyledLink
              href="https://help.seenspire.com/portal/newticket"
              target="_blank"
            >
              {" "}
              contact us
            </StyledLink>
            .
          </Trans>
        </ModalDescription>
      </Block>

      <ModalCtaContainer>
        {sent ? (
          <Button
            elementWidth="block"
            buttonType="success"
            disablePointer={true}
          >
            {t("Confirmation sent!")}
          </Button>
        ) : (
          <Button
            elementWidth="block"
            disabled={resenting}
            onClick={() => submitData()}
            buttonType={resenting ? "accent" : "cta"}
          >
            {resenting ? (
              <Icon name="loading" strokeColor="white" />
            ) : (
              t("auth.resed_confirmation_email")
            )}
          </Button>
        )}
      </ModalCtaContainer>
    </Modal>
  );
};

export { ConfirmEmailModal };
