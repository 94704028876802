import { UserRoles } from "../interfaces";
import { UserMe } from "seenspire-api-client";

export type PermissionKeyType =
  | "FEED.CREATE_ADD_FEED.MANAGE"
  | "FEED.MANAGE_FEED.MANAGE";

export interface IPermission {
  key: PermissionKeyType;
  roles: UserRoles[];
}

export const Permissions: IPermission[] = [
  {
    key: "FEED.MANAGE_FEED.MANAGE",
    roles: ["ROLE_MANAGER", "ROLE_OWNER"],
  },
  {
    key: "FEED.CREATE_ADD_FEED.MANAGE",
    roles: ["ROLE_MANAGER", "ROLE_OWNER"],
  },
];
