import * as React from "react";
import { FunctionComponent } from "react";
import { Block, IBlockProps } from "../Block/Block";
import { Icon, IconName } from "../Icon/Icon";
import { Text } from "../Text/Text";
import { Button } from "../Button/Button";
import { isEmpty } from "lodash-es";
import { ListItemBody, ListItemRow, ListItemCell } from "../List/List";

interface IEmptyEntityFallbackProps extends IBlockProps {
  ctaIcon?: IconName;
  ctaLabel?: any;
  descriptionIcon?: IconName;
  description?: any;
  onAction?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const EmptyEntityFallback: FunctionComponent<IEmptyEntityFallbackProps> = ({
  ctaIcon,
  ctaLabel,
  descriptionIcon,
  description,
  onAction,
  ...blockProps
}) => {
  return (
    <Block
      display="grid"
      textAlign="center"
      padding={{ all: 25 }}
      gridGap={35}
      justifyContent="center"
      alignItems="center"
      {...blockProps}
    >
      <Block
        display="flex"
        justifyContent="center"
        justifyItems="center"
        fontSize="largest"
        color="gray"
        shade="lighter"
      >
        <Icon
          name={descriptionIcon || "information"}
          opacity={0.5}
          iconSize="largest"
        />
      </Block>
      <Text color="gray" fontSize="largest">
        {description || "It seems there is nothing to display!"}
      </Text>
      {onAction && (
        <Button
          icon={ctaIcon || "add"}
          justifySelf="center"
          buttonType="cta"
          onClick={(e) => onAction(e)}
        >
          {" "}
          {ctaLabel}{" "}
        </Button>
      )}
    </Block>
  );
};

const EmptyEntityFallbackListItem: FunctionComponent<
  IEmptyEntityFallbackProps & { collection: any[] }
> = ({ collection, ...props }) =>
  isEmpty(collection) ? (
    <ListItemBody>
      <ListItemRow>
        <ListItemCell>
          <EmptyEntityFallback {...props} />
        </ListItemCell>
      </ListItemRow>
    </ListItemBody>
  ) : null;

export {
  EmptyEntityFallback,
  IEmptyEntityFallbackProps,
  EmptyEntityFallbackListItem,
};
