import React, {
  useState,
  FunctionComponent,
  useContext,
  useEffect,
} from "react";
import {
  Block,
  Modal,
  Select,
  PanelHeader,
  IModalProps,
  Icon,
  Button,
  ModalTitle,
  ModalSubTitle,
  ModalDescription,
  ModalIconContainer,
  ModalTitleContainer,
  ModalCtaContainer,
  IconName,
} from "../../../seenspire-library";
import PanelForm from "../../components/PanelForm/PanelForm";
import { Formik, FormikHelpers, Field } from "formik";
import * as Yup from "yup";
import {
  Invite,
  ActiveChannelType,
  Subscription,
  Plan,
  UpgradeRequest,
  UpgradeRequest5,
  Plan5,
} from "seenspire-api-client";
import {
  formatServerValidationErrors,
  FeedTypeEnum,
  FeedType,
  isFeedType,
} from "../../util";
import InputField from "../../components/InputField/InputField";
import { AppContext } from "../../state";
import { useTranslation } from "react-i18next";
import {
  ModalButtons,
  FormModalButtons,
} from "../../components/ModalButtons/ModalButtons";
import { ISocialNetworksTypesConfig } from "../../containers/AccountSocialNetworks/AccountSocialNetworks";
import { chain, get, every } from "lodash";
import { AuthRoutNames } from "../../routes/AuthRoutes";
import { FeaturesConfig } from "../../containers/Dashboard/MethodsAndLogic";
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator";
import { Link } from "../../components/LinkButton/LinkButton";
import { HELP_URL } from "../../backend/consts";

const CONTEXT_DEFAULT_DATA: any = {
  INFOTAINMENT: {
    icon: "feature",
    title: " ",
    subTitle: "feeds.locked_unlock_infotainment_title",
    description: "feeds.locked_unlock_infotainment_description",
  },
  SOCIAL: {
    icon: "feature",
    title: " ",
    subTitle: "feeds.locked_unlock_social_title",
    description: "feeds.locked_unlock_social_description",
  },
  TEAMS: {
    icon: "feature",
    title: " ",
    subTitle: "feeds.locked_unlock_teams_title",
    description: "feeds.locked_unlock_teams_description",
  },
};

const RequestFeatureModal: FunctionComponent<IModalProps> = ({
  isOpen: ignored,
  ...props
}) => {
  const {
    client,
    authenticated,
    activated,
    theme,
    currentRoute,
    logout,
    requestFeatureModalIsOpen,
    setRequestFeatureModalIsOpen,
    goTo,
    account,
  } = useContext(AppContext);
  const { t } = useTranslation();
  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [requestedFeature, setRequestedFeature] = useState<ActiveChannelType>({
    id: 0,
    title: "",
    slug: "",
  });
  const [plans, setPlans] = useState<Plan[]>();
  const [currentSubscription, setCurrentSubscription] =
    useState<Subscription>();
  const [parentsSubscription, setParentSubscriptions] = useState();
  const [parentHasFeature, setParentHasFeature] = useState<boolean>(false);
  const [hasCurrentPendingRequest, setHasCurrentPendingRequest] =
    useState<UpgradeRequest5>();
  const [context, setContext] = useState({
    icon: "feature" as IconName,
    title: "",
    subTitle: "",
    description: "",
  });
  useEffect(() => {
    if (!requestFeatureModalIsOpen) return;
    setError(undefined);
    setLoading(true);
    loadData().then(() => setLoading(false));
  }, [requestFeatureModalIsOpen]);

  const generateCurrentPendingRequest = (
    _currentSubscription: Subscription,
    _requestedFeature: ActiveChannelType
  ) =>
    chain(_currentSubscription.upgrade_requests)
      .filter(
        (request) =>
          (request as any).custom &&
          request.status === UpgradeRequest.StatusEnum.PENDING &&
          isPlanOfType(request.plan as any, _requestedFeature)
      )
      .head()
      .value();

  const loadData = async () => {
    if (!authenticated || !activated) return;

    const promise = Promise.all([
      await client.Plans.getPlans().then((data: any) => data.data),
      await client.Subscriptions.getParentSubscriptions().then(
        (data: any) => data.data
      ),
    ]).then(([_plans, _parentsSubscription]) => {
      setPlans(_plans);
      setParentSubscriptions(_parentsSubscription);
      return [_plans, _parentsSubscription];
    });

    return promise

      .then(([_plans, _parentsSubscription]) => {
        return Promise.all([
          Promise.resolve(_plans),
          Promise.resolve(_parentsSubscription),
          client.Subscriptions.getSubscriptions().then(
            (data: any) => data.data
          ),
        ]);
      })

      .then(([_plans, _parentsSubscription, _subscription]) => {
        if (!requestFeatureModalIsOpen) return;

        const _currentSubscription = _subscription[0];
        const _requestedFeature = FeaturesConfig[requestFeatureModalIsOpen];
        const _hasCurrentPendingRequest = generateCurrentPendingRequest(
          _currentSubscription,
          _requestedFeature
        );
        const _parentHasFeature = checkParentHasFeature(
          _parentsSubscription,
          _requestedFeature
        );

        setCurrentSubscription(_currentSubscription);
        setRequestedFeature(_requestedFeature);
        setParentHasFeature(_parentHasFeature);
        setHasCurrentPendingRequest(_hasCurrentPendingRequest);

        if (_hasCurrentPendingRequest) {
          setContext({
            icon: "feature",
            title: "Featured requested",
            subTitle: `You have a pending request for ${
              _requestedFeature && _requestedFeature.title
            }`,
            description:
              "Please contact your reseller to follow up with this request.",
          });
        } else {
          setContext(CONTEXT_DEFAULT_DATA[requestFeatureModalIsOpen]);
        }
      });
  };

  const checkParentHasFeature = (
    _parentsSubscription: Subscription[],
    _requestedFeature: ActiveChannelType
  ) => {
    return chain(_parentsSubscription)
      .map("plan")
      .map("channel_types")
      .flatten()
      .map("slug")
      .includes(_requestedFeature.slug)
      .value();
  };

  const isPlanOfType = (plan: Plan, _requestedFeature: ActiveChannelType) =>
    every(
      plan.channel_types,
      (channelType) => channelType.slug === _requestedFeature.slug
    );
  const submitData = async () => {
    setError(undefined);
    if (!currentSubscription || !plans || !requestedFeature) return;
    if (parentHasFeature) {
      return goTo(`account/subscription/${requestedFeature.slug}`, true);
    }
    setLoading(true);
    const targetPlan = chain(plans)
      .filter((plan) => isPlanOfType(plan, requestedFeature))
      .head()
      .value();
    try {
      await client.UpgradeRequests.postUpgradeRequest({
        licences: currentSubscription.licences,
        subscription: { id: currentSubscription.id },
        plan: { id: targetPlan.id },
        custom: 1,
      } as any);

      await loadData();

      setContext({
        icon: "feature",
        title: "Request",
        subTitle: `A request has been sent to ${get(
          account,
          "account.parent.organization_info.name"
        )}`,
        description:
          "They will reach out to you shortly to facilitate access to this content",
      });
      setLoading(false);
    } catch (error) {
      let message = "Unknown error";
      if (error instanceof Error) {
        message = error.message;
      }
      setLoading(false);
      setContext({
        icon: "error",
        title: "Error submitting a Request",
        subTitle: "An error has occurred",
        description:
          "Unfortunately, your request for this feature is not complete, please try again or contact support",
      });
      setError(message);
    }
  };

  const cancelRequest = async () => {
    if (!hasCurrentPendingRequest) return;
    setLoading(true);
    await client.UpgradeRequests.cancelUpgradeRequest(
      String(hasCurrentPendingRequest.id)
    );
    await loadData();
    setLoading(false);
  };

  const generateCtaLabel = () => {
    switch (true) {
      case !!error:
        return "Retry";
      case !!parentHasFeature:
        return "Upgrade subscription";
      case !parentHasFeature:
        return t("feeds.locked_unlock_request_now_button");
    }
  };

  const ctaLabel = generateCtaLabel();

  return (
    <Modal
      isOpen={!!requestFeatureModalIsOpen}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => setRequestFeatureModalIsOpen(undefined)}
      theme={theme}
      {...props}
    >
      {!loading ? (
        <>
          <Block>
            <ModalIconContainer>
              <Icon iconSize="larger" name={context.icon} />
            </ModalIconContainer>

            <ModalTitleContainer>
              <ModalSubTitle>{t(context.title)}</ModalSubTitle>
              <ModalTitle>{t(context.subTitle)}</ModalTitle>
            </ModalTitleContainer>

            <ModalDescription>{t(context.description)}</ModalDescription>
          </Block>

          <ModalCtaContainer>
            <Link url={true} target="_blank" to={HELP_URL}>
              <Button elementWidth="block" buttonType="outline">
                {t("feeds.locked_unlock_more_info_button")}
              </Button>
            </Link>
            {!hasCurrentPendingRequest ? (
              <Button
                elementWidth="block"
                onClick={() => submitData()}
                buttonType="cta"
              >
                {ctaLabel}
              </Button>
            ) : (
              <Button
                elementWidth="block"
                onClick={() => cancelRequest()}
                buttonType="alert"
              >
                Cancel Request
              </Button>
            )}
          </ModalCtaContainer>
        </>
      ) : (
        <Block>
          <LoadingIndicator bg="transparent" loading={true} />
        </Block>
      )}
    </Modal>
  );
};

export { RequestFeatureModal };
