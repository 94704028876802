import React from "react";
import { RouteConfig } from ".";

const ContentPartnerDashboard = React.lazy(
  () => import("../containers/ContentPartnerDashboard")
);
const ContentPartnerChannels = React.lazy(
  () => import("../containers/ContentPartnerChannels")
);
const ContentPartnerReports = React.lazy(
  () => import("../containers/ContentPartnerReports")
);

export type ContentPartnerRoutesNames =
  | "content_partner_dashboard"
  | "content_partner_channels"
  | "content_partner_reports";

const PartnerRoutes: RouteConfig[] = [
  {
    name: "content_partner_dashboard",
    label: "Dashboard",
    path: "/:slug/content-partner/dashboard",
    restrictOn: ["Client", "Distributor", "Reseller"],
    requiredUserRoles: ["ROLE_OWNER"],
    component: ContentPartnerDashboard,
  },
  {
    name: "content_partner_channels",
    label: "Channels",
    path: "/:slug/content-partner/channels",
    restrictOn: ["Client", "Distributor", "Reseller"],
    requiredUserRoles: ["ROLE_OWNER"],
    component: ContentPartnerChannels,
  },
  {
    name: "content_partner_reports",
    label: "Reconciliation reports",
    path: "/:slug/content-partner/reports",
    restrictOn: ["Client", "Distributor", "Reseller"],
    requiredUserRoles: ["ROLE_OWNER"],
    component: ContentPartnerReports,
  },
  {
    name: "redirect",
    label: "",
    path: "/:slug/partner/home",
    redirect: true,
    component: () => <div></div>,
  },
];

export default PartnerRoutes;
