import {
  ChannelCategory2 as _ChannelCategory,
  Channel2 as _Channel,
  ActiveChannel as _ActiveChannel,
  Body2 as _Body,
  AccountUserRoleMe as _AccountUserRoleMe,
  Account8 as _AccountUserRoleMeAccount,
  DisplaySettings,
  PresentationDesign2,
  Cta4 as _Cta,
  ActiveItem,
} from "seenspire-api-client";
import { IconName } from "../../seenspire-library";

export interface Channel extends _Channel, _ActiveChannel {
  media: any;
  placeholder?: boolean;
  default_display_settings: any;
}
export interface ChannelCategory extends _ChannelCategory {
  channels: Channel[];
}

export interface IPaginatedData<T = any> {
  data: T[];
  meta: Meta;
}

/**
 *
 * @export
 * @interface Meta
 */
export interface Meta {
  /**
   *
   * @type {number}
   * @memberof Meta
   */
  last: number;
  /**
   *
   * @type {number}
   * @memberof Meta
   */
  current: number;
  /**
   *
   * @type {number}
   * @memberof Meta
   */
  numItemsPerPage: number;
  /**
   *
   * @type {number}
   * @memberof Meta
   */
  first: number;
  /**
   *
   * @type {number}
   * @memberof Meta
   */
  pageCount: number;
  /**
   *
   * @type {number}
   * @memberof Meta
   */
  totalCount: number;
  /**
   *
   * @type {number}
   * @memberof Meta
   */
  pageRange: number;
  /**
   *
   * @type {number}
   * @memberof Meta
   */
  startPage: number;
  /**
   *
   * @type {number}
   * @memberof Meta
   */
  endPage: number;
  /**
   *
   * @type {Array<number>}
   * @memberof Meta
   */
  pagesInRange: Array<number>;
  /**
   *
   * @type {number}
   * @memberof Meta
   */
  firstPageInRange: number;
  /**
   *
   * @type {number}
   * @memberof Meta
   */
  lastPageInRange: number;
  /**
   *
   * @type {number}
   * @memberof Meta
   */
  currentItemCount: number;
  /**
   *
   * @type {number}
   * @memberof Meta
   */
  firstItemNumber: number;
  /**
   *
   * @type {number}
   * @memberof Meta
   */
  lastItemNumber: number;

  previous: number;
  next: number;
}

export type AccountType =
  | "Master"
  | "Reseller"
  | "Distributor"
  | "ContentPartner"
  | "Referral"
  | "Lead"
  | "Client";
export type AccountTypeNoMaster =
  | "Reseller"
  | "Distributor"
  | "ContentPartner"
  | "Referral"
  | "Lead"
  | "Client";

export enum AccountTypeEnum {
  "master" = "Master",
  "reseller" = "Reseller",
  "distributor" = "Distributor",
  "contentPartner" = "ContentPartner",
  "referral" = "Referral",
  "lead" = "Lead",
  "client" = "Client",
}

export interface ILoginData {
  email: string;
  password: string;
  persistSession?: boolean;
}

export interface IRegisterData {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  acceptTermsAndConditions: boolean;
}

export enum CustomChannelSourceTypeEnum {
  TWITTERACCOUNT = "TWITTERACCOUNT",
  TWITTERTAG = "TWITTERTAG",
  INSTACCOUNT = "INSTACCOUNT",
  FACEBOOKPAGE = "FACEBOOKPAGE",
  LINKEDINPAGE = "LINKEDINPAGE",
  WEBEXROOM = "WEBEXROOM",
  INSTAGRAMBUSINESSTAG = "INSTAGRAMBUSINESSTAG",
  INSTAGRAMBUSINESSBUSINESS = "INSTAGRAMBUSINESSBUSINESS",
  WORKPLACEGROUP = "WORKPLACEGROUP",
  INSTAGRAMBUSINESSMENTION = "INSTAGRAMBUSINESSMENTION",
  MICROSOFTTEAMSCHANNEL = "MICROSOFTTEAMSCHANNEL",
  YAMMERCHANNEL = "YAMMERCHANNEL",
  SLACKCHANNEL = "SLACKCHANNEL",
  // ZOOMROOM = "ZOOMROOM",
}

export enum CustomChannelSourceItemTypeEnum {
  TwitterPost = "TwitterPost",
  FacebookPost = "FacebookPost",
  InstaPost = "InstaPost",
  LinkedinPost = "LinkedinPost",
  WebexPost = "WebexPost",
  InstagramBusinessPost = "InstagramBusinessPost",
  WorkplacePost = "WorkplacePost",
  //SlackPost = 'SlackPost',
}

export enum CustomChannelCustomFieldsEnum {
  "screen_name" = "screen_name",
  "account_name" = "account_name",
  "tag" = "tag",
  "retweets" = "retweets",
  "replies" = "replies",
  "page_id" = "page_id",
  "pages" = "pages",
  "pagesearch" = "pagesearch",
  "company_id" = "company_id",
  "room_id" = "room_id",
  "group_id" = "group_id",
  "instagram_business_account_id" = "instagram_business_account_id",
  "instagram_business_discovery" = "instagram_business_discovery",
  "hashtag_id" = "hashtag_id",
  "hashtag" = "hashtag",
  "business_name" = "business_name",
  "team_id" = "team_id",
  "team_name" = "team_name",
  "channel_id" = "channel_id",
  "channel_name" = "channel_name",
  "source_account_id" = "source_account_id",
}

export interface Source {
  source_account_id: string;
  full_name: string;
  user_name?: string;
  networked_active_channel: number;
  type: string;
  curation_type: string;
  network_connection: number;
  hide_older_than: string;
  custom_fields: any;
}

export interface IFeedType {
  id: CustomChannelCustomFieldsEnum;
  queryResultId?: string;
  queryResultExtraId?: string;
  icon: IconName;
  label: string;
  secondaryLabel?: string;
  option: CustomChannelCustomFieldsEnum;
  type: CustomChannelSourceTypeEnum;
  static?: boolean;
  query?: string;
  customFieldsKeys?: { [key: string]: any };
}

export interface IFeedTypeOptions {
  id: string;
  name: string;
  avatar?: string;
  verification_status?: string;
  link?: string;
  screen_name?: string;
  fullName?: string;
  user_name?: string;
  full_name?: string;
  page_name?: string;
  group_name?: string;
  room_name?: string;
  team_id?: string;
  team_name?: string;
  channel_id?: string;
  channel_name?: string;
  handle_id?: string | number;
}

export interface IChannelData
  extends Pick<_Body, Exclude<keyof _Body, "custom_fields">> {
  custom_fields: any;
}

export interface AccountUserRoleMeAccount extends _AccountUserRoleMeAccount {}
export interface AccountUserRoleMe extends _AccountUserRoleMe {}

export enum UpgradeRequestStatus {
  "APPROVED" = "APPROVED",
  "PENDING" = "PENDING",
}

export interface ActiveChannel extends _ActiveChannel {}

export interface PresentationDesign extends PresentationDesign2 {}

export type PresentationDesignTypes =
  | "UNO"
  | "GRID"
  | "TEAMS"
  | "SOCIALCANVAS"
  | "DAILYNEWS"
  | "BIRTHDAYS"
  | "CURRENCIES"
  | "DESTINATIONS"
  | "HISTORY"
  | "VIDEOS"
  | "FASHION"
  | "HEALTH"
  | "HOLLYWOOD"
  | "HOROSCOPE"
  | "QUOTES"
  | "TRIVIA"
  | "SPORTS"
  | "TRAFFIC"
  | "STOCKS"
  | "FINANCIAL"
  | "WEATHER_FORECAST"
  | WeathermapTypes;

export type WeathermapTypes =
  | "WEATHERMAP_NATIONAL"
  | "WEATHERMAP_TEMPERATURE"
  | "WEATHERMAP_PRECIPITATION"
  | "WEATHERMAP_WIND"
  | "WEATHERMAP_PRESSURE"
  | "WEATHERMAP_CLOUD";

export enum PresentationDesignEnum {
  "UNO" = "UNO",
  "GRID" = "GRID",
  "TEAMS" = "TEAMS",
  "SOCIALCANVAS" = "SOCIALCANVAS",
  "DAILYNEWS" = "DAILYNEWS",
  "BIRTHDAYS" = "BIRTHDAYS",
  "CURRENCIES" = "CURRENCIES",
  "DESTINATIONS" = "DESTINATIONS",
  "HISTORY" = "HISTORY",
  "VIDEOS" = "VIDEOS",
  "FASHION" = "FASHION",
  "HEALTH" = "HEALTH",
  "HOLLYWOOD" = "HOLLYWOOD",
  "HOROSCOPE" = "HOROSCOPE",
  "QUOTES" = "QUOTES",
  "TRIVIA" = "TRIVIA",
  "SPORTS" = "SPORTS",
  "TRAFFIC" = "TRAFFIC",
  "STOCKS" = "STOCKS",
  "FINANCIAL" = "FINANCIAL",
  "WEATHER_FORECAST" = "WEATHER_FORECAST",
  "WEATHERMAP_NATIONAL" = "WEATHERMAP_NATIONAL",
  "WEATHERMAP_TEMPERATURE" = "WEATHERMAP_TEMPERATURE",
  "WEATHERMAP_PRECIPITATION" = "WEATHERMAP_PRECIPITATION",
  "WEATHERMAP_WIND" = "WEATHERMAP_WIND",
  "WEATHERMAP_PRESSURE" = "WEATHERMAP_PRESSURE",
  "WEATHERMAP_CLOUD" = "WEATHERMAP_CLOUD",
}

export const NEOS_PRESENTATION_DESIGNS = [
  PresentationDesignEnum.DAILYNEWS,
  PresentationDesignEnum.BIRTHDAYS,
  PresentationDesignEnum.CURRENCIES,
  PresentationDesignEnum.DESTINATIONS,
  PresentationDesignEnum.HISTORY,
  PresentationDesignEnum.VIDEOS,
  PresentationDesignEnum.FASHION,
  PresentationDesignEnum.HEALTH,
  PresentationDesignEnum.HOLLYWOOD,
  PresentationDesignEnum.HOROSCOPE,
  PresentationDesignEnum.QUOTES,
  PresentationDesignEnum.TRIVIA,
  PresentationDesignEnum.SPORTS,
  PresentationDesignEnum.TRAFFIC,
  PresentationDesignEnum.STOCKS,
  PresentationDesignEnum.FINANCIAL,
  PresentationDesignEnum.WEATHER_FORECAST,
  PresentationDesignEnum.WEATHERMAP_NATIONAL,
  PresentationDesignEnum.WEATHERMAP_TEMPERATURE,
  PresentationDesignEnum.WEATHERMAP_PRECIPITATION,
  PresentationDesignEnum.WEATHERMAP_WIND,
  PresentationDesignEnum.WEATHERMAP_PRESSURE,
  PresentationDesignEnum.WEATHERMAP_CLOUD,
];

export interface DisplaySettingsKeys extends DisplaySettings {}

export interface FormDisplaySettingsKeys
  extends Omit<DisplaySettingsKeys, "size"> {
  postSize: string;
  postFrequency: string;
}

export type DisplaySettingsTemplate = (keyof DisplaySettingsKeys)[];

export type FormDisplaySettingsTemplate = (keyof FormDisplaySettingsKeys)[];

export interface Cta extends Pick<_Cta, Exclude<keyof _Cta, "active_channel">> {
  type: string;
  active_channel: { id: string };
}

export enum CtaTypeEnum {
  "follow" = "follow",
  "custom" = "custom",
  "hashtag" = "hashtag",
}

export enum ItemMediaTypeEnum {
  VIDEO_MP4 = "video/mp4",
  VIDEO = "video/mpeg",
  IMAGE = "image",
  TEXT = "text",
}
export interface EnrichedActiveItem extends ActiveItem {
  media_type: ItemMediaTypeEnum;
}

export type UserRoles =
  | "ROLE_CURATOR"
  | "ROLE_MANAGER"
  | "ROLE_OWNER"
  | "ROLE_PARTNER_PORTAL_MANAGER";
export enum UserRolesEnum {
  "ROLE_CURATOR" = "ROLE_CURATOR",
  "ROLE_MANAGER" = "ROLE_MANAGER",
  "ROLE_OWNER" = "ROLE_OWNER",
  "ROLE_PARTNER_PORTAL_MANAGER" = "ROLE_PARTNER_PORTAL_MANAGER",
}

export enum SeenspireConnectMessageActionEnums {
  "SSC_AUTHENTICATE" = "SSC_AUTHENTICATE",
  "SSC_STORE_TOKEN" = "SSC_STORE_TOKEN",
  "SSC_ORIGIN_INIT" = "SSC_ORIGIN_INIT",
  "SSC_SOURCE_ERROR_OCCURRED" = "SSC_SOURCE_ERROR_OCCURRED",
  "SSC_SOURCE_OPERATION_DONE" = "SSC_SOURCE_OPERATION_DONE",
  "SSC_SOURCE_OPERATION_NOTIFY_UPDATED" = "SSC_SOURCE_OPERATION_NOTIFY_UPDATED",
}
export interface ISeenspireConnectMessage {
  action: SeenspireConnectMessageActionEnums;
  payload: any;
}

export type AppDisplayModeType = "STANDARD" | "SEENSPIRE_CONNECT";

export interface IPostFeedData {
  feedUrl: string;
  code: string;
  slug: string;
}

export interface ISeenspireConnectConfig {
  active: boolean;
  code: string | null;
  token: string | null;
  source: any; //Window | MessagePort | ServiceWorker | null,
  origin: any;
  customData: { [key: string]: any };
  postFeedData: (feedData: IPostFeedData) => void;
  postMessage: (message: ISeenspireConnectMessage) => void;
  notifyUpdated: () => void;
}
