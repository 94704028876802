import React, {
  useState,
  FunctionComponent,
  useContext,
  useEffect,
} from "react";
import {
  Block,
  Modal,
  IModalProps,
  styled,
  Button,
  Icon,
} from "../../../seenspire-library";
import { AppContext } from "../../state";
import Preview, { IPreviewConfig } from "../../components/Preview/Preview";
import FauxPreview from "../../components/Preview/FauxPreview";
import { get } from "lodash";
import { AspectRatioBox } from "../../containers/Dashboard/Styles";
import {
  copyToClipboard,
  generateFeedUrl,
} from "../../containers/ChannelUrl/ChannelUrl";
import {
  getDefaultSource,
  getDefaultRegion,
  getDefaultLanguage,
} from "../../containers/Dashboard/SliderItemOnInteractLogic";

export const PreviewContainer = styled(Block)`
  min-width: 80vw;
`;

export const ButtonsContainer = styled(Block)`
  position: absolute;
  top: 0;
  right: -60px;
  display: grid;
  grid-gap: 10px;
`;

export const PreviewButton = styled(Button)`
  height: 50px;
  width: 50px;
  min-width: 50px;
  min-height: 50px;
`;

const CopyButtonLabel = styled(Block)`
  font-size: 8px;
  position: absolute;
  bottom: 7px;
  width: 100%;
`;

const PreviewInnerContainer = styled(Block)`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;

const PreviewModal: FunctionComponent<
  IModalProps & {
    channel?: any;
    channelType: "active" | "open";
    data?: any;
    hideUrl?: boolean;
  }
> = ({
  channel,
  channelType,
  onSubmit,
  onCancel,
  data = {},
  hideUrl,
  ...props
}) => {
  const { theme, account } = useContext(AppContext);
  const [config, setConfig] = useState<IPreviewConfig>();
  const [copied, setCopied] = useState<boolean>();

  const loadData = () => {
    if (!channel) return;

    if (channelType === "open" && !channel.precurated_template) {
      const id = channel.id;
      const source = channel.available_sources
        ? getDefaultSource(channel.available_sources)
        : null;
      const region = source ? getDefaultRegion(source.available_regions) : null;

      const language = region
        ? getDefaultLanguage(region.available_languages)
        : null;
      const accountId = get(account, "account.id");

      let _config: IPreviewConfig = { id, preview: true };

      if (source) _config.sourceId = String(source.source.id);
      if (region) _config.regionId = String(region.region.id);
      if (language) _config.languageId = String(language.language.id);
      if (accountId) _config.accountId = String(accountId);
      setConfig(_config);
      return;
    }

    if (channelType === "active" || channel.precurated_template) {
      setConfig({ id: channel.code, preview: true, ...data });
    }
  };

  useEffect(() => {
    if (!channel) return;
    loadData();
    setCopied(undefined);
  }, [channel]);

  const PreviewComp =
    channel && channel.slug === "public_transport" ? FauxPreview : Preview;

  // const previewData = {
  //   display_settings: {
  //     "color": "#f47373",
  //     "font": "Courier New",
  //     "font_path": "assets/fonts",
  //     "secondary_color": "#ba68c8",
  //   }
  // };

  return (
    <Modal
      skipSizing
      skipContentPadding
      customStyle={{
        content: { overflow: "unset", background: "transparent" },
      }}
      onRequestClose={() => onCancel()}
      theme={theme}
      {...props}
    >
      <PreviewContainer display="grid" gridGap={20}>
        {config && (
          <>
            <ButtonsContainer>
              <PreviewButton onClick={() => onCancel()} buttonType="outline">
                <Icon iconSize="small" name="cross" />
              </PreviewButton>
              {channelType === "active" && !hideUrl && (
                <PreviewButton
                  onClick={() =>
                    setCopied(copyToClipboard(generateFeedUrl(config.id)))
                  }
                  buttonType={copied ? "success" : "outline"}
                >
                  {copied ? (
                    <>
                      <Icon name="checkDark" margin={{ bottom: 6 }} />
                      <CopyButtonLabel>Copied!</CopyButtonLabel>
                    </>
                  ) : (
                    <Icon name="url" />
                  )}
                </PreviewButton>
              )}
            </ButtonsContainer>
            <PreviewInnerContainer>
              <PreviewComp
                config={config}
                onSubmit={null}
                onCancel={() => {}}
              />
            </PreviewInnerContainer>
          </>
        )}
        <AspectRatioBox />
      </PreviewContainer>
    </Modal>
  );
};

export { PreviewModal };
