import React, {
  FunctionComponent,
  useRef,
  ReactComponentElement,
  useState,
  useEffect,
  useContext,
} from "react";
import usePortal from "react-useportal";
import {
  styled,
  Block,
  BorderRadiusBase,
  TransitionBase,
  Button,
  hsla,
  IBlockProps,
} from "../../../seenspire-library";
import useOnClickOutside from "use-onclickoutside";
import { useWindowSize } from "react-use";
import { AppContext } from "../../state";
import { useScrollYPosition } from "../../util";

const MenuHeader = styled(Block)`
  display: flex;
  align-items: center;
  min-height: 60px;
  padding: 6px;
  flex-grow: 2;
`;

const MenuSection = styled(Block).attrs({})<{
  borderTop?: boolean;
  limitedHeight?: boolean;
}>`
  display: grid;
  padding: ${({ padding }) => !padding && "16px"};
  border-top: ${({ theme, borderTop }) =>
    borderTop && `solid 1px ${theme.colors.gray.light}`};
  max-height: ${({ limitedHeight }) => limitedHeight && "180px"};
  overflow: hidden;
  overflow-y: auto;
`;

const MenuButton = styled(Button).attrs({ buttonType: "transparent" })<{
  withIcon?: boolean;
  disabled?: boolean;
  selected?: boolean;
}>`
  color: ${({ theme }) => theme.colors.text.lighter};
  justify-content: flex-start;
  font-weight: 400;
  padding: ${({ withIcon }) => (withIcon ? "0" : "5px")};
  width: 100%;
  min-height: auto;
  background-color: ${({ theme, selected }) =>
    !selected ? "transparent" : hsla(theme.colors.gray.lightest, 0.5)};
  font-size: ${({ theme, fontSize }) => fontSize || theme.fontSize.large};
  :hover {
    background-color: ${({ theme, disabled }) =>
      !disabled && theme.colors.gray.lightest};
  }
`;

const MenuStyled = styled(Block)`
  /* border: solid 1px ${({ theme }) => theme.colors.gray.lighter}; */
  box-shadow: ${({ theme }) => theme.shadows.dark};
  ${TransitionBase};
  ${BorderRadiusBase};
`;
const MenuStyledFixed = styled(MenuStyled).attrs({})<{
  visible: boolean;
  manualWidth?: boolean;
  skipMenuBackgroundColor?: boolean;
}>`
  position: absolute;
  min-width: ${({ manualWidth }) => !manualWidth && "260px"};
  transform: ${({ visible }) =>
    visible ? "translateY(0)" : "translateY(-15px)"};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
  pointer-events: ${({ visible }) => (visible ? "unset" : "none")};
  z-index: ${({ theme }) => theme.header.zIndex + 100};
  background-color: ${({ theme, skipMenuBackgroundColor }) =>
    skipMenuBackgroundColor ? "transparent" : theme.colors.white.base};
`;

const MenuOverlay = styled(Block).attrs({})<{
  visible: boolean;
  lightOverlay?: boolean;
}>`
  position: ${({ position }) => position || "fixed"};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ theme }) => theme.header.zIndex + 98};
  background-color: ${({ theme, lightOverlay }) =>
    hsla(
      lightOverlay ? theme.colors.white.base : theme.colors.primary.darkest,
      0.7
    )};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
  ${TransitionBase};
`;

const MenuSectionTitle = styled(Block)`
  color: ${({ theme }) => theme.colors.gray.dark};
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
`;

const Menu: FunctionComponent<
  IBlockProps & {
    menuButton: ReactComponentElement<any>;
    adaptiveWidth?: boolean;
    menuWidth?: string;
    manualWidth?: boolean;
    widthAdjustment?: string;
    sideShift?: number;
    overlay?: boolean;
    lightOverlay?: boolean;
    bottomSpacing?: number;
    disableCloseOnClickOutside?: boolean;
    scrollToButton?: boolean;
    adjustments?: { top?: number; right?: number; left?: number };
    ignoreButtonWidth?: boolean;
    skipMenuBackgroundColor?: boolean;
    openDirection?: "up" | "down";
    withPortal?: boolean;
    onMenuOpen?: (isOpen: boolean) => void;
    disableCloseOnScroll?: boolean;
  }
> = function ({
  children,
  menuButton,
  adaptiveWidth,
  overlay,
  disableCloseOnClickOutside,
  sideShift = 0,
  bottomSpacing = 5,
  lightOverlay,
  onMenuOpen,
  scrollToButton,
  adjustments,
  manualWidth,
  ignoreButtonWidth,
  widthAdjustment,
  menuWidth,
  skipMenuBackgroundColor,
  openDirection,
  withPortal,
  disableCloseOnScroll,
  ...props
}) {
  const { theme } = useContext(AppContext);
  const ref = useRef<HTMLElement>(null);
  const [buttonRef, setButtonRef] = useState<HTMLElement>();
  const [visible, setVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState<any>();
  const { width: containerWidth } = useWindowSize();
  const { openPortal, closePortal, isOpen, Portal } = usePortal();

  const scrollY = useScrollYPosition();

  useEffect(() => {
    if (!buttonRef) return;

    !disableCloseOnScroll && close();

    // @TODO fix menu position on window resize
    calculateAndSetMenuPosition(buttonRef);
  }, [containerWidth, scrollY]);

  useEffect(() => {
    if (!visible || !buttonRef) return;
    setTimeout(() => {
      const { top, height } = buttonRef.getBoundingClientRect();
      scrollToButton &&
        window.scrollTo({
          top: top + window.pageYOffset - height - bottomSpacing,
          behavior: "smooth",
        });
    }, 280);
  }, [visible]);

  const close = () => {
    setVisible(false);
    onMenuOpen && onMenuOpen(false);
  };

  const toggleMenu = ({ currentTarget }: { currentTarget: HTMLElement }) => {
    if (!buttonRef) {
      setButtonRef(currentTarget);
      calculateAndSetMenuPosition(currentTarget);
    }
    setVisible(!visible);
    onMenuOpen && onMenuOpen(!visible);
  };

  const calculateAndSetMenuPosition = (currentTarget: HTMLElement) => {
    const clientRect = currentTarget.getBoundingClientRect();

    const { height, width, top, right, left } = clientRect;

    const openUp =
      openDirection === "up" || (top + 100 > window.innerHeight ? true : false);

    setMenuPosition({
      // @TODO remove magic number
      width: !adaptiveWidth
        ? manualWidth
          ? menuWidth
          : width
        : `calc(100vw - 8vw - ${widthAdjustment || 0})`,
      top: !openUp
        ? withPortal
          ? top +
            height +
            bottomSpacing +
            ((adjustments && adjustments.top) || 0)
          : height + bottomSpacing + ((adjustments && adjustments.top) || 0)
        : "auto",
      bottom: openUp ? height + bottomSpacing : "auto",
      right: withPortal
        ? window.innerWidth -
          (left + width - ((adjustments && adjustments.right) || 0))
        : +((adjustments && adjustments.right) || 0),
      position: withPortal && "fixed",
      zIndex: 10000000,
      // left : adaptiveWidth && - ((containerWidth - right))
    });
  };

  useOnClickOutside(ref, (e: any) => {
    if (!visible || !buttonRef) return;
    const buttonClicked = e.composedPath().includes(buttonRef);
    !buttonClicked && close();
  });

  const buttonProps = {
    ...menuButton.props,
    isVisible: visible,
    onClick: menuButton.props.onClick || toggleMenu,
    style: {
      position: visible && "relative",
      zIndex: visible && theme.header.zIndex + 100,
    },
  };

  const content = (
    <MenuStyledFixed
      visible={visible}
      manualWidth={manualWidth}
      skipMenuBackgroundColor={skipMenuBackgroundColor}
      onClick={disableCloseOnClickOutside ? () => {} : close}
      style={menuPosition}
      ref={ref as React.MutableRefObject<HTMLInputElement>}
    >
      {children}
    </MenuStyledFixed>
  );
  return (
    <>
      <Block
        style={{
          position: "relative",
          zIndex: visible ? theme.header.zIndex + 100 : "unset",
        }}
        {...props}
      >
        {/* Button */}
        {React.cloneElement(menuButton, buttonProps)}

        {/* Menu Content */}
        {withPortal ? <Portal>{content}</Portal> : content}

        {/* Overlay */}
        {overlay && (
          <MenuOverlay visible={visible} lightOverlay={lightOverlay} />
        )}
      </Block>
    </>
  );
};

export {
  Menu,
  MenuStyled,
  MenuHeader,
  MenuSection,
  MenuButton,
  MenuOverlay,
  MenuSectionTitle,
};
