import { useCallback } from 'react';

function parseUserAgent(ua: string) {
  let browserName = "Unknown";
  let browserVersion = "Unknown";
  let osName = "Unknown";
  let osVersion = "Unknown";
  let deviceType = "Desktop";

  // Detect browser name and version
  if (ua.indexOf("Opera") != -1 || ua.indexOf("OPR") != -1) {
    browserName = "Opera";
  } else if (ua.indexOf("Edg") != -1) {
    browserName = "Microsoft Edge";
  } else if (ua.indexOf("Chrome") != -1) {
    browserName = "Chrome";
  } else if (ua.indexOf("Safari") != -1) {
    browserName = "Safari";
  } else if (ua.indexOf("Firefox") != -1) {
    browserName = "Firefox";
  } else if (ua.indexOf("MSIE") != -1 || ua.indexOf("Trident/") != -1) {
    browserName = "Internet Explorer";
  }

  // Extract browser version
  const versionMatch = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (versionMatch.length > 2) {
    browserVersion = versionMatch[2];
  }

  // Detect OS
  if (ua.indexOf("Win") != -1) {
    osName = "Windows";
  } else if (ua.indexOf("Mac") != -1) {
    osName = "macOS";
  } else if (ua.indexOf("Linux") != -1) {
    osName = "Linux";
  } else if (ua.indexOf("Android") != -1) {
    osName = "Android";
  } else if (ua.indexOf("iOS") != -1) {
    osName = "iOS";
  }

  // Detect OS version (limited)
  if (osName === "Windows") {
    const windowsVersionMatch = ua.match(/Windows NT (\d+\.\d+)/);
    if (windowsVersionMatch) osVersion = windowsVersionMatch[1];
  } else if (osName === "macOS") {
    const macVersionMatch = ua.match(/Mac OS X (\d+[._]\d+)/);
    if (macVersionMatch) osVersion = macVersionMatch[1].replace("_", ".");
  } else if (osName === "Android") {
    const androidVersionMatch = ua.match(/Android (\d+(\.\d+)?)/);
    if (androidVersionMatch) osVersion = androidVersionMatch[1];
  } else if (osName === "iOS") {
    const iosVersionMatch = ua.match(/OS (\d+_\d+)/);
    if (iosVersionMatch) osVersion = iosVersionMatch[1].replace("_", ".");
  }

  // Detect device type
  if (/Mobi|Android/i.test(ua)) {
    deviceType = "Mobile";
  } else if (/Tablet|iPad/i.test(ua)) {
    deviceType = "Tablet";
  }

  return {
    browser: browserName,
    browserVersion: browserVersion,
    os: osName,
    osVersion: osVersion,
    device: deviceType
  };
}

export function useUserAgent() {
  const parse = useCallback((ua) => {

    if (!ua) {
      return {
        browser: null,
        browserVersion: null,
        os: null,
        osVersion: null,
        device: null
      };
    }

    return parseUserAgent(ua);
  }, []);

  return {
    parse
  };
}