import React from "react";
import { RouteConfig } from ".";

const PartnerHome = React.lazy(() => import("../containers/PartnerHome"));
const PartnerLeads = React.lazy(() => import("../containers/PartnerLeads"));
const PartnerPlans = React.lazy(() => import("../containers/PartnerPlans"));
const PartnerResources = React.lazy(
  () => import("../containers/PartnerResources")
);
const PartnerSalesPartners = React.lazy(
  () => import("../containers/PartnerSalesPartners")
);
const PartnerLicenseRequests = React.lazy(
  () => import("../containers/PartnerLicenseRequests")
);
const PartnerAdmin = React.lazy(() => import("../containers/PartnerAdmin"));
const PartnerTracking = React.lazy(
  () => import("../containers/PartnerTracking")
);
const PartnerFeedsTracking = React.lazy(
  () => import("../containers/PartnerFeedsTracking")
);
const PartnerUsageTracking = React.lazy(
  () => import("../containers/PartnerUsageTracking")
);
const PartnerContentPartners = React.lazy(
  () => import("../containers/PartnerContentPartners")
);
const PartnerAdminMonitor = React.lazy(
  () => import("../containers/PartnerAdminMonitor")
);

export type PartnerRoutesNames =
  | "partner_home"
  | "partner_leads"
  | "partner_sales_partners"
  | "partner_content_partners"
  | "partner_license_requests"
  | "partner_plans"
  | "partner_resources"
  | "partner_usage_account_tracking"
  | "partner_usage_account_feed_tracking"
  | "partner_feeds_tracking"
  | "partner_tracking"
  | "partner_admin"
  | "partner_admin_monitor";

const PartnerRoutes: RouteConfig[] = [
  {
    name: "partner_home",
    label: "Home",
    path: "/:slug/partner/home",
    requiredUserRoles: ["ROLE_PARTNER_PORTAL_MANAGER"],
    component: PartnerHome,
  },
  {
    name: "partner_sales_partners",
    label: "Sales partners",
    path: "/:slug/partner/sales-partners",
    requiredUserRoles: ["ROLE_PARTNER_PORTAL_MANAGER"],
    component: PartnerSalesPartners,
    restrictOn: ["Reseller"],
  },
  {
    name: "partner_leads",
    label: "Leads",
    path: "/:slug/partner/leads",
    requiredUserRoles: ["ROLE_PARTNER_PORTAL_MANAGER"],
    component: PartnerLeads,
    restrictOn: ["ContentPartner"],
  },
  {
    name: "partner_plans",
    label: "Plans",
    path: "/:slug/partner/plans",
    requiredUserRoles: ["ROLE_PARTNER_PORTAL_MANAGER"],
    component: PartnerPlans,
  },
  // {
  //   name: 'partner_content_partners',
  //   label: 'Content partners',
  //   path: '/:slug/partner/content-partners',
  //   requiredUserRoles: ['ROLE_PARTNER_PORTAL_MANAGER'],
  //   component: PartnerContentPartners,
  //   restrictOn: ['Distributor','Reseller']
  // },
  {
    name: "partner_license_requests",
    label: "License requests",
    path: "/:slug/partner/license-requests",
    requiredUserRoles: ["ROLE_PARTNER_PORTAL_MANAGER"],
    component: PartnerLicenseRequests,
  },
  {
    name: "partner_admin",
    label: "Admin",
    path: "/:slug/partner/admin",
    restrictOn: ["Distributor", "Reseller"],
    requiredUserRoles: ["ROLE_PARTNER_PORTAL_MANAGER"],
    hideFromMenu: true,
    component: PartnerAdmin,
  },
  // {
  //   name: "partner_admin_monitor",
  //   label: "Licenses Monitor",
  //   path: "/:slug/partner/admin-monitor",
  //   restrictOn: ["Distributor", "Reseller"],
  //   requiredUserRoles: ["ROLE_PARTNER_PORTAL_MANAGER"],
  //   hideFromMenu: false,
  //   component: PartnerAdminMonitor,
  // },
  {
    name: "partner_feeds_tracking",
    label: "Admin Feeds Tracking",
    path: "/:slug/partner/feeds-tracking",
    restrictOn: ["Distributor", "Reseller"],
    requiredUserRoles: ["ROLE_PARTNER_PORTAL_MANAGER"],
    hideFromMenu: true,
    component: PartnerFeedsTracking,
  },
  {
    name: "partner_tracking",
    label: "Admin Tracking",
    path: "/:slug/partner/tracking",
    restrictOn: ["Distributor", "Reseller"],
    requiredUserRoles: ["ROLE_PARTNER_PORTAL_MANAGER"],
    hideFromMenu: true,
    component: PartnerTracking,
  },
  {
    name: "partner_usage_account_tracking",
    label: "Accounts Usage Tracking",
    path: "/:slug/partner/usage/:timestamp",
    restrictOn: ["Distributor", "Reseller"],
    requiredUserRoles: ["ROLE_PARTNER_PORTAL_MANAGER"],
    hideFromMenu: true,
    component: PartnerUsageTracking,
  },
  {
    name: "partner_usage_account_feed_tracking",
    label: "Feeds Usage Tracking",
    path: "/:slug/partner/usage/:accountSlug/:timestamp",
    restrictOn: ["Distributor", "Reseller"],
    requiredUserRoles: ["ROLE_PARTNER_PORTAL_MANAGER"],
    hideFromMenu: true,
    component: PartnerUsageTracking,
  },
  // {
  //   name: 'partner_resources',
  //   label: 'Resources',
  //   path: '/:slug/partner/resources',
  //   requiredUserRoles: ['ROLE_PARTNER_PORTAL_MANAGER'],
  //   component: PartnerResources,
  //   restrictOn: ['Master']
  // },
  {
    name: "redirect",
    label: "",
    path: "/:slug/partner/home",
    redirect: true,
    component: () => <div></div>,
  },
];

export default PartnerRoutes;
