import * as React from "react";
import { FunctionComponent } from "react";
import { styled, hsla, IResponsive } from "../../styled-components";
import {
  TableCell,
  TableRow,
  TableBody,
  Table,
  ITableProps,
  ITableRowProps,
} from "../Table/Table";
import { Block } from "../Block/Block";

const ListItemCell = styled(TableCell)``;

const ListItemRow = styled(TableRow)<ITableRowProps>``;

const ListItemBody = styled(TableBody)`
  ${ListItemRow} {
    &:last-child {
      ${ListItemCell} {
        border-bottom: 1px solid ${({ theme }) => theme.colors.gray.lighter};
      }
    }
  }
  &:nth-child(even) {
    ${ListItemRow} {
      ${ListItemCell} {
        background-color: ${({ theme }) =>
          hsla(theme.colors.gray.lightest, 0.2)};
      }
    }
  }
  &:last-child {
    ${ListItemRow} {
      &:last-child {
        ${ListItemCell} {
          border-bottom-color: transparent;
        }
      }
    }
  }
`;

interface IListProps extends ITableProps {
  condense?: IResponsive<number>;
}

const List = styled(Table).attrs({})<IListProps>`
  ${ListItemCell} {
    height: ${({ condense }) => (condense ? `${condense}px` : "90px")};
  }
`;

const ListItemContent: FunctionComponent<{
  label?: any;
  description?: any;
  reverse?: boolean;
}> = ({ label, description, reverse }) => {
  return reverse ? (
    <>
      {label !== null && (
        <Block fontWeight="bold" margin={{ bottom: 6 }}>
          {label}
        </Block>
      )}
      {description !== null && (
        <Block fontSize="small" color="gray" shade="dark">
          {description}
        </Block>
      )}
    </>
  ) : (
    <>
      {label !== null && (
        <Block
          fontSize="small"
          color="gray"
          shade="dark"
          margin={{ bottom: 6 }}
        >
          {label}
        </Block>
      )}
      {description !== null && <Block fontWeight="bold">{description}</Block>}
    </>
  );
};

const ListItemLabel = styled(Block)`
  color: ${({ theme, color }) => !color && theme.colors.gray.light};
  font-size: ${({ theme, fontSize }) => !fontSize && theme.fontSize.small};
  text-align: ${({ textAlign }) => !textAlign && "right"};
`;

export {
  IListProps,
  List,
  ListItemCell,
  ListItemRow,
  ListItemBody,
  ListItemContent,
  ListItemLabel,
};
