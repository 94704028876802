import * as React from "react";
import { FunctionComponent } from "react";
import getIcon from "./icons";
import { IconName as TIconName } from "./type";
import {
  styled,
  unit,
  IColor,
  IColors,
  renderColor,
} from "../../styled-components";
import { Block, IBlockProps } from "../Block/Block";

type IconName = TIconName;

const isBase64 = (str: string) => {
  if (str === "" || str.trim() === "") {
    return false;
  }
  try {
    return btoa(atob(str)) == str;
  } catch (err) {
    return false;
  }
};

type iconSizeType =
  | "free"
  | "smallest"
  | "smaller"
  | "small"
  | "small2"
  | "base"
  | "medium"
  | "large"
  | "larger"
  | "largest";

interface IIconProps extends IBlockProps {
  /**
   * Name of the Icon
   * from iconName type.
   * @example 'calendar'
   */
  name?: IconName | null;
  /**
   *
   * @summary to use external svg in your app use:
   * @example import { ReactComponent as SearchIcon } from './search.svg'
   */
  source?: SvgrComponent;
  overlayMedia?: any;
  overlayMediaProps?: any;
  strokeColor?: keyof IColors;
  strokeShade?: keyof IColor;
  iconSize?: iconSizeType;
  iconFill?: string;
  onClick?:
    | ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void)
    | undefined;
}

export const IconContainer = styled(Block).attrs({})<IIconProps>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ theme, iconSize }) =>
    iconSize !== "free" && unit(theme.icon.size[iconSize || "base"])};
  height: ${({ theme, iconSize }) =>
    iconSize !== "free" && unit(theme.icon.size[iconSize || "base"])};
  svg {
    fill: ${({ iconFill }) => iconFill};
    path {
      fill: ${({ iconFill }) => iconFill};
    }

    circle {
      stroke: ${({ theme, strokeColor, strokeShade }) =>
        renderColor(theme, strokeColor, strokeShade)};
    }
  }
  * {
    width: 100%;
    height: 100%;
  }
`;

const OverlayMedia = styled(Block).attrs({})<{
  mediaUrl: string;
  isMediaBase64: boolean;
}>`
  right: -5px;
  bottom: -5px;
  z-index: 100;
  position: absolute;
  height: 18px;
  width: 18px;
  border-radius: 40%;
  background-color: green;
  background-image: ${({ mediaUrl, isMediaBase64 }) => {
    return isMediaBase64
      ? `url(data:image/jpeg;base64, ${mediaUrl})`
      : `url(${mediaUrl})`;
  }};
  background-size: contain;
  box-shadow: 0px 0px 5px #00000063;
  background-repeat: no-repeat;
`;

const Icon: FunctionComponent<IIconProps> = ({
  overlayMedia,
  overlayMediaProps,
  iconFill,
  ...props
}) => {
  const Svg = props.name && getIcon(props.name);
  const ExternalSvg = props.source;
  return (
    <IconContainer {...props}>
      {overlayMedia && (
        <OverlayMedia
          isMediaBase64={isBase64(overlayMedia) ? true : false}
          mediaUrl={overlayMedia}
          {...overlayMediaProps}
        />
      )}
      {Svg && <Svg name={props.name || ""} />}
      {!Svg && ExternalSvg && <ExternalSvg />}
    </IconContainer>
  );
};

export { Icon, IIconProps, IconName };
export default Icon;
