import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import {
  App,
  styled,
  Block,
  createGlobalStyle,
  ProgressBarStyles,
} from "../seenspire-library";
import { Header, PoweredBy } from "./components";
import Routes, { RoutesConfig } from "./routes";
import { Provider, Consumer, IAppContext } from "./state";
import { AppMenu } from "./components/AppMenu/AppMenu";
import ConfirmEmailModal from "./modals/ConfirmEmailModal";

import { DragDropContextProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import ErrorNotificationModal from "./modals/ErrorNotificationModal";
import NotificationModal from "./modals/NotificationModal";
import RequestFeatureModal from "./modals/RequestFeatureModal";
import SwitchAccountModal from "./modals/SwitchAccountModal";
import StickyNotification from "./components/StickyNotification/StickyNotification";
import DeleteFeedModal from "./modals/DeleteFeedModal";
import AcceptTermsModal from "./modals/AcceptTermsModal";
import RequestTrialModal from "./modals/RequestTrialModal";
import { RequestTrialEndedModal } from "./modals/RequestTrialModal/RequestTrialEndedModal";

const AppGlobalStyles = createGlobalStyle`

  html, body, #root {
    height:100%
  }
  #root {
    display:flex;
    flex-direction:column;
  }
  body{
    overflow-x: hidden;
    line-height: normal;
  }
`;

const RouteContainer = styled(Block)`
  flex: 1;
`;

const Index = () => {
  return (
    <Router>
      <DragDropContextProvider backend={HTML5Backend}>
        <Provider>
          <Consumer>
            {(context: IAppContext) => (
              <App theme={context.theme}>
                <AppGlobalStyles />
                <ProgressBarStyles />
                <Suspense fallback={<></>}>
                  {/* {context.appDisplayMode === 'STANDARD' && <></>} */}
                  <StickyNotification />
                  <Header />
                  <AppMenu />
                </Suspense>
                <Suspense fallback={null}>
                  <RouteContainer>
                    <Routes routesConfig={RoutesConfig} />
                  </RouteContainer>
                  <NotificationModal isOpen={true} />
                  <ConfirmEmailModal isOpen={true} />
                  <AcceptTermsModal isOpen={true} />
                  <RequestFeatureModal isOpen={true} />
                  <RequestTrialModal isOpen={true} />
                  <RequestTrialEndedModal isOpen={true} />
                  <SwitchAccountModal isOpen={true} />
                  <DeleteFeedModal />
                  {context.showPoweredBy && <PoweredBy />}
                  <ErrorNotificationModal
                    error={context.error}
                    setError={context.setError}
                  />
                </Suspense>
              </App>
            )}
          </Consumer>
        </Provider>
      </DragDropContextProvider>
    </Router>
  );
};

export default Index;
