import React, {
  useEffect,
  useState,
  FunctionComponent,
  CSSProperties,
  useRef,
} from "react";
import {
  Block,
  Icon,
  theme,
  Button,
  styled,
  FullInputButton,
} from "../../../seenspire-library";
import { IRouted } from "../../routes";
import { Container } from "../../components/Container/Container";
import { Layout } from "../../components";
import Preview from "../../components/Preview/Preview";
import { useWindowSize } from "react-use";
import { AppBackgroundColor } from "../../styles";

const LongFullInputButton = styled(FullInputButton)`
  width: 100%;
`;

const UrlTitle = styled(Block)`
  color: #464951;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  text-transform: uppercase;
`;

const UrlDescription = styled(Block)`
  color: #3a414c;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -0.19px;
  line-height: 16px;
  text-align: left;
`;

const CopyButton = styled(Button)`
  border-radius: 3px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.13), 0 0 2px 0 rgba(0, 0, 0, 0.14),
    inset 0 1px 0 0 #394a68;
  width: 88px;
  height: 36px;
  border: none;
  min-width: auto;
  min-height: auto;
  box-shadow: none;
  font-size: ${({ theme }) => theme.fontSize.smaller};
`;
export const copyToClipboard = (_url: string) => {
  const el = document.createElement("textarea");
  el.value = _url;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  return true;
};

export const generateFeedUrl = (code: string) => {
  return `${process.env.REACT_APP_PRESENTATION}/${code}`;
};

const ChannelUrl: FunctionComponent<IRouted<{ id: string }>> = ({
  t,
  match,
  client,
  setTitle,
  route,
  seenspireConnectConfig,
}) => {
  const [id] = useState<any>(match && match.params.id);

  const [url, setUrl] = useState<string>("");
  const [copied, setCopied] = useState<boolean>();
  const [previewStyle, setPreviewStyle] = useState<Partial<CSSProperties>>();
  const previewRef = useRef<HTMLDivElement>(null);
  const { width: containerWidth, height: containerHeight } = useWindowSize();

  const loadFeed = async () => {
    const _channel = (await client.ActiveChannels.getOpenActiveChannelById(
      id
    )) as any;
    setUrl(generateFeedUrl(_channel.code));
    return _channel;
  };

  const copy = (_url: string) => {
    copyToClipboard(_url);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const resizePreviewContainer = () => {
    if (previewRef && previewRef.current) {
      const ratio = 16 / 9;
      const { top, width: wrapperWidth } =
        previewRef.current.getBoundingClientRect();
      const initialHeight = window.innerHeight - top - 16 - 20;
      const maxWidth = window.innerWidth > 1200 ? 1200 : window.innerWidth;
      const maxHeightBasedOnFullWidth = maxWidth / (16 / 9);
      const height =
        initialHeight > maxHeightBasedOnFullWidth
          ? maxHeightBasedOnFullWidth
          : initialHeight;
      const width =
        height * ratio > wrapperWidth ? wrapperWidth : height * ratio;
      setPreviewStyle({ height, width });
    }
  };

  setTitle(t("Manage feed"));

  useEffect(() => {
    loadFeed();
    resizePreviewContainer();
  }, []);

  useEffect(
    () => void resizePreviewContainer(),
    [containerWidth, containerHeight]
  );

  return (
    <Layout>
      <AppBackgroundColor />

      <Container
        fullHeight
        bg="transparent"
        shadow={undefined}
        scrollY={undefined}
        skipContentPadding
        pulledUp
        display="grid"
        gridGap={16}
        gridTemplate={{ rows: { small: "94px" } }}
      >
        {!seenspireConnectConfig.active && (
          <Container
            alignSelf="stretch"
            display="grid"
            alignItems="center"
            gridTemplate={{ columns: { small: "1fr", medium: "1fr 1fr" } }}
          >
            <Block padding={{ left: 12 }}>
              <UrlTitle>FEED URL</UrlTitle>
              <UrlDescription>
                <Block>{t("feeds.copy_paste_url")}</Block>
              </UrlDescription>
            </Block>

            <Block
              padding={{ right: 12 }}
              display="grid"
              alignItems="center"
              gridGap={20}
              gridTemplate={{ columns: { small: "1fr", medium: "1fr" } }}
            >
              <LongFullInputButton
                title="FEED URL"
                readOnly
                value={url}
                button={
                  <CopyButton
                    disabled={!url}
                    buttonType={copied ? "success" : "cta"}
                    onClick={() => url && copy(url)}
                  >
                    {copied ? (
                      <Icon
                        name="checkDark"
                        iconFill={theme.colors.black.darkest}
                      />
                    ) : (
                      "Copy"
                    )}
                  </CopyButton>
                }
                buttonPosition="right"
              />
            </Block>
          </Container>
        )}

        <Block display="grid" justifyContent="center" ref={previewRef}>
          <Preview
            config={{ id, preview: true }}
            onSubmit={null}
            onCancel={() => {}}
            padding={{ all: 8 }}
            skipInnerWrapper
            style={previewStyle}
          />
        </Block>
      </Container>
    </Layout>
  );
};

export { ChannelUrl };
