import { FlattenInterpolation, ThemeProps } from "styled-components";

interface IColor {
  lightest: string;
  lighter: string;
  light: string;
  base: string;
  dark: string;
  darker: string;
  darkest: string;
}

interface IShadows extends IColor {}

type ColorShadeType =
  | "lightest"
  | "lighter"
  | "light"
  | "base"
  | "dark"
  | "darker"
  | "darkest";

interface IColors {
  text: IColor;
  transparent: IColor;
  white: IColor;
  black: IColor;
  background: IColor;
  primary: IColor;
  accent: IColor;
  alert: IColor;
  warning: IColor;
  success: IColor;
  cta: IColor;
  gray: IColor;
}

type ColorType =
  | "text"
  | "transparent"
  | "white"
  | "black"
  | "background"
  | "primary"
  | "accent"
  | "alert"
  | "success"
  | "cta"
  | "gray"
  | "warning";

interface IFontWeightName {
  black: number;
  bolder: number;
  bold: number;
  regular: number;
  thin: number;
  thinner: number;
  hairline: number;
}

interface IFontSizeName {
  smallest: string;
  smaller: string;
  small: string;
  base: string;
  large: string;
  larger: string;
  largest: string;
}

type ElementSizeType = "large" | "small" | "base";
interface IElementSize {
  smallest: number;
  smaller: number;
  small: number;
  base: number;
  medium: number;
  large: number;
  larger: number;
  largest: number;
}

interface IIconSize {
  smallest: number;
  smaller: number;
  small2: number;
  small: number;
  base: number;
  medium: number;
  large: number;
  larger: number;
  largest: number;
}

type ElementWidthType = "default" | "free" | "block";
interface IElementWidth {
  elementWidth?: IResponsive<ElementWidthType>;
}
interface IElementHeightBase {
  elementHeightBase?: boolean;
}

interface IElement {
  height: number;
  width: number;
  padding: IElementSize;
  size: IElementSize;
}
interface IIcon {
  width: number;
  size: IIconSize;
}
interface ISides {
  all?: IResponsive<number>;
  top?: IResponsive<number>;
  right?: IResponsive<number>;
  bottom?: IResponsive<number>;
  left?: IResponsive<number>;
}

interface ISpacing {
  padding?: ISides;
  margin?: ISides;
}
interface IAlignment {
  position?: IResponsive<"fixed" | "relative" | "absolute" | "inherit">;
  display?: IResponsive<"grid" | "flex" | "inherit" | "block" | "none">;
  direction?: IResponsive<"row-reverse" | "column-reverse" | "row" | "column">;
  gridGap?: IResponsive<number>;
  gridColumn?: IResponsive<string>;
  gridRow?: IResponsive<string>;
  gridTemplate?: {
    columns?: IResponsive<string>;
    rows?: IResponsive<string>;
  };
  justifyContent?: IResponsive<string>;
  justifyItems?: IResponsive<string>;
  alignContent?: IResponsive<string>;
  alignItems?: IResponsive<string>;
  alignSelf?: IResponsive<string>;
  justifySelf?: IResponsive<string>;
}

interface ITextTransform {
  textTransform?: string;
}

interface ITextAlign {
  textAlign?: string;
}
interface ICursor {
  cursor?: string;
}
interface IVisibility {
  visibility?: IResponsive<string>;
}
interface IOpacity {
  opacity?: number;
}

type IShadowType = keyof IColor;
interface IShadow {
  shadow?: IShadowType;
}
interface ITransition {
  transition?: boolean;
}
interface IFlex {
  flex?: IResponsive<string | number>;
}

interface ITextColor {
  color?: keyof IColors;
  shade?: keyof IColor;
}
interface IBackgroundColor {
  bg?: keyof IColors;
  bgShade?: keyof IColor;
}
interface IFontWeight {
  fontWeight?: keyof IFontWeightName;
}
interface IFontSize {
  fontSize?: keyof IFontSizeName;
}
interface IBorderRadius {
  borderRadius?: boolean;
}
interface IPointer {
  disablePointer?: boolean;
}
interface ITextDecoration {
  textDecoration?: string;
}
interface IZIndex {
  zIndex?: number | undefined;
}

interface IResponsiveAt {
  responsiveAt?: keyof IMediaSizes;
}

interface IMediaSizes {
  small: number;
  medium: number;
  large: number;
  xlarge: number;
}

type IMediaSizesMap<T> =
  | T
  | {
      [key in keyof IMediaSizes]?: T;
    };

type IMediaSizesArray<T> = T | T[];

type IResponsive<T> = T | IMediaSizesArray<T> | IMediaSizesMap<T>;

type ThemedColorConfig = {
  [key in ColorType]: FlattenInterpolation<ThemeProps<ITheme>>;
};

interface ITheme {
  colors: IColors;
  spacing: { base: number };
  borderRadius: number;
  fontFamily: {
    main: string;
    heading: string;
  };
  fontWeight: IFontWeightName;
  fontSize: IFontSizeName;
  shadows: IShadows;
  element: IElement;
  icon: IIcon;
  mediaSizes: IMediaSizes;
  layout: {
    maxWidth: number;
    [key: string]: any;
  };
  header: {
    height: number;
    zIndex: number;
  };
  sidebar: {
    width: number;
  };
}

const getElementSize = (
  size: keyof IFontSizeName | undefined
): ElementSizeType => {
  let _size: ElementSizeType = "base";
  switch (size) {
    case "large":
    case "larger":
    case "largest":
      _size = "large";
      break;
    case "small":
    case "smaller":
    case "smallest":
      _size = "small";
      break;
    default:
      _size = "base";
      break;
  }
  return _size;
};

export {
  IColor,
  IShadow,
  ColorShadeType,
  IColors,
  ColorType,
  IFontWeight,
  IFontWeightName,
  IFontSize,
  IFontSizeName,
  IElement,
  ElementWidthType,
  IElementWidth,
  IElementHeightBase,
  ISides,
  ISpacing,
  IAlignment,
  ITextTransform,
  ITextAlign,
  ICursor,
  IVisibility,
  IOpacity,
  IPointer,
  ITransition,
  IFlex,
  IMediaSizes,
  ITextColor,
  ITextDecoration,
  IBackgroundColor,
  IBorderRadius,
  IResponsiveAt,
  ThemedColorConfig,
  IMediaSizesMap,
  IMediaSizesArray,
  IResponsive,
  IZIndex,
  ITheme,
  getElementSize,
};
