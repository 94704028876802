import React, { FunctionComponent, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  Button,
  styled,
  Spacing,
  IAlignment,
  ISpacing,
  css,
  TransitionBase,
  IElementWidth,
} from "../../../seenspire-library";
import { NavLinkProps, NavLink } from "react-router-dom";
import { AppContext } from "../../state";

interface LinkProps extends NavLinkProps, IAlignment, ISpacing, IElementWidth {}

const LinkButtonHoveredOrActive = css<{ skipUnderline?: boolean }>`
  color: ${({ theme }) => theme.colors.white.base};
  svg {
    ${TransitionBase};
    fill: ${({ theme, skipUnderline }) =>
      !skipUnderline && theme.colors.cta.base};
  }
  ::after {
    width: 50%;
    height: 1px;
    background: ${({ theme, skipUnderline }) =>
      !skipUnderline && theme.colors.cta.base};
  }
`;

const ButtonStyled = styled(Button).attrs({
  padding: { left: 20, right: 20 },
})<{ skipUnderline?: boolean }>`
  color: ${({ theme }) => theme.colors.gray.lightest};
  text-transform: uppercase;
  box-shadow: none;
  font-weight: 600;
  ${TransitionBase};
  position: relative;
  ::after {
    position: absolute;
    ${TransitionBase};
    content: "";
    display: block;
    width: 0px;
    height: 1px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    background: transparent;
    bottom: 10px;
  }
  &:hover {
    ${LinkButtonHoveredOrActive}
  }
`;

const BackButtonStyle = styled(ButtonStyled).attrs({
  padding: { left: 20, right: 20 },
})<{ skipUnderline?: boolean }>`
  width: 100px;
  padding: 0;
  text-algin: left;
  align-items: left;
  justify-content: left;
  font-weight: 600;
`;

const _Link = styled(NavLink).attrs({})<LinkProps>`
  ${Spacing};
  &.active {
    ${ButtonStyled} {
      ${LinkButtonHoveredOrActive}
    }
  }
`;

const Link: FunctionComponent<LinkProps & { url?: boolean }> = ({
  to,
  children,
  url,
  ...props
}) => {
  return url ? (
    // @ts-ignore-next-line
    <a href={to as any} {...props}>
      {" "}
      {children}
    </a>
  ) : (
    <_Link to={to as any} {...(props as any)}>
      {children}
    </_Link>
  );
};

const AppLink: FunctionComponent<LinkProps> = ({ to, children, ...props }) => {
  const { slug } = useContext(AppContext);
  return (
    <_Link to={`/${slug}/${to}`} {...(props as any)}>
      {children}
    </_Link>
  );
};

export const BackButton: FunctionComponent<
  LinkProps & { skipUnderline?: boolean; url?: boolean; push: boolean }
> = ({ to, push, skipUnderline, children }) => {
  const history = useHistory();
  const { search } = useLocation();

  const query = React.useMemo(() => new URLSearchParams(search), [search]);

  const goBack = React.useCallback(() => {
    const _from = query.get("from");
    if (_from) {
      history.push(_from);
    } else if (push && to) {
      history.push(to);
    } else {
      history.goBack();
    }
  }, [history, query, to, push]);

  return (
    <BackButtonStyle
      skipUnderline={skipUnderline}
      buttonType="transparent"
      color="gray"
      shade="lighter"
      elementWidth="free"
      onClick={goBack}
    >
      {children}
    </BackButtonStyle>
  );
};

const LinkButton: FunctionComponent<
  LinkProps & { skipUnderline?: boolean; url?: boolean }
> = ({ to, skipUnderline, children, ...props }) => {
  const _props = {
    activeclassname: "active",
    to,
    ...props,
  };
  const content = (
    <ButtonStyled
      skipUnderline={skipUnderline}
      buttonType="transparent"
      color="text"
      shade="lighter"
      elementWidth="free"
    >
      {children}
    </ButtonStyled>
  );
  return props.url ? (
    // Some odd behaviour here... not changing behaviour for now
    // @ts-ignore-next-line
    <Link {..._props}> {content} </Link>
  ) : (
    <AppLink {..._props}>{content}</AppLink>
  );
};
export default LinkButton;
export { Link, AppLink, LinkButton, ButtonStyled };
