import * as React from "react";
import { FunctionComponent } from "react";
import { IconName } from "../Icon/type";
import {
  styled,
  unit,
  getElementSize,
  IElementWidth,
  ColorType,
  css,
  IElementHeightBase,
  hsla,
  ITheme,
} from "../../styled-components";
import { Icon, IIconProps } from "../Icon/Icon";
import { Text, TextStyles } from "../Text/Text";
import { IBlockProps, BlockStyles } from "../Block/Block";
import {
  CenterContent,
  CenterItems,
  ElementSizePadding,
  ElementWidth,
  FontWeightBold,
  DisplayFlex,
  ElementHeightBase,
  BorderRadiusBase,
  ColorsReducer,
} from "../styles";
import { Block } from "../Block/Block";
import { INPUT_BORDER_COLOR_DARK_MODE } from "../Input/variables";

// Button main types
type buttonType =
  | "primary"
  | "accent"
  | "alert"
  | "success"
  | "cta"
  | "outline"
  | "transparent"
  | "white"
  | "gray"
  | "warning";
interface IButtonProps extends IBlockProps, IElementWidth, IElementHeightBase {
  buttonType?: buttonType;
  icon?: IconName | null;
  iconProps?: IIconProps;
  onClick?:
    | ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void)
    | undefined;
  type?: string;
  disabled?: boolean;
  darkMode?: boolean;
  nativeColor?: string;
}

const pickBgColor = (color: ColorType, theme: ITheme) => {
  switch (true) {
    case color === "gray":
      return theme.colors[color].darkest;
    default:
      return theme.colors[color].base;
  }
};
const pickBgHoverColor = (color: ColorType, theme: ITheme) => {
  switch (true) {
    default:
      return theme.colors[color].darker;
  }
};

export const LightColors = ["transparent", "white", "success", "warning"];
const ButtonColorsConfig = ColorsReducer((acc, label) => {
  const requireDarkColor = LightColors.includes(label);
  acc[label] = css`
    color: ${({ theme }) =>
      requireDarkColor ? theme.colors.black.light : theme.colors.white.base};
    fill: ${({ theme }) =>
      requireDarkColor ? theme.colors.black.light : theme.colors.white.base};
    path: ${({ theme }) =>
      requireDarkColor ? theme.colors.black.light : theme.colors.white.base};
    background-color: ${({ theme }) => pickBgColor(label, theme)};
    border: solid 1px transparent;
    border-color: ${({ theme }) => pickBgColor(label, theme)};
    transition: all 160ms ease-in-out;
    &:hover {
      background-color: ${({ theme }) => pickBgHoverColor(label, theme)};
      border-color: ${({ theme }) => pickBgHoverColor(label, theme)};
    }
    &:active {
      background-color: ${({ theme }) => pickBgHoverColor(label, theme)};
      border-color: ${({ theme }) => pickBgHoverColor(label, theme)};
    }
    &:disabled {
      opacity: 0.5;
    }
  `;
  return acc;
});

const outline = css<{ darkMode?: boolean }>`
  color: ${({ theme, darkMode }) =>
    darkMode ? theme.colors.gray.lightest : theme.colors.gray.darkest};
  fill: ${({ theme, darkMode }) =>
    darkMode ? theme.colors.gray.lightest : theme.colors.gray.darkest};
  path: ${({ theme, darkMode }) =>
    darkMode ? theme.colors.gray.lightest : theme.colors.gray.darkest};
  background-color: ${({ theme, darkMode }) =>
    darkMode ? "transparent" : theme.colors.background.base};
  border: solid 1px transparent;
  border-color: ${({ darkMode }) =>
    darkMode ? INPUT_BORDER_COLOR_DARK_MODE : "#E5E9EE"};
  transition: all 160ms ease-in-out;
  &:hover {
    background-color: ${({ theme, darkMode }) =>
      darkMode
        ? hsla(theme.colors.gray.lightest, 0.2)
        : theme.colors.background.darkest};
  }
  &:active {
    background-color: ${({ theme }) => theme.colors.background.darkest};
    border-color: ${({ darkMode }) =>
      darkMode ? INPUT_BORDER_COLOR_DARK_MODE : "#E5E9EE"};
  }
  &:disabled {
    opacity: 0.5;
  }
`;

const IconContainer = styled(Icon)``;

const StyledButton = styled(Block).attrs({ as: "button" })<IButtonProps>`
  ${ElementHeightBase};
  ${BlockStyles};
  ${TextStyles};
  ${CenterItems};
  ${CenterContent};
  ${ElementSizePadding};
  ${ElementWidth};
  ${FontWeightBold};
  ${DisplayFlex};
  ${BorderRadiusBase};

  ${({ buttonType }) =>
    buttonType !== "transparent" ? `text-transform: uppercase` : ""};
  ${({ buttonType }) => {
    switch (buttonType) {
      case "outline":
        return outline;
      default:
        return buttonType
          ? ButtonColorsConfig[buttonType]
          : ButtonColorsConfig.primary;
    }
  }};

  font-weight: ${({ buttonType }) =>
    buttonType !== "transparent" && buttonType !== "outline" ? `600` : "400"};
  background-color: ${({ nativeColor }) => nativeColor};
  border-color: ${({ nativeColor }) => nativeColor};
  :hover {
    background-color: ${({ nativeColor }) => nativeColor};
    border-color: ${({ nativeColor }) => nativeColor};
  }
  :active {
    background-color: ${({ nativeColor }) => nativeColor};
    border-color: ${({ nativeColor }) => nativeColor};
  }

  &:hover,
  &:focus {
  }
  &:active {
    transform: translateY(1px);
  }
  &:disabled {
    opacity: 0.5;
  }
  ${IconContainer} {
    width: ${({ theme, fontSize }) =>
      unit(theme.element.size[getElementSize(fontSize)])};
  } ;
`;

const Button: FunctionComponent<IButtonProps> = ({
  children,
  icon,
  elementWidth,
  iconProps,
  ...props
}) => {
  const _elementWidth = elementWidth || "default";

  return (
    <StyledButton elementWidth={_elementWidth} {...props}>
      {icon && (
        <IconContainer
          margin={{ right: children ? 10 : 0 }}
          name={icon}
          {...iconProps}
        />
      )}

      {typeof children === "string" ? <Text>{children}</Text> : children}
    </StyledButton>
  );
};

export { Button, IButtonProps, buttonType };
