import React, { FunctionComponent, useContext } from "react";
import { AppContext } from "../../state";
import {
  styled,
  Block,
  unit,
  Icon,
  Button,
  Media,
  css,
  TransitionBase,
  renderColor,
} from "../../../seenspire-library";
import { SidePadding } from "../Layout/Layout";

const HeaderHeight = css`
  height: ${(p) => unit(p.theme.header.height)};
  ${Media.lt.large`
    height: ${(p) => unit(p.theme.header.height - 10)};
  `};
`;

const StickyStyled = styled(Block).attrs({})<{ active: boolean }>`
  width: 100%;
  display: flex;
  position: fixed;
  top: ${({ active }) => (active ? 0 : unit(-100))};
  left: 0;
  height: ${({ theme }) => unit(theme.header.height)};
  width: 100%;
  align-items: center;
  z-index: ${({ theme }) => theme.header.zIndex + 1000};
  background-color: ${({ theme }) => theme.colors.white.base};
  opacity: ${({ active }) => (active ? 1 : 0)};
  ${HeaderHeight}
  ${SidePadding}
  ${TransitionBase};
`;

const IconBlock = styled(Block)`
  display: grid;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: ${({ theme }) => unit(theme.borderRadius)};
  background-color: ${({ theme, bg, bgShade }) =>
    renderColor(theme, bg, bgShade) || theme.colors.background.dark};
  border: 1px solid #d5dbe2;
`;

const StickyNotification: FunctionComponent = () => {
  const Context = useContext(AppContext);
  const { stickyNotification, setStickyNotification } = Context;
  const {
    active,
    title,
    subTitle,
    onConfirm = () => setStickyNotification({ active: false }),
    ctaLabel,
  } = stickyNotification;
  return (
    <>
      <StickyStyled active={active}>
        <Block
          flex={1}
          display="grid"
          alignItems="center"
          gridGap={15}
          gridTemplate={{ columns: "32px 1fr 160px" }}
        >
          <IconBlock>
            <Icon name="error" />
          </IconBlock>

          <Block display="grid" alignItems="center" gridGap={3}>
            <Block fontWeight="bold" color="gray" shade="darkest">
              {title}
            </Block>
            <Block fontSize="small" color="gray" shade="darker" opacity={0.5}>
              {subTitle}
            </Block>
          </Block>

          <Button
            elementHeightBase={false}
            elementWidth="block"
            buttonType="outline"
            onClick={onConfirm}
          >
            {ctaLabel || "Got it"}
          </Button>
        </Block>
      </StickyStyled>
    </>
  );
};

export default StickyNotification;
