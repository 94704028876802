import React, { useState, FunctionComponent, useEffect } from "react";
import * as Yup from "yup";
import { Block, styled, Panel, PanelHeader } from "../../../seenspire-library";
import { Formik, Field, FormikHelpers } from "formik";
import { AccountUserRoleMe, OpenApi } from "seenspire-api-client";
import { IRouted, getCurrentUserAccount } from "../../routes";
import InputField from "../../components/InputField/InputField";
import { Container } from "../../components/Container/Container";
import { FORM_DEFAULTS } from "../../components/PanelForm/PanelForm";
import {
  onSuccess,
  onFail,
} from "../../components/FormCtaButton/FormCtaButton";
import { Layout } from "../../components";
import ImageField from "../../components/ImageField/ImageField";
import { Avatar } from "../../components/Avatar/Avatar";
import { AppBackgroundColor } from "../../styles";

const PullUpBlock = styled(Block).attrs({})<{
  pullUpAmount: number;
}>`
  z-index: 100;
  transform: ${({ pullUpAmount }) => `translateY(-${pullUpAmount}%)`};
`;

const CustomAvatar = styled(Avatar)`
  border: 2px solid #ffffff;
`;

const FieldWithTickStyled = styled(Block).attrs({})<{ showTick: boolean }>`
  position: relative;

  ${({ showTick }) =>
    showTick &&
    `::after {
    position:absolute;
    width:18px;
    height:18px;
    content:" ";
    background-image:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAAXNSR0IArs4c6QAAAgtJREFUOBGdVM9LFVEU/s6b96SHiAi2MPFRSNii3CTUQucp+miXuvGRUOaiVbjth7m0/AOkVXvFHxt36lOeTQq61oUgQSIi8qaEENHpOad7bt18Mw6ZXZg5937nO9+cOXPOECJWm5NpBzirLpsZtUIhwq66O+qayNu5xXAYlQKZ1czNoscfmDldiof3RPQxXkbPcvdzW8b3R6h9+UHLqX86A+Yq4/yrJTqwYlbnYvPcJ+FpIcnkh8dr/yxinqDEEmV0TzKLCSavc2kRCVTZ61i1JSkss78g+EWr3CpHS3UzZvfnAlSiWEf819cJ4JGHBCXw7vYwGivvoCJegand6RIeZ9WrsV2CRG5JlfLNrddapHBSQL6wFOKxHTN9EvIEjgP1z5G+auOweIgX66/gem7ALxq62AFUHfpSj5FKpjTcW/cI3bVd8HwPgxtD+HK0Habrc1w6Vik2GG9rdRpPr/chW9eD+f0cOq89hM8+3m6OYP37hqEFrGiojKTtz9bqtzUtkLSSWkQ8o5/fw3F1350RAztyrBv99Ueq4E8MXuQilr+uwD1x0VR1FxM7kxjbGTfuSKs+/0vd2W1Ox1LUfNVcqcHe8V5ksAFl7vL2QqsutgygGu8D4zT2IhGJ0bEqQAvJrMgARokZ0XP299CaP4AWEpJMsQygpHouKAQIR7hm8sWtaxTi4X9+bD8ByXi/PrSBrd8AAAAASUVORK5CYII=');
    top:40%;
    right:16px;
    color:white;
    display:flex;
    align-items:center;
    justify-content:center;
  }`}
`;

const FieldWithSecondTickStyled = styled(FieldWithTickStyled)`
  ${({ showTick }) =>
    showTick &&
    `::after {
    top:35%;
    right:50px;
  }`}
`;

export const FieldWithTick: FunctionComponent<{ showTick: boolean }> = ({
  children,
  showTick = false,
}) => {
  return (
    <FieldWithTickStyled showTick={showTick}>{children}</FieldWithTickStyled>
  );
};

export const FieldWithSecondTick: FunctionComponent<{ showTick: boolean }> = ({
  children,
  showTick = false,
}) => {
  return (
    <FieldWithSecondTickStyled showTick={showTick}>
      {children}
    </FieldWithSecondTickStyled>
  );
};

const Profile: FunctionComponent<IRouted> = ({
  t,
  slug,
  client,
  setTitle,
  user,
  CtaConfig,
  resetCtaConfig,
  setCtaConfig,
  setReAuthenticate,
  account,
}) => {
  const [data, setData] = useState<any>({
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    avatar: user.avatar,
  });
  const [currentUser, setCurrentUser] = useState<
    AccountUserRoleMe | undefined
  >();
  const [passwordsMatch, setPasswordsMatch] = useState<boolean>(false);

  setTitle(t("My Profile"));

  const loadData = async () => {
    try {
      const currentUser = getCurrentUserAccount(user, slug);
      setCurrentUser(currentUser);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadData();
  }, [account]);

  const onSubmit = async (values: any, actions: FormikHelpers<any>) => {
    try {
      setCtaConfig((prev: any) => ({
        ...prev,
        error: false,
        submitting: true,
      }));
      await client.Users.putMe(values, slug);
      onSuccess(actions, () => {
        setData(values);
        setReAuthenticate(true);
      });
      setCtaConfig((prev: any) => ({
        ...prev,
        error: false,
        submitted: true,
        submitting: false,
      }));
    } catch (error) {
      setCtaConfig((prev: any) => ({
        ...prev,
        error: true,
        submitting: false,
      }));
      onFail(actions, error);
    }
  };

  const checkPasswords = (values: any) => {
    const { plainPassword } = values;

    if (!plainPassword) return false;

    const { first, second } = plainPassword;

    const _passwordsMatch =
      first &&
      first.length >= 8 &&
      first.match(/\d+/) &&
      first.match(/[a-z]/) &&
      first.match(/[A-Z]/) &&
      first.match(/[^a-zA-Z0-9]/) &&
      first === second
        ? true
        : false;

    setPasswordsMatch(_passwordsMatch);
  };

  const generateRolesString = (roles: any): string => {
    if (!roles || roles.length === 0) {
      return "";
    }

    const roleNames = roles.map((role: any) => {
      return `${role.name}`;
    });

    return roleNames.join(", ");
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().nullable().required(),
    last_name: Yup.string().nullable().required(),
    email: Yup.string().nullable().email(t("auth.email_is_invalid")).required(),
    avatar: Yup.string().nullable(),
    oldPassword: Yup.string().nullable(),
    plainPassword: Yup.object().shape({
      first: Yup.string()
        .min(8, "Password is too short - should be 8 chars minimum.")
        .matches(/\d+/, t("auth.password_validation"))
        .matches(/[a-z]/, t("auth.password_validation"))
        .matches(/[A-Z]/, t("auth.password_validation"))
        .matches(/[^a-zA-Z0-9]/, t("auth.password_validation")),

      second: Yup.string().oneOf(
        [Yup.ref("first"), ""],
        "Passwords must match!"
      ),
    }),
  });

  const { first_name, last_name, avatar } = data;

  const gridTemplate = { columns: { small: "1fr", medium: "1fr 1fr" } };
  const roles = currentUser ? currentUser.roles : [];
  const rolesString = roles ? generateRolesString(roles) : "";
  return (
    <>
      <AppBackgroundColor />
      <Layout>
        <Container
          margin={{ top: -20 }}
          defaultPadding
          scrollY={false}
          overflow={"unset"}
          padding={{ top: 0, left: 68, right: 68, bottom: 55 }}
        >
          <Formik
            initialValues={data}
            validationSchema={validationSchema}
            validate={(values: any) => {
              // checkPasswords(values);
              resetCtaConfig();
            }}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {(form) => {
              if (!CtaConfig)
                setCtaConfig({
                  action: form.submitForm,
                  label: "SAVE",
                  submitted: false,
                  submitting: false,
                  error: false,
                });

              return (
                <>
                  <PullUpBlock
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    pullUpAmount={30}
                    direction="column"
                  >
                    <CustomAvatar
                      margin={{ bottom: 10 }}
                      avatarSize={"superlarge"}
                      fontSize={"larger"}
                      user={{
                        first_name,
                        last_name,
                        avatar: form.values.avatar,
                      }}
                    />
                    <Field
                      name="avatar"
                      component={ImageField}
                      previewHide={true}
                      imageType="url"
                    />
                  </PullUpBlock>
                  <Panel
                    title={t("Profile information")}
                    gridTemplate={{ columns: "fr1", rows: "fr1" }}
                    gridGap={16}
                  >
                    <Block
                      display="grid"
                      gridGap={FORM_DEFAULTS.gap}
                      gridTemplate={gridTemplate}
                      direction="column"
                    >
                      <Field
                        name="first_name"
                        title="First name"
                        type="text"
                        component={InputField}
                      />
                      <Field
                        name="last_name"
                        title="Last name"
                        type="text"
                        component={InputField}
                      />
                    </Block>
                    <Block
                      display="grid"
                      gridGap={FORM_DEFAULTS.gap}
                      gridTemplate={gridTemplate}
                      margin={{ top: 16 }}
                    >
                      <Field
                        name="email"
                        type="email"
                        title="Email"
                        component={InputField}
                        direction="column"
                        disabled={true}
                      />
                      {roles && roles.length > 0 && (
                        <Block
                          alignItems="start"
                          justifyContent="center"
                          display="flex"
                          direction="column"
                        >
                          <Block
                            fontSize="small"
                            color="gray"
                            shade="base"
                            margin={{ bottom: 6 }}
                          >
                            User role:
                          </Block>
                          <Block fontWeight="bold">{rolesString}</Block>
                        </Block>
                      )}
                    </Block>
                  </Panel>
                  <Panel
                    title={t("Password")}
                    gridTemplate={{ columns: "fr1", rows: "fr1" }}
                  >
                    <Block display="grid" gridGap={FORM_DEFAULTS.gap}>
                      <Field
                        name="oldPassword"
                        title="Old password"
                        type="password"
                        component={InputField}
                      />
                      <FieldWithTick showTick={passwordsMatch}>
                        <Field
                          name="plainPassword.first"
                          type="password"
                          title={t("auth.new_password")}
                          component={InputField}
                        />
                      </FieldWithTick>
                      <FieldWithTick showTick={passwordsMatch}>
                        <Field
                          name="plainPassword.second"
                          type="password"
                          title={t("auth.confirm_password")}
                          component={InputField}
                        />
                      </FieldWithTick>
                    </Block>
                  </Panel>
                </>
              );
            }}
          </Formik>
        </Container>
      </Layout>
    </>
  );
};

export { Profile };
