import * as moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";
momentDurationFormatSetup(moment);

export interface ITrackData {
  userId: string;
  accountId: string;
  airtime?: string;
  account_users?: string[];
  account_status?: string;
  revenue?: string;
  licences?: number;
  plan_name?: string;
  subscription_created_at?: string;
  subscription_expires_on?: string;
  subscription_last_billed_at?: string;
  organization_name?: string;
}

const convertSecondToHoursMins = (airtime: number) => {
  if (airtime === 0) {
    return "0 Mins";
  }

  const duration = moment.duration(100, "seconds");
  return duration.format("h [hrs], m [min]");
};

export async function track(trackData: ITrackData) {}
