import { createGlobalStyle, styled, Block } from "../../seenspire-library";

export const AppBackgroundColor = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.background.base} !important;
  }
`;

export const AuthAppBackgroundColor = createGlobalStyle`
  body {
    background-color: #0a0e17!important;
    background-image: url("https://static.seenspire.com/assets/portals/img/login_background_image.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
`;

export const WrapOverflow = styled(Block)<{ maxWidth?: any }>`
  max-width: ${({ maxWidth }) => maxWidth || "90%"};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
`;
