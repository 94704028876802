import { ITheme, IColors } from "./interface";

export const colors: IColors = {
  transparent: {
    lightest: "transparent",
    lighter: "transparent",
    light: "transparent",
    base: "transparent",
    dark: "transparent",
    darker: "transparent",
    darkest: "transparent",
  },
  white: {
    lightest: "hsl(0, 0%, 100%)",
    lighter: "hsl(0, 0%, 100%)",
    light: "hsl(0, 0%, 100%)",
    base: "hsl(0, 0%, 100%)",
    dark: "hsl(0, 0%, 100%)",
    darker: "hsl(0, 0%, 100%)",
    darkest: "hsl(0, 0%, 100%)",
  },
  black: {
    lightest: "hsl(0, 0%, 4%)",
    lighter: "hsl(0, 0%, 4%)",
    light: "hsl(0, 0%, 4%)",
    base: "hsl(0, 0%, 4%)",
    dark: "hsl(0, 0%, 4%)",
    darker: "hsl(0, 0%, 4%)",
    darkest: "hsl(0, 0%, 4%)",
  },
  background: {
    lightest: "hsl(216, 15%, 100%)",
    lighter: "hsl(216, 15%, 99%)",
    light: "hsl(216, 29%, 98%)",
    base: "hsl(180, 8%, 97%)",
    dark: "hsl(0, 0%, 98%)",
    darker: "hsl(216, 10%, 95%)",
    darkest: "hsl(216, 5%, 95%)",
  },
  primary: {
    lightest: "hsl(215, 59%, 14%)",
    lighter: "hsl(215, 59%, 14%)",
    light: "hsl(215, 59%, 14%)",
    base: "hsl(215, 59%, 14%)",
    dark: "hsl(215, 63%, 13%)",
    darker: "hsl(215, 63%, 13%)",
    darkest: "hsl(215, 63%, 13%)",
  },
  text: {
    lightest: "hsl(0, 0%, 4%)",
    lighter: "hsl(210, 19%, 31%)",
    light: "hsl(220, 50%, 5%)",
    base: "hsl(0, 0%, 4%)",
    dark: "hsl(0, 0%, 4%)",
    darker: "hsl(0, 0%, 4%)",
    darkest: "hsl(0, 0%, 4%)",
  },
  accent: {
    // lightest: "hsl(37, 100%, 95%)",
    // lighter: "hsl(36, 95%, 85%)",
    // light: "hsl(36, 95%, 70%)",
    // base: "hsl(35, 84%, 50%)",
    // dark: "hsl(36, 95%, 40%)",
    // darker: "hsl(36, 95%, 25%)",
    // darkest: "hsl(36, 100%, 15%)",
    lightest: "hsl(210, 85%, 51%)",
    lighter: "hsl(210, 85%, 51%)",
    light: "hsl(210, 85%, 51%)",
    base: "hsl(210, 85%, 51%)",
    dark: "hsl(210, 85%, 51%)",
    darker: "hsl(210, 85%, 45%)",
    darkest: "hsl(35, 84%, 50%)",
  },
  cta: {
    lightest: "hsl(210, 85%, 51%)",
    lighter: "hsl(210, 85%, 51%)",
    light: "hsl(210, 85%, 51%)",
    base: "hsl(223, 100%, 51%)",
    dark: "hsl(210, 85%, 51%)",
    darker: "hsl(223, 100%, 45%)",
    darkest: "hsl(210, 85%, 51%)",
  },
  warning: {
    lightest: "hsl(34, 99%, 66%)",
    lighter: "hsl(34, 99%, 66%)",
    light: "hsl(34, 99%, 66%)",
    base: "hsl(34, 99%, 66%)",
    dark: "hsl(34, 94%, 62%)",
    darker: "hsl(34, 94%, 62%)",
    darkest: "hsl(34, 94%, 62%)",
  },
  alert: {
    lightest: "hsl(358, 87%, 56%)",
    lighter: "hsl(358, 87%, 56%)",
    light: "hsl(358, 87%, 56%)",
    base: "hsl(356, 75%, 53%)",
    dark: "hsl(356, 72%, 46%)",
    darker: "hsl(356, 72%, 46%)",
    darkest: "hsl(358, 87%, 56%)",
  },
  success: {
    lightest: "hsl(121, 51%, 51%)",
    lighter: "hsl(121, 51%, 51%)",
    light: "hsl(121, 51%, 51%)",
    base: "hsl(159, 93%, 43%)",
    dark: "hsl(121, 51%, 51%)",
    darker: "hsl(159, 93%, 40%)",
    darkest: "hsl(121, 51%, 51%)",
  },
  gray: {
    lightest: "hsl(211, 15%, 93%)",
    lighter: "hsl(211, 15%, 83%)",
    light: "hsl(211, 15%, 73%)",
    base: "hsl(211, 15%, 63%)",
    dark: "hsl(210, 10%, 50%)",
    darker: "hsl(211, 15%, 43%)",
    darkest: "hsl(217, 13%, 26%)",
  },
};

const fontWeight = {
  black: 900,
  bolder: 800,
  bold: 600,
  regular: 400,
  thin: 300,
  thinner: 200,
  hairline: 100,
};

const fontSize = {
  smallest: "0.8rem",
  smaller: "1.1rem",
  small: "1.2rem",
  base: "1.3rem",
  large: "1.5rem",
  larger: "1.6rem",
  largest: "2rem",
};

const MediaSizes = {
  small: 700,
  medium: 1024,
  large: 1280,
  xlarge: 1440,
};

export const theme: ITheme = {
  colors,
  fontWeight,
  fontSize,
  fontFamily: {
    main: "'Open Sans', sans-serif;",
    heading: "proxima-nova, sans-serif",
  },
  shadows: {
    lightest: "",
    lighter: "1px 1px 3px hsla(218, 43%, 18%, 0.58)",
    light: "3px 3px 4px -5px #000000;",
    base: "0 1px 2px 0 rgba(0, 0, 0, 0.16)",
    dark: "0 1px 2px 0 rgba(0, 0, 0, 0.16)",
    darker: "0 1px 2px 0 rgba(0, 0, 0, 0.32)",
    darkest: "0 1px 2px 0 rgba(0, 0, 0, 0.42)",
  },
  element: {
    height: 56,
    width: 210,
    padding: {
      smallest: 1,
      smaller: 2,
      small: 5,
      base: 10,
      medium: 14,
      large: 16,
      larger: 20,
      largest: 40,
    },
    size: {
      smallest: 8,
      smaller: 10,
      small: 15,
      base: 25,
      medium: 30,
      large: 40,
      larger: 50,
      largest: 100,
    },
  },
  icon: {
    width: 250,
    size: {
      smallest: 7,
      smaller: 10,
      small2: 12,
      small: 15,
      base: 20,
      medium: 30,
      large: 40,
      larger: 83,
      largest: 120,
    },
  },
  spacing: {
    base: 5,
  },
  borderRadius: 4,
  mediaSizes: MediaSizes,
  layout: {
    maxWidth: 1200,
  },
  header: {
    height: 65,
    zIndex: 9999,
  },
  sidebar: {
    width: 250,
  },
};
