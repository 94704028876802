import * as React from "react";
import * as styledComponents from "styled-components";
import { ThemedStyledComponentsModule } from "styled-components";
import { ITheme } from "./interface";

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
} = styledComponents as ThemedStyledComponentsModule<ITheme>;

interface reuse extends React.HTMLProps<any> {}
export { reuse, styled, css, createGlobalStyle, keyframes, ThemeProvider };
