import * as React from "react";
import { FunctionComponent } from "react";
import {
  ThemeProvider,
  theme as baseTheme,
  GlobalStyle,
  ITheme,
} from "../../styled-components";

const App: FunctionComponent<{ theme?: ITheme }> = function App({
  theme,
  children,
}) {
  return (
    <>
      <GlobalStyle></GlobalStyle>
      <ThemeProvider theme={theme || baseTheme}>
        <>{children}</>
      </ThemeProvider>
    </>
  );
};
export { App };
