import React, {
  FunctionComponent,
  useState,
  useRef,
  useEffect,
  CSSProperties,
} from "react";
import {
  styled,
  Block,
  CtaButton,
  PanelHeader,
} from "../../../seenspire-library";
import { Container, IContainer } from "../Container/Container";
import { useTranslation } from "react-i18next";
import { AspectRatioBox } from "../../containers/Dashboard/Styles";
import { FormikProps } from "formik";

const IFrame = styled.iframe`
  position: absolute;
  width: 100%;
  height: 100%;
  border: 0;
`;

export interface IPreviewConfig {
  id: string;
  accountId?: string;
  preview?: boolean;
  sourceId?: string;
  regionId?: string;
  languageId?: string;
  presentationDesignSlug?: string;
}
interface IPreviewProps extends IContainer {
  config: IPreviewConfig;
  data?: any;
  form?: FormikProps<any>;
  onSubmit: (() => void) | null;
  onCancel: () => void;
  skipInnerWrapper?: boolean;
}

const Preview: FunctionComponent<
  IPreviewProps & { style?: Partial<CSSProperties> }
> = ({ data, onSubmit, onCancel, config, skipInnerWrapper, ...props }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const { t } = useTranslation();
  const [queryParams, setQueryParams] = useState("");

  useEffect(() => {
    if (!config) return;

    let _qp = `?id=${config.id}`;

    if (config.preview) {
      _qp = `${_qp}&preview=true`;
    }

    if (config.presentationDesignSlug) {
      _qp = `${_qp}&presentationDesignSlug=${config.presentationDesignSlug}`;
    }

    if (config.sourceId) {
      _qp = `${_qp}&sourceId=${config.sourceId}`;
    }

    if (config.regionId) {
      _qp = `${_qp}&regionId=${config.regionId}`;
    }

    if (config.languageId) {
      _qp = `${_qp}&languageId=${config.languageId}`;
    }

    if (config.accountId) {
      _qp = `${_qp}&accountId=${config.accountId}`;
    }

    setQueryParams(_qp);
  }, [config]);

  const updateIframe = React.useCallback(() => {
    const iFrame = iframeRef.current && iframeRef.current.contentWindow;
    if (iFrame && data) {
      iFrame.postMessage(data, "*"); //window.location.origin
    }
  }, [data, queryParams]);

  useEffect(() => {
    updateIframe();
  }, [updateIframe]);

  const iframe = (
    <IFrame
      ref={iframeRef}
      src={`${process.env.REACT_APP_PRESENTATION}/preview/index.html${queryParams}`}
      onLoad={updateIframe}
    ></IFrame>
  );

  return queryParams ? (
    <Container skipContentPadding withHeader={!!onSubmit} {...props}>
      {skipInnerWrapper ? (
        iframe
      ) : (
        <Block position="relative">
          {iframe}
          <AspectRatioBox />
        </Block>
      )}
    </Container>
  ) : null;
};

export default Preview;
