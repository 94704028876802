import * as React from "react";
import { FunctionComponent } from "react";
import Select, { Creatable } from "react-select";
import Async, { Props as AsyncProps } from "react-select/lib/Async";
import AsyncCreatable from "react-select/lib/AsyncCreatable";
import {
  theme,
  styled,
  unit,
  hsla,
  scaleFontSize,
} from "../../styled-components";
import type { Props } from "react-select/lib/Select";
import type { ValueType } from "react-select/lib/types";
import type { StylesConfig } from "react-select/lib/styles";
import { Icon } from "../Icon/Icon";
import { InputIcon } from "../Input/Input";
import {
  ContainerProps,
  SelectContainer,
} from "react-select/lib/components/containers";
import { SelectComponents } from "react-select/lib/components";
import {
  SELECT_DEFAULTS,
  INPUT_ICON_DEFAULTS,
  INPUT_BORDER_COLOR,
} from "../Input/variables";
import { Label, LabelActive } from "../Label/Label";
import Option, { OptionProps } from "react-select/lib/components/Option";
import { Block } from "../Block/Block";
import Menu, { MenuProps } from "react-select/lib/components/Menu";
import Control, { ControlProps } from "react-select/lib/components/Control";
import { IconPositionType, FieldError } from "../Input/shared";

interface ISelectProps extends Props {
  primitiveValue?: boolean;
  withIcon?: boolean;
  icon?: React.ReactComponentElement<typeof Icon>;
  title?: string;
  alwaysPadding?: boolean;
  ignorePadding?: boolean;
  withDescription?: boolean;
  optionWithIcon?: boolean;
  scaleAmount?: number;
  errorMessage?: any;
}
interface IAsyncSelectProps extends AsyncProps<any> {
  primitiveValue?: boolean;
  scaleAmount?: number;
}

type OptionType = ValueType<{
  label: string;
  value: string;
}>;

const SelectOptionStyle = (
  base: React.CSSProperties,
  { isFocused, isSelected }: any
) => {
  return {
    ...base,
    backgroundColor: isSelected
      ? theme.colors.gray.lightest
      : isFocused
      ? hsla(theme.colors.gray.lightest, 0.45)
      : theme.colors.white.base,
    "&:active": {
      backgroundColor: theme.colors.gray.lightest,
    },
    color: theme.colors.gray.darker, // isSelected ? theme.colors.white.base : theme.colors.gray.darker,
    borderRadius: theme.borderRadius,
    margin: "0 8px",
    padding: "8px",
    width: "calc(100% - 16px)",
  };
};
const SelectControlStyles = (
  base: React.CSSProperties,
  { isFocused, selectProps, errorMessage }: any
) => ({
  ...base,
  minHeight: selectProps.scaleAmount
    ? theme.element.height / selectProps.scaleAmount
    : theme.element.height,
  borderColor: selectProps.errorMessage
    ? theme.colors["alert"].base
    : isFocused
    ? INPUT_BORDER_COLOR
    : INPUT_BORDER_COLOR,
  backgroundColor: "transparent",
  boxShadow: "none !important",
  zIndex: 150,
  "&:hover": { borderColor: null },
});

const SelectInputStyles = (base: React.CSSProperties) => ({
  ...base,
  marginLeft: 0,
  marginTop: 0,
  marginBottom: 0,
  color: theme.colors.gray.darker,
});

const SelectValueContainerStyles = (base: React.CSSProperties, props: any) => {
  return {
    ...base,
    /* subtract  border-top & border-bottom width */
    minHeight: props.selectProps.scaleAmount
      ? (theme.element.height - 2) / props.selectProps.scaleAmount
      : theme.element.height - 2,
    // @TODO Use InputIconPaddingLeft in input.tsx
    paddingLeft: props.selectProps.withIcon
      ? props.selectProps.scaleAmount
        ? // withIcon + scaleAmount
          (theme.element.padding.large + INPUT_ICON_DEFAULTS.size) /
          props.selectProps.scaleAmount
        : // withIcon
          theme.element.padding.large + INPUT_ICON_DEFAULTS.size
      : props.selectProps.scaleAmount
      ? // withoutIcon + scaleAmount
        theme.element.padding.large / props.selectProps.scaleAmount
      : // withoutIcon and without scaleAmount
        theme.element.padding.large,
    fontSize: props.selectProps.scaleAmount
      ? scaleFontSize(theme.fontSize.large, props.selectProps.scaleAmount)
      : theme.fontSize.large,
  };
};

const SelectStyles: StylesConfig = {
  control: SelectControlStyles,
  input: (base: React.CSSProperties, state: any) => SelectInputStyles(base),
  valueContainer: SelectValueContainerStyles,
  option: SelectOptionStyle,
  menuList: (base: any) => ({ ...base, margin: "8px 0" }),
  placeholder: (base: any) => {
    return {
      ...base,
      color: "inherit",
      opacity: 0,
    };
  },
  singleValue: (base, { selectProps }) => ({
    ...base,
    top: "65%",
    padding: "10px 0",
    marginLeft: 0,
    color: theme.colors.gray.darker,
    fontSize: selectProps.scaleAmount
      ? scaleFontSize(theme.fontSize.large, selectProps.scaleAmount)
      : theme.fontSize.large,
  }),
  menuPortal: (base: any) => ({
    ...base,
    zIndex: theme.header.zIndex + 20000000,
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    opacity: 0,
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    paddingLeft: 10,
    paddingRight: 10,
  }),
};

export const SelectContainerExtendedStyled = styled(SelectContainer)<{
  withIcon?: boolean;
  hasValue?: boolean;
  ignorePadding?: boolean;
  alwaysPadding?: boolean;
  labelPadding?: number;
  scaleAmount?: number;
  errorMessage?: any;
}>`
  &.app-select--is-disabled {
    opacity: 0.5;
  }

  ${({ errorMessage }) => (errorMessage ? `margin-bottom: 15px;` : "")}

  background-color: white;

  ${({ alwaysPadding, scaleAmount }) =>
    alwaysPadding
      ? `
    .app-select__value-container {
      padding-top: ${unit(
        scaleAmount
          ? (theme.element.padding.large * 1.1) / scaleAmount
          : theme.element.padding.large * 1.1
      )}; 
    }
    `
      : ""}
  .app-select__control--is-focused:not(.select-ignore-padding) {
    .app-select__value-container,
    .app-select__value-container--has-value {
      padding-top: ${({ ignorePadding, scaleAmount }) =>
        !ignorePadding &&
        unit(
          scaleAmount
            ? (theme.element.padding.large * 1.1) / scaleAmount
            : theme.element.padding.large * 1.1
        )};
    }
  }

  ${Label} {
    z-index: 100;
    left: ${({ withIcon, labelPadding, scaleAmount }) => {
      let value = unit(theme.element.padding.large);
      if (withIcon && labelPadding && scaleAmount) {
        value = unit(labelPadding / scaleAmount);
      } else if (withIcon && labelPadding && !scaleAmount) {
        value = unit(labelPadding);
      } else if (withIcon && !labelPadding && !scaleAmount) {
        value = unit(theme.element.padding.large + INPUT_ICON_DEFAULTS.size);
      } else if (withIcon && !labelPadding && scaleAmount) {
        value = unit(
          (theme.element.padding.large + INPUT_ICON_DEFAULTS.size) / scaleAmount
        );
      } else if (!withIcon && labelPadding && scaleAmount) {
        value = unit(labelPadding / scaleAmount);
      } else if (!withIcon && !labelPadding && scaleAmount) {
        value = unit(theme.element.padding.large / scaleAmount);
      } else if (!withIcon && labelPadding && !scaleAmount) {
        value = unit(labelPadding);
      }
      return value;
    }};
    transition: all 350ms ease;
    ${({ alwaysPadding }) => (!!alwaysPadding ? LabelActive : "")}
  }
  .app-select__control--is-focused + ${Label} {
    ${LabelActive}
  }
`;

const SelectContainerExtended: React.ComponentType<
  ContainerProps<any> & {
    icon?: any;
    title?: any;
    withIcon?: boolean;
    alwaysPadding?: boolean;
    ignorePadding?: boolean;
    iconPosition?: IconPositionType;
    labelPadding: number;
    scaleAmount?: number;
    errorMessage?: any;
  }
> = ({ icon, title, className, iconPosition: _iconPosition, ...props }) => {
  const iconPosition = _iconPosition || "left";

  return (
    <SelectContainerExtendedStyled
      className={`${className} ${SELECT_DEFAULTS.selectContainerClassName} `}
      {...props}
    >
      {icon && (
        <InputIcon iconPosition={iconPosition} scaleAmount={props.scaleAmount}>
          {" "}
          {icon}{" "}
        </InputIcon>
      )}

      <FieldError
        fontSize="smaller"
        color="white"
        bg="alert"
        errorMessage={props.errorMessage}
      >
        {props.errorMessage}
      </FieldError>

      {props.children}

      <Label
        className={props.hasValue ? "active" : ""}
        scaleAmount={props.scaleAmount}
      >
        {title}
      </Label>
    </SelectContainerExtendedStyled>
  );
};

const SelectCommonProps = {
  menuPortalTarget: document.body,
  classNamePrefix: SELECT_DEFAULTS.classNamePrefix,
};

const DropdownIcon = styled(Icon)`
  width: 26px;
  height: 26px;
`;

const getSelectComponents = (
  props: any
): Partial<SelectComponents<{ label: string; value: string }>> => {
  return {
    SelectContainer: (containerProps) => (
      <SelectContainerExtended
        title={props.title}
        icon={props.icon}
        withIcon={props.withIcon}
        ignorePadding={props.ignorePadding}
        alwaysPadding={props.alwaysPadding}
        labelPadding={props.labelPadding}
        scaleAmount={props.scaleAmount}
        errorMessage={props.errorMessage}
        {...containerProps}
      />
    ),
    Option: SelectOptionComponent(),
    DropdownIndicator: () => (
      <Block padding={{ left: 12, right: 12 }}>
        <DropdownIcon name="dropdownIcon" color="gray" shade="darkest" />
      </Block>
    ),
  };
};

function SelectOptionComponent() {
  return (
    optionProps: React.PropsWithChildren<
      OptionProps<{
        label: string;
        value: string;
      }>
    >
  ) => (
    <Option {...optionProps}>
      <Block
        display="grid"
        alignItems="center"
        gridGap={8}
        gridTemplate={{
          columns: optionProps.selectProps.optionWithIcon ? "20px 1fr" : "1fr",
        }}
      >
        {optionProps.selectProps.optionWithIcon && (
          <Block>
            <Icon name={optionProps.data.icon || "empty"} />
          </Block>
        )}
        <Block>
          {optionProps.children}
          {optionProps.selectProps.withDescription &&
            optionProps.data.description && (
              <Block fontSize="smaller" opacity={0.75} margin={{ top: 5 }}>
                {optionProps.data.description}
              </Block>
            )}
        </Block>
      </Block>
    </Option>
  );
}

function SelectControlComponent(
  containerProps: React.PropsWithChildren<
    ControlProps<{
      label: string;
      value: string;
    }>
  >
) {
  const iconMode =
    containerProps.selectProps.withIcon && containerProps.selectProps.icon;

  return (
    <Control {...containerProps}>
      <Block
        style={{ width: "100%" }}
        padding={{ left: 4, right: 4 }}
        display="grid"
        alignItems="center"
        gridTemplate={{ columns: iconMode ? "20px 1fr" : "1fr" }}
      >
        {/* Icon */}
        {iconMode && <Block>{containerProps.selectProps.icon}</Block>}

        {/* Content */}
        <Block display="flex" margin={iconMode && { left: -8, right: -8 }}>
          {" "}
          {containerProps.children}
        </Block>
      </Block>
    </Control>
  );
}

const SelectMenuSectionTitle = styled(Block)`
  padding: 12px 0 0 16px;
  color: ${({ theme }) => theme.colors.gray.dark};
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  text-transform: uppercase;
  white-space: nowrap;
`;

function SelectMenuComponent(
  props: any
):
  | React.ComponentClass<MenuProps<{ label: string; value: string }>, any>
  | React.FunctionComponent<MenuProps<{ label: string; value: string }>>
  | undefined {
  return (
    menuProps: React.PropsWithChildren<
      MenuProps<{
        label: string;
        value: string;
      }>
    >
  ) => (
    <Menu {...menuProps}>
      <Block>
        <SelectMenuSectionTitle>{props.title}</SelectMenuSectionTitle>
        <Block>{menuProps.children}</Block>
      </Block>
    </Menu>
  );
}

function getSelectValue(
  primitiveValue: boolean | undefined,
  _value: any,
  options: any
) {
  // @TODO Improve typing
  const value = primitiveValue
    ? options.find((option: any) => option.value === String(_value))
    : _value;
  return value as OptionType;
}

const ExtendedSelect: FunctionComponent<ISelectProps> = ({
  primitiveValue,
  value: _value,
  ...props
}) => {
  const value = getSelectValue(primitiveValue, _value, props.options);
  const components = { ...getSelectComponents(props), ...props.components };
  const styles: StylesConfig = { ...SelectStyles, ...props.styles };
  const finalProps = {
    ...SelectCommonProps,
    ...props,
    components,
    styles,
    value,
  };
  return props.isClearable ? (
    <Creatable {...finalProps} />
  ) : (
    <Select {...finalProps} />
  );
};

const ExtendedAsyncSelect: FunctionComponent<IAsyncSelectProps> = ({
  primitiveValue,
  value: _value,
  ...props
}) => {
  const value = getSelectValue(primitiveValue, _value, props.options);
  const components = { ...getSelectComponents(props), ...props.components };
  const styles: StylesConfig = { ...SelectStyles, ...props.styles };
  const finalProps = {
    ...props,
    ...SelectCommonProps,
    components,
    styles,
    value,
  };
  return props.isClearable ? (
    <AsyncCreatable {...finalProps} />
  ) : (
    <Async {...finalProps} />
  );
};

const SelectTagsStyles: StylesConfig = {
  menu: () => ({ display: "none", visibility: "hidden" }),
  valueContainer: (base: any) => ({ ...base, padding: "0 !important" }),
  control: (base, state) => ({
    ...SelectControlStyles(base, state),
    backgroundColor: "transparent !important",
    borderColor: "transparent !important",
  }),
  input: (base: React.CSSProperties) => ({
    ...SelectInputStyles(base),
    marginBottom: "10px !important",
  }),
  multiValue: (base: any) => ({
    ...base,
    backgroundColor: "#ff4156",
    padding: "10px !important",
    margin: "0",
    marginRight: "10px !important",
    marginBottom: "10px !important",
    borderRadius: "5px !important",
  }),
  multiValueLabel: (base: any) => ({ ...base, color: "white" }),
  multiValueRemove: (base: any) => ({
    ...base,
    "& svg": { fill: "#0000006b" },
    "&:hover": { backgroundColor: "unset" },
  }),
};

const TagsSelectComponents = {
  DropdownIndicator: null,
  ClearIndicator: null,
  // MenuList: () => <span></span>,
};

const TagsSelect: FunctionComponent<ISelectProps> = (props) => {
  return (
    <ExtendedSelect
      isMulti
      isClearable
      styles={{ ...SelectTagsStyles, ...props.styles }}
      {...props}
      components={{
        ...TagsSelectComponents,
        ...props.components,
      }}
    />
  );
};
const TagsAsyncSelect: FunctionComponent<IAsyncSelectProps> = (props) => {
  return (
    <ExtendedAsyncSelect
      isMulti
      isClearable
      styles={{ ...SelectTagsStyles, ...props.styles }}
      {...props}
      components={{
        ...TagsSelectComponents,
        ...props.components,
      }}
    />
  );
};

export {
  ExtendedSelect as Select,
  ExtendedAsyncSelect as AsyncSelect,
  TagsSelect,
  TagsAsyncSelect,
  ISelectProps,
  IAsyncSelectProps,
  SelectStyles,
  SelectTagsStyles,
  SelectControlStyles,
  SelectOptionStyle,
  SELECT_DEFAULTS,
  INPUT_ICON_DEFAULTS,
  INPUT_BORDER_COLOR,
  SelectCommonProps,
  SelectOptionComponent,
  SelectMenuComponent,
  SelectControlComponent,
  getSelectComponents,
};
