import * as React from "react";
import { FunctionComponent } from "react";
import { styled, unit, theme } from "../../styled-components";
import { Button, IButtonProps, LightColors } from "../Button/Button";
import { Block } from "../Block/Block";
import { Icon } from "../Icon/Icon";

const CTA_BUTTON_DEFAULTS = {
  maxWidth: theme.element.width,
  adjustments: {
    top: -50,
    right: 25,
  },
};
const ButtonContent = styled(Block)``;

const ButtonStyled = styled(Button).attrs({})<{
  keepOpen?: boolean;
  keepClose?: boolean;
  pullUpAmount: number;
  pullRightAmount: number;
  disableZIndex?: boolean;
}>`
  top: 0;
  position: absolute !important;
  min-height: unset;
  min-width: unset;
  width: 50px;
  height: 50px;
  right: 0;
  transform: ${({ pullUpAmount, pullRightAmount }) =>
    `translateY(-${pullUpAmount}%) translateX(-${pullRightAmount}px)`};
  z-index: ${({ disableZIndex }) => !disableZIndex && "100"};
  letter-spacing: 0.5px;
  transition: all 350ms ease-in-out;
  transform-origin: right;
  font-family: ${theme.fontFamily.main};
  font-size: 13px;
  font-weight: 600;
  ${ButtonContent} {
    opacity: ${({ keepOpen }) => (keepOpen ? 1 : 0)};
    padding-left: ${({ keepOpen }) => (keepOpen ? "8px" : 0)};
    white-space: nowrap;
    transition: all 350ms ease-in-out;
    max-width: ${({ keepOpen }) =>
      keepOpen ? unit(CTA_BUTTON_DEFAULTS.maxWidth - 10) : 0};
    overflow: hidden;
  }

  width: ${({ keepOpen }) =>
    keepOpen ? unit(CTA_BUTTON_DEFAULTS.maxWidth) : "50px"};

  &:hover {
    width: ${({ keepClose }) =>
      !keepClose && unit(CTA_BUTTON_DEFAULTS.maxWidth)};
    ${ButtonContent} {
      opacity: ${({ keepClose }) => !keepClose && 1};
      padding-left: ${({ keepClose }) => !keepClose && "8px"};
      max-width: ${({ keepClose }) =>
        !keepClose && unit(CTA_BUTTON_DEFAULTS.maxWidth - 10)};
    }
    border-radius: ${({ theme, keepClose }) =>
      !keepClose && unit(theme.borderRadius)};
    transform: ${({ pullUpAmount, pullRightAmount, keepClose }) =>
      !keepClose &&
      `translateY(-${pullUpAmount}%) translateX(-${pullRightAmount}px)`};
  }
  &:active {
    transform: ${({ pullUpAmount, pullRightAmount, disabled }) =>
      !disabled &&
      `translateY(-${pullUpAmount - 10}%) translateX(-${pullRightAmount}px)`};
    opacity: ${({ disabled }) => !disabled && 0.5};
  }
  &:active {
    opacity: ${({ disabled }) => disabled && 0.5};
  }
`;

const ButtonStyledContainer = styled(Block)`
  position: relative;
  justify-self: stretch;
`;

interface ICtaButtonProps extends IButtonProps {
  removeIcon?: boolean;
  label: string;
  keepClose?: boolean;
  keepOpen?: boolean;
  pullUpAmount?: number;
  pullRightAmount?: number;
  disableZIndex?: boolean;
}
const CtaButton: FunctionComponent<ICtaButtonProps> = ({
  icon,
  label,
  pullUpAmount,
  pullRightAmount,
  removeIcon,
  disableZIndex,
  ...buttonProps
}) => {
  return (
    <ButtonStyledContainer>
      <ButtonStyled
        disableZIndex={disableZIndex}
        pullUpAmount={pullUpAmount || (pullUpAmount === 0 ? 0 : 50)}
        pullRightAmount={pullRightAmount || (pullRightAmount === 0 ? 0 : 25)}
        buttonType={buttonProps.buttonType || "cta"}
        {...buttonProps}
      >
        {!removeIcon && (
          <Icon
            iconSize="small2"
            strokeColor={
              LightColors.includes(buttonProps.buttonType ?? "white")
                ? "white"
                : "black"
            }
            name={icon || "add"}
          />
        )}
        <ButtonContent>{label}</ButtonContent>
      </ButtonStyled>
    </ButtonStyledContainer>
  );
};

export { CtaButton, ICtaButtonProps, CTA_BUTTON_DEFAULTS };
