import { ILoginData } from "../interfaces";
import { openApi, basePath } from "./api";
import { storeToken } from "./session";
export const login = async ({
  email: username,
  password,
  persistSession,
}: ILoginData) => {
  const url = `${basePath.baseURL}/oauth/v2/token`;
  const params = {
    client_id: "1_qwgh3gijy6oc44kw04wco0swookgwgws0csww4cgw4oss80s4",
    client_secret: "44eugnuavykgosskw00c84wwoosc4oosk80000w88o48wkssso",
    grant_type: "password",
    username,
    password,
  };
  try {
    const token = await openApi.post(url, params);
    if (!token) {
      return Promise.reject("Missing token");
    }
    return storeToken(token, persistSession);
  } catch (error) {
    return Promise.reject(error);
  }
};
